export type Status = 'basic'|'primary'|'info'|'warning'|'danger'|'success';
export const Statuses: Status[] = [
  'basic',
  'primary',
  'info',
  'warning',
  'danger',
  'success',
];
Statuses.sort();

export type BannerStatus = 'error'|'info'|'success'|'warning';
export const BannerStatuses: BannerStatus[] = [
  'error',
  'info',
  'success',
  'warning',
];
Statuses.sort();

export type BadgeStatus = 'basic'|'success'|'info'|'warning'|'danger';
export const BadgeStatuses: BadgeStatus[] = [
  'basic',
  'success',
  'info',
  'warning',
  'danger',
];
BadgeStatuses.sort();

export type InputStatus = 'basic'|'primary'|'info'|'warning'|'danger'|'success'|'none';
export const InputStatuses: InputStatus[] = [
  'basic',
  'primary',
  'info',
  'warning',
  'danger',
  'success',
  'none',
];
InputStatuses.sort();
