import { Fragment, useState } from 'react';
import { SaveSponsoredBannerModal } from './SaveSponsoredBanner';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { SponsoredBannerApi } from '@/utilities/api/SponsoredBannerApi';
import { useSearch } from '@/hooks/useSearch';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { ToastMessages } from '@/constants/constant';
import DetailedCard from './DetailedCard';

const SponsoredBanners = () => {
  const queryClient = useQueryClient();
  const [addSponsoredBanner, setAddSponsoredBanner] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const { openSnackbar } = useSnackbar();

  const { search, setSearch, debouncedSearch } = useSearch();

  const { data: sponsoredBannerList } = useQuery(
    [QueryKeys.GET_SPONSORED_BANNER, debouncedSearch],
    () => SponsoredBannerApi.list(debouncedSearch),
    {
      onSuccess: (response) => {
        if (response?.length) {
          return setIsSearch(true);
        }
        if (debouncedSearch) {
          return setIsSearch(true);
        }
        return setIsSearch(false);
      },
    },
  );

  const { mutate: deleteSponsoredBanner } = useMutation(
    (id: string) => SponsoredBannerApi.deleteSponsoredBanner(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.GET_SPONSORED_BANNER]);
        openSnackbar(ToastMessages.sponsoredBannerDeleteSuccess);
      },
      onError: (error: DetailedApiError) => {
        if (error?.code == '500') {
          return openSnackbar('Something went wrong');
        }
        return openSnackbar(error?.message);
      },
    },
  );

  const { mutate: saveChanges, isLoading: isSaveChangesLoading } = useMutation(
    (args: string[]) => SponsoredBannerApi.reOrderedSponsoredBanner(args),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.GET_SPONSORED_BANNER]);
        openSnackbar(ToastMessages.sponsoredBannerReOrderSuccess);
      },
      onError: (error: DetailedApiError) => {
        if (error?.code == '500') {
          return openSnackbar(ToastMessages.sponsoredBannerReOrderFailure);
        }
        return openSnackbar(error?.message);
      },
    },
  );

  const sponsoredBannerData = sponsoredBannerList?.map(
    ({
      rank,
      bannerTitle,
      id,
      productId,
      manufacturerId,
      retailerId,
      bannerImage,
    }) => ({
      id,
      bannerImage: bannerImage ?? undefined,
      rank: rank ?? '',
      name: bannerTitle ?? '',
      productId: productId ?? undefined,
      manufacturerId: manufacturerId ?? undefined,
      retailerId: retailerId ?? undefined,
    }),
  );

  const getTitle = () => {
    if (!isSearch) {
      return 'Sponsored Banner';
    }
    if (sponsoredBannerData && sponsoredBannerData?.length <= 1) {
      return `${sponsoredBannerData?.length} Sponsored Banner`;
    }
    return `${sponsoredBannerData?.length} Sponsored Banners`;
  };

  return (
    <Fragment>
      <DetailedCard
        addNew={setAddSponsoredBanner}
        cardTitle={'Sponsored banner'}
        data={sponsoredBannerData}
        deleteCard={deleteSponsoredBanner}
        isClickable={true}
        isDisabled={sponsoredBannerData?.length === 10}
        isSaveChangesLoading={isSaveChangesLoading}
        isSearch={isSearch}
        saveChanges={saveChanges}
        search={search}
        setSearch={setSearch}
        title={getTitle()}
      />
      {addSponsoredBanner && (
        <SaveSponsoredBannerModal close={() => setAddSponsoredBanner(false)}/>
      )}
    </Fragment>
  );
};

export default SponsoredBanners;
