import { Box, Divider, Stack } from '@mui/material';
import { Button, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { useState } from 'react';
import Upload from '@mui/icons-material/Upload';
import Add from '@mui/icons-material/Add';
import { ApiRetailer } from '@api/interfaces';
import {
  DeliverableTemplateModal,
} from '@/pages/Admin/HierarchyOfRetailers/Retailer/DeliverableTemplateModal';
import { LogoModal } from '@/pages/Admin/HierarchyOfRetailers/Retailer/LogoModal';
import { RetailerEndpoint } from '@api/endpoints';
import { BrandColorModal } from '@/pages/Admin/HierarchyOfRetailers/Retailer/BrandColorModal';
import { FriendlyDeliverableTemplateType } from '@/constants/FriendlyDeliverableTemplateType';
import ConfirmModal from '@/components/shared/ConfirmModal';

interface BrandIdentityInputsProps {
  onChange: (updates: RetailerEndpoint.Update.Request) => void,
  retailer: ApiRetailer,
}

export const BrandIdentityInputs = ({
  onChange,
  retailer,
}: BrandIdentityInputsProps) => {
  const [showLogoModal, setShowLogoModal] = useState(false);
  const [showBrandColorModal, setShowBrandColorModal] = useState(false);
  const [showDeliverableTemplateModal, setShowDeliverableTemplateModal] = useState(false);
  const [showRemoveLogoDialog, setShowRemoveLogoDialog] = useState(false);
  const [showRemoveBrandColorDialog, setShowRemoveBrandColorDialog] = useState(false);
  const [showRemoveTemplateDialog, setShowRemoveTemplateDialog] = useState(false);

  const LogoInput = (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Text category="title-small">
        Logo
      </Text>
      {retailer.image ? (
        <Stack alignItems="center" direction="row">
          <Text>
            {retailer.image}
          </Text>
          <HSpacer size="5"/>
          <Button
            onClick={() => setShowRemoveLogoDialog(true)}
            testID="logo-remove-button"
            variant="text"
          >
            Remove
          </Button>
        </Stack>
      ) : (
        <Button
          onClick={() => setShowLogoModal(true)}
          testID="logo-upload-button"
          variant="text"
        >
          <Upload/>
          <HSpacer size="3"/>
          Upload Logo
        </Button>
      )}
    </Stack>
  );

  const BrandColorInput = (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Text category="title-small">
        Brand color
      </Text>
      {retailer.brandColor ? (
        <Stack alignItems="center" direction="row">
          <Box bgcolor={retailer.brandColor} height="16px" width="16px" />
          <HSpacer size="3" />
          <Text category="body-medium">
            {retailer.brandColor}
          </Text>
          <HSpacer size="5" />
          <Button
            onClick={() => setShowBrandColorModal(true)}
            testID="brand-color-edit-button"
            variant="text"
          >
            Edit
          </Button>
          <Button
            onClick={() => setShowRemoveBrandColorDialog(true)}
            testID="brand-color-remote-button"
            variant="text"
          >
            Remove
          </Button>
        </Stack>
      ) : (
        <Button
          onClick={() => setShowBrandColorModal(true)}
          testID="brand-color-add-button"
          variant="text"
        >
          <Add/>
          <HSpacer size="3"/>
          Add Brand Color
        </Button>
      )}
    </Stack>
  );

  const DeliverableTemplateInput = (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Text category="title-small">
        Deliverable template
      </Text>
      {retailer.templateType ? (
        <Stack alignItems="center" direction="row">
          <Text category="body-medium">
            {FriendlyDeliverableTemplateType[retailer.templateType]}
          </Text>
          <HSpacer size="5" />
          <Button
            onClick={() => setShowDeliverableTemplateModal(true)}
            testID="deliverable-template-edit-button"
            variant="text"
          >
            Edit
          </Button>
          <Button
            onClick={() => setShowRemoveTemplateDialog(true)}
            testID="deliverable-template-remove-button"
            variant="text"
          >
            Remove
          </Button>
        </Stack>
      ) : (
        <Button
          disabled={!retailer.brandColor}
          onClick={() => setShowDeliverableTemplateModal(true)}
          testID="deliverable-template-button"
          variant="text"
        >
          <Add />
          <HSpacer size="3" />
          Add Deliverable Template
        </Button>
      )}
    </Stack>
  );

  return (
    <>
      <Stack>
        <Text category="body-xlarge">
          Brand Identity
        </Text>
        <VSpacer size="5" />
        <Divider />
        <VSpacer size="2" />
        {LogoInput}
        <VSpacer size="2" />
        <Divider />
        <VSpacer size="2" />
        {BrandColorInput}
        <VSpacer size="2" />
        <Divider />
        <VSpacer size="2" />
        {DeliverableTemplateInput}
        <VSpacer size="2" />
        <Divider />
      </Stack>
      {showLogoModal && (
        <LogoModal
          imageFilename={retailer.image}
          onClose={() => setShowLogoModal(false)}
          onSave={(image) => onChange({ image })}
        />
      )}
      {showBrandColorModal && (
        <BrandColorModal
          brandColor={retailer.brandColor}
          onClose={() => setShowBrandColorModal(false)}
          onSave={(brandColor) => onChange({ brandColor })}
        />
      )}
      {showDeliverableTemplateModal && (
        <DeliverableTemplateModal
          onClose={() => setShowDeliverableTemplateModal(false)}
          onSaveBrandColor={(brandColor) => onChange({ brandColor })}
          onSaveTemplateType={(templateType) => onChange({ templateType })}
          retailer={retailer}
        />
      )}
      {showRemoveLogoDialog && (
        <ConfirmModal
          confirmButtonText="Yes"
          message={
            <Text category="headline-small">
              Are you sure you want to
              remove this logo?
            </Text>
          }
          onCancel={() => setShowRemoveLogoDialog(false)}
          onConfirm={() => {
            onChange({ image: null });
            setShowRemoveLogoDialog(false);
          }}
          open
          testID="remove-logo-dialog"
        />
      )}
      {showRemoveBrandColorDialog && (
        <ConfirmModal
          confirmButtonText="Yes"
          message={
            <Text category="headline-small">
              Are you sure you want to
              remove this brand color?
            </Text>
          }
          onCancel={() => setShowRemoveBrandColorDialog(false)}
          onConfirm={() => {
            onChange({ brandColor: null, templateType: null });
            setShowRemoveBrandColorDialog(false);
          }}
          open
          testID="remove-brand-color-dialog"
        />
      )}
      {showRemoveTemplateDialog && (
        <ConfirmModal
          confirmButtonText="Yes"
          message={
            <Text category="headline-small">
              Are you sure you want to
              remove this deliverable template?
            </Text>
          }
          onCancel={() => setShowRemoveTemplateDialog(false)}
          onConfirm={() => {
            onChange({ templateType: null });
            setShowRemoveTemplateDialog(false);
          }}
          open
          testID="remove-template-dialog"
        />
      )}
    </>
  );
};
