import { AppConfig } from '@/constants/AppConfig';

export interface OauthConfiguration {
  authEndpoint: string,
  clientId: string,
}

export const OauthConfig: { [subdomain: string]: OauthConfiguration } = {
  landus: {
    authEndpoint: AppConfig.env.prod
      ? 'https://account.landus.ag/realms/landus/protocol/openid-connect/auth'
      : 'https://devaccount.landus.ag/realms/landus/protocol/openid-connect/auth',
    clientId: 'growers_org',
  },
};
