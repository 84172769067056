import { Button, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { Action } from '@/pages/Admin/CustomNotifications/helper';
import { LocationCard } from '@/pages/Admin/CustomNotifications/LocationCard';
import { ApiRetailer, LocationDetails } from '@api/interfaces';
import AddIcon from '@mui/icons-material/Add';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Stack } from '@mui/material';
import React from 'react';

interface RetailerLocationsListProps {
  locations: LocationDetails[],
  onBack: () => void,
  onChange: (updatedIds: string[]) => void,
  onSelectLocation: (location: LocationDetails) => void,
  retailer: ApiRetailer,
  selectedRecipientIds: string[],
}

export const RetailerLocationsList = ({
  locations,
  onBack,
  onChange,
  onSelectLocation,
  retailer,
  selectedRecipientIds,
}: RetailerLocationsListProps) => {
  const salespersonIds = locations.flatMap((location) => (
    location.salespersons.map((salesperson) => salesperson.id,
    ))) ?? [];

  const addAll = () => {
    const newSelectedRecipients = new Set(selectedRecipientIds);
    salespersonIds.forEach((id) => newSelectedRecipients.add(id));
    onChange(Array.from(newSelectedRecipients));
  };

  const removeAll = () => {
    const newSelectedRecipients = new Set(selectedRecipientIds);
    salespersonIds.forEach((id) => newSelectedRecipients.delete(id));
    onChange(Array.from(newSelectedRecipients));
  };

  const showAddAllButton = !salespersonIds.every((id) => selectedRecipientIds.includes(id));
  const showRemoveAllButton = selectedRecipientIds.some((id) => salespersonIds.includes(id));

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack alignItems="center" direction="row">
          <Button
            color="inherit"
            onClick={onBack}
            startIcon={<ArrowBack />}
            testID="location-back-button"
            variant="outlined"
          >
            Back
          </Button>
          <HSpacer size="4" />
          <Text category="title-medium">
            {retailer.name} / Locations ({locations.length})
          </Text>
        </Stack>
        <Stack flexDirection="row" justifyContent="flex-end">
          {showRemoveAllButton && (
            <Button
              onClick={removeAll}
              testID="remove-all-recipients-button"
              variant="text"
            >
              Remove all
            </Button>
          )}
          {showAddAllButton && showRemoveAllButton && (
            <HSpacer size="5" />
          )}
          {showAddAllButton && (
            <Button
              onClick={addAll}
              startIcon={<AddIcon />}
              testID="remove-all-recipients-button"
            >
              Add all
            </Button>
          )}
        </Stack>
      </Stack>
      <VSpacer size="5" />
      {locations.map((location) => (
        <React.Fragment key={location.id}>
          <LocationCard
            addedSalespersonIds={selectedRecipientIds}
            location={location}
            onAction={(action: Action) => {
              const updatedRecipients = new Set(selectedRecipientIds);
              const salespersonIds = location.salespersons.map(
                (salesperson) => salesperson.id,
              ) ?? [];
              salespersonIds.forEach((id) => {
                if (action === Action.AddAll) {
                  updatedRecipients.add(id);
                } else {
                  updatedRecipients.delete(id);
                }
              });
              onChange(Array.from(updatedRecipients));
            }}
            onSelectLocation={onSelectLocation}
          />
          <VSpacer size="4" />
        </React.Fragment>
      ))}
    </>
  );
};
