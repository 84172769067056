import {
  Button,
  MenuItem,
  Modal,
  Dialog,
  Select,
  VSpacer,
  BottomSheet,
} from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { FriendlyPaymentMethodNew } from '@/constants/FriendlyPaymentMethod';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Divider, Stack } from '@mui/material';
import { PaymentMethodNew } from '@shared/enums/PaymentMethod';
import { Fragment, useState } from 'react';
import { MobileOnly } from '@/components/shared/MobileOnly';

interface SelectPaymentTypeModalProps {
  onClose: () => void,
  onSubmit: (selectedPaymentType: PaymentMethodNew) => void,
  open?: boolean,
}

export const SelectPaymentTypeModal = ({
  onClose,
  onSubmit,
  open,
}: SelectPaymentTypeModalProps) => {
  const [paymentType, setPaymentType] = useState<PaymentMethodNew>();
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const { isMobile } = useMediaQuery();

  const paymentOptions = [
    PaymentMethodNew.Bank,
    PaymentMethodNew.Card,
    PaymentMethodNew.OnAccount,
    PaymentMethodNew.Financing,
  ];

  const modalContent = (
    <Stack>
      <Select
        label="Payment type"
        onChangeValue={(paymentType) => {
          setPaymentType(paymentType as PaymentMethodNew);
        }}
        onClick={() => {
          if (isMobile) {
            setShowBottomSheet(true);
          }
        }}
        open={isMobile ? false : undefined}
        testID="payment-type-select"
        value={paymentType}
      >
        {paymentOptions.map((option) => (
          <MenuItem
            key={option}
            testID={`${option}-payment-type`}
            value={option}
          >
            {FriendlyPaymentMethodNew[option]}
          </MenuItem>
        ))}
      </Select>
      {!!paymentType &&
        <>
          <VSpacer size="8" />
          <Button
            onClick={() => onSubmit(paymentType)}
            sx={{ alignSelf: 'center', width: 'fit-content' }}
            testID="payment-type-submit-button"
          >
            { paymentType === PaymentMethodNew.Bank || paymentType === PaymentMethodNew.Card
              ? 'Continue to Stripe Payment' : 'Submit' }
          </Button>
        </>
      }
    </Stack>
  );

  return (
    <>
      <DesktopOnly>
        <Modal
          cancelButton={(props) => (
            <Button
              onClick={onClose}
              {...props}
            >
              Cancel
            </Button>
          )}
          onClose={onClose}
          open={open}
          testID="select-payment-type-modal"
          title="Select payment type"
        >
          {modalContent}
        </Modal>
      </DesktopOnly>
      <MobileOnly>
        <Dialog
          cancelButton={(props) => (
            <Button
              onClick={onClose}
              {...props}
            >
              Cancel
            </Button>
          )}
          onClose={onClose}
          open={open}
          showCloseButton={false}
          testID="select-payment-type-modal"
          title="Select payment type"
        >
          {modalContent}
        </Dialog>
      </MobileOnly>
      {showBottomSheet &&
        <BottomSheet
          footer={<VSpacer size="5" />}
          onClose={() => setShowBottomSheet(false)}
          onOpen={() => null}
          open
          testID="select-payment-type-bottom-sheet"
        >
          <Stack sx={{ px: '4px' }}>
            <Divider />
            {paymentOptions.map((option) => (
              <Fragment key={option}>
                <MenuItem
                  onClick={() => {
                    setPaymentType(option);
                    setShowBottomSheet(false);
                  }}
                  testID={`${option}-payment-type`}
                >
                  {FriendlyPaymentMethodNew[option]}
                </MenuItem>
                <Divider />
              </Fragment>
            ))}
          </Stack>
        </BottomSheet>
      }
    </>
  );
};
