import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Button } from '@/components/DesignSystem';

interface ConfirmDialogProps {
  cancelButtonText?: string;
  isLoading?: boolean;
  isOpen: boolean;
  message?: string;
  onClose: () => void;
  onConfirm?: () => void;
  title?: string;
}

const ConfirmDialog = ({
  cancelButtonText = 'Cancel',
  isLoading = false,
  isOpen,
  message,
  onClose,
  onConfirm,
  title,
}: ConfirmDialogProps) => {
  return (
    <div>
      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        onClick={(e) => e.stopPropagation()}
        open={isOpen}
      >
        {!!title && (
          <DialogTitle id='alert-dialog-title' sx={{ padding: '20px 20px' }}>
          {title}
        </DialogTitle>
        )}
        <DialogContent>
          {!!message && (
            <DialogContentText
            id='alert-dialog-description'
            sx={{ color: 'white' }}
          >
            {message}
          </DialogContentText>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: '20px 20px' }}>
          <Button
            disabled={isLoading}
            onClick={onClose}
            testID='Close'
            variant='outlined'
          >
            {cancelButtonText}
          </Button>
          {!!onConfirm && (
            <Button
            autoFocus
            loading={isLoading}
            onClick={(e) => {
              e.stopPropagation();
              onConfirm();
            }}
            testID='Confirm'
            variant='contained'
          >
            Confirm
          </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;
