import { DataPoint } from '@/components/DesignSystem';
import { ModalBottomSheet } from '@/components/shared/ModalBottomSheet/ModalBottomSheet';
import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { formatLicense } from '@/utilities/PricingRequest';
import { ApiUserPublic } from '@api/interfaces';

interface FarmerLicensesModalBottomSheetProps {
  onClose: () => void,
  onOpen: () => void,
  farmer: ApiUserPublic,
}

export const FarmerLicensesModalBottomSheet = ({
  onClose,
  onOpen,
  farmer,
}: FarmerLicensesModalBottomSheetProps) => {

  return (
    <ModalBottomSheet
      containerSpacing={1}
      containerStyle={{
        px: getResponsiveValue(2, 0),
      }}
      onClose={onClose}
      onOpen={onOpen}
      open
      testID="view-price-request-contact-info-dialog"
      title="Licenses"
      width={395}
    >
      <DataPoint
        label="Restricted Use License"
        spacing="fixed"
        testID="farmer-licenses-restricted-use"
      >
        {formatLicense(
          farmer.restrictedUseLicense,
          farmer.restrictedUseLicenseExpirationDate,
        )}
      </DataPoint>
      <DataPoint
        label="Tax-exempt License"
        spacing="fixed"
        testID="farmer-licenses-tax-exempt"
      >
        {formatLicense(
          farmer.taxExemptLicense,
          farmer.taxExemptLicenseExpirationDate,
        )}
      </DataPoint>
      <DataPoint
        label="Technology License"
        spacing="fixed"
        testID="farmer-licenses-technology"
      >
        {formatLicense(
          farmer.technologyLicense,
          farmer.technologyLicenseExpirationDate,
        )}
      </DataPoint>
    </ModalBottomSheet>
  );
};
