import { QueryUtil } from '../Query';
import { Client } from './Client';
import { SavedRetailersEndpoint } from "@api/endpoints";

export class SavedRetailersApi {
  static create (
    retailer: SavedRetailersEndpoint.Create.Request,
  ): Promise<SavedRetailersEndpoint.Create.Response> {
    return Client('saved-retailers', { method: 'POST', body: retailer });
  }

  static delete (
    id: string,
  ): Promise<void> {
    return Client(`saved-retailers/${id}`, { method: 'DELETE' });
  }

  static get (
    id: string,
  ): Promise<SavedRetailersEndpoint.Get.Response> {
    return Client(`saved-retailers/${id}`);
  }

  static list (
    query: SavedRetailersEndpoint.List.Query,
  ): Promise<SavedRetailersEndpoint.List.Response> {
    const queryString = QueryUtil.stringify(query);
    return Client(`saved-retailers?${queryString}`);
  }

  static update (
    id: string,
    updates: SavedRetailersEndpoint.Update.Request,
  ): Promise<SavedRetailersEndpoint.Update.Response> {
    return Client(`saved-retailers/${id}`, { method: 'PATCH', body: updates });
  }
}