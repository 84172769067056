import {
  MenuItem as MuiMenuItem, 
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material';

export interface MenuItemProps extends MuiMenuItemProps {
  density?: 0 | 2 | 4,
  paddingLeft?: number,
  testID: string,
}

export const MenuItem = ({ 
  density = 0,
  paddingLeft = 16,
  testID,
  ...rest
}: MenuItemProps) => {

  const padding = 32 - (density * 4);

  return (
    <MuiMenuItem
      data-testid={testID}
      sx={{
        paddingLeft: `${paddingLeft}px`,
        paddingTop: `${padding / 2}px`,
        paddingBottom: `${padding / 2}px`,
      }} 
      {...rest} 
    />
  );
};
