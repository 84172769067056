import { useState, useEffect } from 'react';
import { debounce } from '@/utilities/debounce';

export const useSearch = (minDebounceLength = 3) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  useEffect(() => {
    if (!search.length) {
      debounce(() => setDebouncedSearch(search), 500);
    }
    if (search?.length >= minDebounceLength) {
      debounce(() => setDebouncedSearch(search), 500);
    }
  }, [search]);

  return {
    search,
    setSearch,
    debouncedSearch,
  };
};
