import {
  Card,
  HSpacer,
  IconButton,
  Text,
  TextElement,
  TextLink,
  VSpacer,
} from "@/components/DesignSystem";
import { CostSummary } from '@/components/shared/PriceRequestOrder/CostSummary';
import { FriendlyUoM } from "@/constants/FriendlyUoM";
import { OfferProductsRetailer } from '@/pages/CompareAcceptOffers/interfaces';
import { OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { OfferDetails } from '@/pages/ReviewSelectOffers/OfferDetails';
import { ApiOffer, ApiOfferProduct, ApiPricingRequest } from '@api/interfaces';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Divider, Stack } from "@mui/material";
import { formatCurrency, pluralize } from "@shared/utilities";
import { Fragment, useMemo, useState } from "react";

export interface PriceRequestOrderCardProps {
  offerProductsRetailer: OfferProductsRetailer,
  onOfferProductDelete?: (offerProduct: OfferProduct) => void,
  priceRequest: ApiPricingRequest,
  readonly?: boolean,
  testID: string,
}

export const PriceRequestOrderCard = ({
  offerProductsRetailer,
  onOfferProductDelete,
  priceRequest,
  readonly = false,
  testID,
}: PriceRequestOrderCardProps) => {
  const [showOfferDetails, setShowOfferDetails] = useState(false);
  const [showProducts, setShowProducts] = useState(false);

  const offer = useMemo<ApiOffer | undefined>(() => {
    const { retailer, offerProducts } = offerProductsRetailer;
    if (!retailer.offer?.products) {
      return;
    }

    return retailer.offer.products.length === offerProducts.length ? retailer.offer : {
      ...retailer.offer,
      products: offerProductsRetailer.offerProducts as unknown as ApiOfferProduct[],
    };
  }, [offerProductsRetailer]);

  return (
    <>
      <Card
        cardContentStyle={{
          p: "16px",
          "&:last-child": {
            pb: "16px",
          },
        }}
        sx={{
          border: '1px solid',
          borderRadius: "4px",
          backgroundColor: 'transparent',
        }}
        testID={testID}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Text category="title-medium" testID={`${testID}-retailer-name`}>
            {offerProductsRetailer.retailer.name}
          </Text>
        </Stack>
        {offer &&
          <>
            <VSpacer size="3" />
            <CostSummary offer={offer} priceRequest={priceRequest} />
          </>
        }
        <VSpacer size="3" />
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <TextLink
            accessoryRight={(
              <IconButton
                onClick={() => setShowProducts(!showProducts)}
                testID={`${testID}-expand-button`}
              >
                {showProducts ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
            category="body-medium"
            onClick={() => setShowProducts(!showProducts)}
            testID={`${testID}-products-count`}
          >
            {pluralize(offerProductsRetailer.offerProducts.length, "product")}
          </TextLink>
          <TextLink
            category="label-medium"
            disabled={!offerProductsRetailer.retailer.offer?.products?.length}
            onClick={() => setShowOfferDetails(true)}
            testID={`${testID}-offer-details`}
          >
            Offer Details
          </TextLink>
        </Stack>
        {showProducts && (
          <>
            <VSpacer size="4" />
            {offerProductsRetailer.offerProducts.map((offerProduct, index) => (
              <Fragment key={index}>
                <Divider />
                <VSpacer size="4" />
                <Stack
                  alignItems="flex-end"
                  direction="row"
                  justifyContent="space-between"
                >
                  <TextElement
                    category="14"
                    subline={`${offerProduct.quantity} ${offerProduct.uom && FriendlyUoM[offerProduct.uom]}`}
                    testID={`product-${index}-name`}
                    title={offerProduct.substituteProduct
                      || offerProduct.productDetail?.name
                      || offerProduct.name
                    }
                  />
                  <HSpacer size="5" />
                  <Stack alignItems="center" direction="row">
                    {(!!offerProduct.price && !!offerProduct.uom && !!offerProduct.quantity) && (
                      <TextElement
                        alignment="flex-end"
                        category="14"
                        nowrap
                        subline={`Total: ${formatCurrency(offerProduct.price)}`}
                        testID={`${testID}-product-${index}-price`}
                        title={
                          `${formatCurrency(offerProduct.price / offerProduct.quantity)} /${FriendlyUoM[offerProduct.uom]}`
                        }
                      />
                    )}

                    {!!onOfferProductDelete && !readonly && (
                      <>
                        <HSpacer size="3" />
                        <IconButton
                          onClick={() => onOfferProductDelete(offerProduct)}
                          testID={`${testID}-product-${index}-delete-button`}
                        >
                          <DeleteOutlined />
                        </IconButton>
                      </>
                    )}
                  </Stack>
                </Stack>
                <VSpacer size="4" />
              </Fragment>
            ))}
          </>
        )}
      </Card>
      {(showOfferDetails && !!offer) && (
        <OfferDetails
          offer={offer}
          onClose={() => setShowOfferDetails(false)}
          priceRequest={priceRequest}
          retailer={offerProductsRetailer.retailer}
          testID={`${testID}-offer-details`}
        />
      )}
    </>
  );
};
