import { ChipProps, ProductListItem } from '@/components/DesignSystem';
import { getStatusChipPropsFromPRStatus } from '@/utilities/PricingRequest';
import { StringUtility } from '@/utilities/String';
import { Stack } from '@mui/system';
import { PricingRequestStatus } from '@shared/enums';
import { DemoBlock } from '../../components/DemoBlock';

interface Product {
  id: string,
  name: string,
  price: number,
  qty: number,
  statuses: PricingRequestStatus[],
}

const products: Product[] = [
  {
    id: '1',
    name: 'Product 1',
    price: 1,
    qty: 1,
    statuses: [
      PricingRequestStatus.Accepted,
      PricingRequestStatus.Open,
      PricingRequestStatus.AwaitingApproval,
    ],
  },
  {
    id: '2',
    name: '14-0-0 Soluble Powder Clear GROW HERBICIDE_5GAL 25lb',
    price: 14,
    qty: 1,
    statuses: [
      PricingRequestStatus.Accepted,
      PricingRequestStatus.Open,
      PricingRequestStatus.AwaitingApproval,
    ],
  },
  {
    id: '3',
    name: 'Product 1',
    price: 12,
    qty: 1,
    statuses: [
      PricingRequestStatus.Accepted,
      PricingRequestStatus.Open,
      PricingRequestStatus.AwaitingApproval,
    ],
  },
  {
    id: '4',
    name: 'Product 1',
    price: 12,
    qty: 1,
    statuses: [
      PricingRequestStatus.Accepted,
      PricingRequestStatus.AwaitingApproval,
    ],
  },
];

export const ProductListItemDemo = () => {
  return (
    <Stack spacing={1}>
      {products.map((product, idx) => {
        const badges: ChipProps[] = product.statuses.map(getStatusChipPropsFromPRStatus);

        return (
          <DemoBlock key={product.id}>
            <ProductListItem
              badges={badges}
              price={StringUtility.formatCurrencyAccounting(product.price)}
              subtitle={`SKU Qty: ${product.qty}`}
              testID={`product-list-item-${idx}`}
              title={product.name}
            />
          </DemoBlock>
        );
      })}
    </Stack>
  );
};
