import { Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { FilterSelector } from "@/components/DesignSystem/Toolbar/FilterSelector";
import { FilterSelection, NonBooleanFilter } from "@/components/DesignSystem/Toolbar/interfaces";
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import Close from '@mui/icons-material/Close';
import { Box, IconButton, Menu, MenuProps, Stack } from "@mui/material";
import { Modify } from "@shared/utilities/UtilityTypes";

const styles: SXStyles = {
  clearButton: {
    paddingRight: '10px',
    paddingY: '8px',
  },
} as const;

interface FilterMenuProps
  extends Modify<
    MenuProps,
    { onChange: (selection: FilterSelection) => void }
  > {
  filter: NonBooleanFilter,
  selection?: FilterSelection,
  testID: string,
}

export const FilterMenu = ({
  anchorEl,
  filter,
  onChange,
  onClose,
  selection = new Set(),
  testID,
}: FilterMenuProps) => {
  return (
    <Menu
      PaperProps={{
        style: {
          borderRadius: '12px',
          marginTop: '12px',
        },
      }}
      anchorEl={anchorEl}
      aria-rowspan={selection?.size ?? 0}
      data-testid={testID}
      onClose={onClose}
      open
    >
      <Stack direction="row" justifyContent="space-between" p="12px 16px">
        <IconButton
          onClick={() => {
            onClose?.({}, 'backdropClick');
          }}
          size="small"
        >
          <Close />
        </IconButton>
        {!filter.hideClearButton && (
          <TextLink
            category="label-medium"
            disabled={!selection || selection.size === 0}
            onClick={() => {
              onChange?.(new Set());
            }}
            sx={styles.clearButton}
            testID={`${testID}-clear-button`}
          >
            Clear
          </TextLink>
        )}
      </Stack>
      <Box p="0 24px 24px">
        {!!filter.helperText &&
          <>
            <Text category="title-small">
              {filter.helperText}
            </Text>
            <VSpacer size="5" />
          </>
        }
        <FilterSelector
          filter={filter}
          onChange={onChange}
          selection={selection}
          testID={`${testID}-selector`}
        />
      </Box>
    </Menu>
  );
};
