import { Stack, Divider, Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import {
  Button,
  FullscreenDialog,
  HSpacer,
  IconButton,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { Modal as MuiModal } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import Today from '@mui/icons-material/Today';
import './DatePickerStyles.css';
import { DatePickerCalendar } from './DatePickerCalendar';
import { DatePickerInput } from './DatePickerInput';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';

const styles: SXStyles = {
  bottomBar: {
    alignItems: "center",
    height: 52,
    justifyContent: "flex-end",
    padding: "2.4px 12px 8px 12px",
  },
  container: {
    borderRadius: "28px",
    boxShadow: 24,
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  inputModeHeader: {
    padding: "16px 24px 0 24px",
  },
  inputModeContainer: {
    paddingLeft: "24px",
    paddingRight: "24px",
  },
} as const;

interface DatePickerProps {
  calendarMode: boolean,
  date?: Date,
  onChangeCalendarMode: (calendarMode: boolean) => void,
  onChangeDate: (date?: Date) => void,
  onClose: () => void,
  onSave: () => void,
  testID: string,
}

const DatePicker = ({
  calendarMode,
  date,
  onChangeCalendarMode,
  onChangeDate,
  onClose,
  onSave,
  testID,
}: DatePickerProps) => {

  const formattedDate = useMemo(() => {
    if (!date) {
      return "";
    }
    return DateTime.fromJSDate(date).toFormat("ccc, LLL dd");
  }, [date]);

  return (
    <Stack>
      <Box sx={styles.inputModeHeader}>
        <Text category="label-large">
          Select date
        </Text>
        <VSpacer size="8" />
        <VSpacer size="2" />
        <Stack direction="row" justifyContent="space-between">
          <Text category="headline-large">
            {calendarMode
              ? formattedDate
              : 'Enter dates'
            }
          </Text>
          <IconButton
            onClick={() => onChangeCalendarMode(!calendarMode)}
            testID={`${testID}-mode-toggle`}
          >
            {calendarMode ? <Edit /> : <Today />}
          </IconButton>
        </Stack>
      </Box>
      <VSpacer size="4" />
      <Divider />
      {calendarMode ? (
        <DatePickerCalendar
          internalStartDate={date}
          onChange={(date) => onChangeDate(date)}
          testID={testID}
          variant="modal"
        />
      ) : (
        <Box sx={styles.inputModeContainer}>
          <VSpacer size="5" />
          <DatePickerInput
            label="Date"
            onChangeDate={(value) => onChangeDate(value)}
            testID={`${testID}-input`}
            value={date}
          />
          <VSpacer size="6" />
        </Box>
      )}
      <Stack
        direction="row"
        sx={styles.bottomBar}
      >
        <Button
          onClick={onClose}
          testID={`${testID}-cancel-button`}
          variant="text"
        >
          Cancel
        </Button>
        <HSpacer size="2" />
        <Button
          onClick={onSave}
          testID={`${testID}-ok-button`}
          variant="text"
        >
          OK
        </Button>
      </Stack>
    </Stack>
  );
};

export interface DatePickerModalProps {
  onChange: (value?: Date) => void,
  onClose: () => void,
  open?: boolean,
  showCalendarMode?: boolean,
  testID: string,
  value?: Date,
}

export const DatePickerModal = ({
  onChange,
  onClose,
  open = false,
  showCalendarMode = false,
  testID,
  value,
}: DatePickerModalProps) => {

  const [calendarMode, setCalendarMode] = useState(showCalendarMode);
  const [date, setDate] = useState(value);

  const handleSave = () => {
    onChange(date);
    onClose();
  };

  return (
    <>
      <DesktopOnly>
        <MuiModal
          data-testid={testID}
          onClose={onClose}
          open={open}
        >
          <Box sx={{
            ...styles.container,
            width: calendarMode ? 360 : 328,
          }}>
            <DatePicker
              calendarMode={calendarMode}
              date={date}
              onChangeCalendarMode={setCalendarMode}
              onChangeDate={setDate}
              onClose={onClose}
              onSave={handleSave}
              testID={testID}
            />
          </Box>
        </MuiModal>
      </DesktopOnly>
      <MobileOnly>
        <FullscreenDialog
          actionButton={<></>}
          contentStyle={{ padding: 0 }}
          onClose={onClose}
          open={open}
          testID={testID}
        >
          <DatePicker
            calendarMode={calendarMode}
            date={date}
            onChangeCalendarMode={setCalendarMode}
            onChangeDate={setDate}
            onClose={onClose}
            onSave={handleSave}
            testID={testID}
          />
        </FullscreenDialog>
      </MobileOnly>
    </>
  );
};
