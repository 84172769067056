import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { HSpacer } from '../Spacer/HSpacer';
import { Text } from '../Text/Text';
import { Input, InputProps } from './Input';

export type DateInputProps = InputProps & {
  onChangeDate?: (value?: Date) => void,
};

export const DateInput = ({
  onChangeDate,
  onChangeText,
  prefix,
  required,
  value,
  ...rest
}: DateInputProps) => {

  const [
    internalValue,
    setInternalValue,
  ] = useState<string>();

  useEffect(() => {
    if (!value) {
      setInternalValue(undefined);
    } else {
      setInternalValue(`${typeof value !== "string" ? (value as Date).toISOString() : value}`.split("T")[0]);
    }
  }, [value]);

  const handleChangeText = (text: string) => {
    onChangeText?.(text);
    if (text) {
      const dateValue = DateTime.fromISO(text);
      if (dateValue.isValid) {
        setInternalValue(text);
        onChangeDate?.(dateValue.toJSDate());
      }
    } else {
      setInternalValue(undefined);
      onChangeDate?.(undefined);
    }
  };

  return (
    <Input
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        startAdornment: !!prefix && (
          <Stack>
            <Text category="p1">
              {prefix}
            </Text>
            <HSpacer size="4" />
          </Stack>
        ),
      }}
      onChangeText={handleChangeText}
      required={required}
      type="date"
      value={internalValue}
      {...rest}
    />
  );
};
