import { useState } from 'react';
import { Button, MenuItem, Modal, Search, Select, Text, VSpacer } from '@/components/DesignSystem';
import { SelectChangeEvent } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import { QueryKeys } from '@/constants/QueryKeys';
import { ApiManufacturer } from '@api/interfaces';
import { FeaturedManufacturerApi } from '@/utilities/api/FeaturedManufacturerApi';
import { useSearch } from '@/hooks/useSearch';
import { HomeScreenSelectLabels, ToastMessages } from '@/constants/constant';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';

const AddFeaturedManufacturers = ({
  addFeaturedManufacturer,
  close,
}: {
  addFeaturedManufacturer: boolean;
  close: (args: boolean) => void;
}) => {
  const [selectedManufacturer, setSelectedManufacturer] =
    useState<ApiManufacturer>();

  const { search, setSearch, debouncedSearch } = useSearch();

  const { openSnackbar } = useSnackbar();

  const { data: manufacturers } = useQuery(
    [QueryKeys.GET_MANUFACTURERS, debouncedSearch],
    () => ManufacturerApi.list({ search: debouncedSearch }),
  );

  const { data: featuredManufacturers } = useQuery(
    [QueryKeys.GET_FEATURED_MANUFACTURERS],
    () => FeaturedManufacturerApi.list(),
  );

  const queryClient = useQueryClient();

  const { mutate: addFeaturedManufacturerData, isLoading: isAddSaving } =
    useMutation(
      () =>
        FeaturedManufacturerApi.Create({
          manufacturerName: selectedManufacturer?.name ?? '',
          manufacturerId: selectedManufacturer?.id ?? '',
        }),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            QueryKeys.GET_FEATURED_MANUFACTURERS,
          ]);
          openSnackbar(ToastMessages.featuredManufacturerAddSuccess);
          close(true);
        },
        onError: (error: DetailedApiError) => {
          openSnackbar(error.message);
        },
      },
    );

  const unSelectedManufacturers = manufacturers?.filter(
    (manufacturer: ApiManufacturer) =>
      !featuredManufacturers?.some(
        (featuredManufacturer) =>
          featuredManufacturer.manufacturerId === manufacturer.id,
      ),
  );

  const handleSelect = (e?: SelectChangeEvent<unknown>) => {
    const selectedId = e?.target?.value;
    const selectedObject = unSelectedManufacturers?.find(
      (option: ApiManufacturer) => option.id === selectedId,
    );
    setSelectedManufacturer(selectedObject);
  };

  return (
    <Modal
      acceptButton={() => (
        <Button
          disabled={!selectedManufacturer?.id}
          loading={isAddSaving}
          onClick={() => {
            addFeaturedManufacturerData();
          }}
          testID='save'
          variant='contained'
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          disabled={isAddSaving}
          onClick={() => close(false)}
          {...props}
        >
          Cancel
        </Button>
      )}
      onClose={() => close(false)}
      open={addFeaturedManufacturer}
      testID='add-manufacturer'
    >
      <Text category='h5'>Add Featured Manufacturer</Text>
      <VSpacer size='7' />
      <Select
        label={HomeScreenSelectLabels.Manufacturers}
        labelId='demo-simple-select-label'
        onChange={(event) => {
          handleSelect(event);
        }}
        onClear={() => {
          setSelectedManufacturer(undefined);
        }}
        testID='demo-simple-select'
        value={selectedManufacturer?.id}
      >
        <VSpacer size='5' />
        <MenuItem onKeyDown={(e) => e.stopPropagation()} testID='search'>
          <Search
            fullWidth
            onChangeText={setSearch}
            testID='search'
            value={search}
          />
        </MenuItem>
        {unSelectedManufacturers?.length === 0 && (
          <MenuItem disabled testID='no-manufactueres'>
            No Manufacturers Available
          </MenuItem>
        )}
        {unSelectedManufacturers?.map((manufacturer: ApiManufacturer) => (
          <MenuItem
            key={manufacturer?.id}
            testID={`manufacturer-${manufacturer?.id}`}
            value={manufacturer?.id}
          >
            {manufacturer.name}
          </MenuItem>
        ))}
      </Select>
    </Modal>
  );
};

export default AddFeaturedManufacturers;
