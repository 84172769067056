import { Typography } from '@/themes/MUITheme/typography';
import { styled, Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@mui/material';

export interface SplitTabsProps extends MuiTabsProps {
  testID: string,
}

const StyledSplitTabs = styled(((props: MuiTabsProps) => (
  <MuiTabs
    variant="fullWidth"
    {...props}
  />
)))(({ theme }) => ({
  '&.MuiTabs-root': {
    borderRadius: "80px",
  },
  '& .MuiButtonBase-root': {
    ...Typography["title-medium"],
    backgroundColor: "transparent",
    borderRadius: "80px",
    height: "56px",
    zIndex: 2,
  },
  '.MuiTabs-flexContainer': {
    backgroundColor: theme.palette.background.default,
    borderRadius: "80px",
  },
  '.Mui-selected': {
    color: `${theme.palette.primary.contrastText} !important`,
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "80px",
    height: "56px",
    zIndex: 1,
  },
}));

export const SplitTabs = ({
  testID,
  ...rest
}: SplitTabsProps) => {
  return <StyledSplitTabs data-testid={testID} {...rest} />;
};
