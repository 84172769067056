import { ButtonProps, FullscreenDialog, SideSheet } from '@/components/DesignSystem';
import { Box, SxProps, Theme } from '@mui/material';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { ReactNode } from 'react';

export interface ResponsiveSideSheetProps {
  actionButton?: (props: ButtonProps) => ReactNode,
  children: ReactNode,
  hideCloseButton?: boolean,
  hideScrollbar?: boolean,
  onClose: () => void,
  onOpen?: () => void,
  open?: boolean,
  secondaryActionButton?: (props: ButtonProps) => ReactNode,
  fullScreenDialogStyles?: SxProps<Theme>,
  testID: string,
  title: string,
  fullScreenDialogTitleStyle?: SxProps<Theme>,
}

export const ResponsiveSideSheet = ({
  actionButton,
  children,
  hideCloseButton,
  hideScrollbar,
  onClose,
  onOpen,
  open = false,
  secondaryActionButton,
  fullScreenDialogStyles,
  testID,
  title,
  fullScreenDialogTitleStyle,
}: ResponsiveSideSheetProps) => {
  return (
    <>
      <MobileOnly>
        <FullscreenDialog
          actionButton={
            <>
              {actionButton?.({ testID: `${testID}-action-button` })}
            </>
          }
          contentStyle={{
            px: '16px',
          }}
          hideCloseButton={hideCloseButton}
          onClose={onClose}
          open={open}
          sx={fullScreenDialogStyles}
          testID={testID}
          title={title}
          titleStyle={fullScreenDialogTitleStyle}
        >
          {children}
        </FullscreenDialog>
      </MobileOnly>
      <DesktopOnly>
        <SideSheet
          actionButton={actionButton}
          centerActions
          hideScrollbar={hideScrollbar}
          onClose={onClose}
          onOpen={() => {
            onOpen?.();
          }}
          open={open}
          secondaryActionButton={secondaryActionButton}
          testID={testID}
          title={title}
        >
          <Box sx={{
            px: '24px',
            pb: '16px',
          }}>
            {children}
          </Box>
        </SideSheet>
      </DesktopOnly>
    </>
  );
};
