export enum PushNotificationRedirect {
  Cart = 'Cart',
  FarmerRecommendationChat = 'Recommendation/#RECOMMENDATIONID#/Chat',
  FarmerRecommendationDetails = 'Recommendation/#RECOMMENDATIONID#',
  FarmerRequestChat = 'Request/#REQUESTID#/Chat',
  FarmerRequestDetails = 'Request/#REQUESTID#',
  NotificationFeed = 'Notification-feed',
  RetailerRecommendationChat = 'Retailer/Recommendation/#RECOMMENDATIONID#/Chat/#CHATID#',
  RetailerRequestDetails = 'Retailer/Request/#REQUESTID#',
  RetailerRequestChat = 'Retailer/Request/#REQUESTID#/Chat',
  Rewards = 'Rewards',
  Test = 'Test-redirect',
}
