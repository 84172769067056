import {
  BottomSheet,
  Button,
  Modal,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import {
  Box,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material';
import { DesktopOnly } from '../DesktopOnly';
import { MobileOnly } from '../MobileOnly';
import Check from "@mui/icons-material/Check";
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { Typography } from '@/themes/MUITheme/typography';
import { getResponsiveValue } from '@/hooks/useMediaQuery';

export interface ResponsivePickerProps<T> {
  data: T[],
  onChange: (item?: T) => void,
  onClose: () => void,
  open: boolean,
  showClearButton?: boolean,
  testID: string,
  title?: string,
  value?: T,
}

export interface ResponsivePickerDefault {
  selectOnClick?: boolean,
  rightIcon?: ReactNode,
  toString?: () => string,
}

export const ResponsivePicker = <T extends ResponsivePickerDefault>({
  data,
  onChange,
  onClose,
  open,
  showClearButton,
  testID,
  title,
  value,
}: ResponsivePickerProps<T>) => {

  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    if (open) {
      setSelectedValue(value);
    }
  }, [value, open]);

  const handleSave = () => {
    onChange(selectedValue);
    onClose();
  };

  const List = (props: {
    closeOnClick: boolean,
    isMobile: boolean,
    testID: string,
  }) => (
    <Stack data-testid={`${testID}-list-container`}>
      {!props.isMobile && <Divider />}
      {data.map((item, index) => {
        const labelId = `${testID}-option-${index}`;

        return (
          <Fragment key={item.toString?.()}>
            <ListItem
              disablePadding
              secondaryAction={
                item.rightIcon ||
                (selectedValue === item ? <Check color="primary" /> : <></>)
              }
              sx={{
                '.MuiListItemSecondaryAction-root': {
                  paddingRight: "0",
                  right: getResponsiveValue('24px', '13px'),
                },
              }}
            >
              <ListItemButton
                data-testid={labelId}
                onClick={() => {
                  if (props.closeOnClick || item.selectOnClick) {
                    onChange(item);
                    onClose();
                  } else {
                    setSelectedValue(item);
                  }
                }}
                sx={{
                  height: "56px",
                  paddingLeft: getResponsiveValue('16px', '4px'),
                  '.MuiListItemText-root span': {
                    ...Typography["body-large"],
                  },
                }}
              >
                <ListItemText
                  id={labelId}
                  primary={item.toString?.()}
                />
              </ListItemButton>
            </ListItem>
            {!props.isMobile && <Divider />}
          </Fragment>
        );
      })}
      {
        showClearButton && (
          <>
            <Divider />
            <ListItem
              disablePadding
              sx={{
                '.MuiListItemSecondaryAction-root': {
                  paddingRight: "0",
                  right: getResponsiveValue('24px', '13px'),
                },
              }}
            >
              <ListItemButton
                data-testid={`${testID}-clear-option`}
                onClick={() => {
                  if (props.closeOnClick) {
                    onChange(undefined);
                    onClose();
                  } else {
                    setSelectedValue(undefined);
                  }
                }}
                sx={{
                  height: "56px",
                  paddingLeft: getResponsiveValue('16px', '4px'),
                  '.MuiListItemText-root span': {
                    ...Typography["body-large"],
                  },
                }}
              >
                <ListItemText
                  primary="Clear"
                />
              </ListItemButton>
            </ListItem>
          </>
        )
      }
    </Stack>
  );

  return (
    <>
      <DesktopOnly>
        <Modal
          acceptButton={() => (
            <Button
              disabled={value === selectedValue}
              onClick={handleSave}
              testID={`${testID}-save-button`}
              variant="text"
            >
              Save
            </Button>
          )}
          cancelButton={() => (
            <Button
              onClick={onClose}
              testID={`${testID}-cancel-button`}
              variant="text"
            >
              Cancel
            </Button>
          )}
          onClose={onClose}
          open={open}
          padding={2.5}
          testID={testID}
          title={title}
        >
          <Box
            sx={{
              maxHeight: '490px',
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
          >
            <List closeOnClick={false} isMobile={false} testID={testID} />
          </Box>
        </Modal>
      </DesktopOnly>
      <MobileOnly>
        <BottomSheet
          onClose={onClose}
          //eslint-disable-next-line @typescript-eslint/no-empty-function
          onOpen={() => { }}
          open={open}
          testID={testID}
        >
          <Stack alignItems="center">
            <Text category="title-large">
              {title}
            </Text>
          </Stack>
          <VSpacer size="3" />
          <List closeOnClick isMobile testID={testID} />
        </BottomSheet>
      </MobileOnly>
    </>
  );
};
