import { VSpacer } from '@/components/DesignSystem';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useShoppingCart } from '@/hooks/useShoppingCart';
import { AccountIdQueryKey, AccountNameQueryKey } from '@/pages/Auth/PortalLogin';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { OauthApi } from '@/utilities/api/OauthApi';
import { QueryUtil } from '@/utilities/Query';
import { ShoppingCartUtils } from '@/utilities/ShoppingCartUtils';
import { CircularProgress, Container, Stack } from '@mui/material';
import { LocalStorageUtility } from '@shared/utilities';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

export const OauthVerify = () => {
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const { refreshUser } = useAuthentication();
  const { saveGuestShoppingCart } = useShoppingCart();

  const searchParams = QueryUtil.getSearchParams(location);
  const code = searchParams.get('code');
  const accountId = LocalStorageUtility.getItem(AccountIdQueryKey);
  const accountName = LocalStorageUtility.getItem(AccountNameQueryKey);
  LocalStorageUtility.remove(AccountIdQueryKey);
  LocalStorageUtility.remove(AccountNameQueryKey);

  useEffect(() => {
    if (code && accountId && accountName) {
      verifyCode();
    }
  }, []);

  const { mutate: verifyCode } = useMutation(
    async () => {
      if (code && accountId && accountName) {
        await OauthApi.verifyCode({ accountId, accountName, code });
      }
    },
    {
      onError: () => {
        openSnackbar('Authentication failed');
        navigate(Routes.ERROR, { replace: true });
      },
      onSuccess: async () => {
        const user = await refreshUser();
        if (user) {
          await saveGuestShoppingCart(user, ShoppingCartUtils.getShoppingCart());
        }
        window.location.replace('/');
      },
      retry: false,
    },
  );

  return (
    <Container>
      <Stack alignItems="center">
        <VSpacer size="14" />
        <CircularProgress />
      </Stack>
    </Container>
  );
};
