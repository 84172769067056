import {
  Card as MuiCard,
  CardContent,
  CardProps as MuiCardProps,
  SxProps,
  Theme,
} from '@mui/material';

interface CardProps extends MuiCardProps {
  cardContentStyle?: SxProps<Theme>,
  children?: React.ReactNode,
  testID: string,
}

export const Card = ({
  cardContentStyle,
  children,
  testID,
  ...rest
}: CardProps) => {
  return (
    <MuiCard data-testid={testID}  {...rest}>
      <CardContent sx={cardContentStyle}>
        {children}
      </CardContent>
    </MuiCard>
  );
};
