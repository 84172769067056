import { Client } from '@/utilities/api/Client';
import { QueryUtil } from '@/utilities/Query';
import { NotificationEndpoint, TestEndpoint } from '@api/endpoints';

export class NotificationApi {
  static getValues (): Promise<TestEndpoint.ListNotificationValues.Response> {
    return Client('test/notifications/values');
  }

  static list (
    query: NotificationEndpoint.List.Query,
  ): Promise<NotificationEndpoint.List.Response> {
    const queryString = QueryUtil.stringify(query);
    return Client(`notifications?${queryString}`);
  }

  static sendTestNotification (
    body: TestEndpoint.SendTestNotification.Request,
  ): Promise<TestEndpoint.SendTestNotification.Response> {
    return Client('test/notifications/send', { body, method: 'POST' });
  }
}
