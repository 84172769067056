import { ColorCategory } from '@/themes/variant-interfaces/ColorCategory';
import { Fab as MuiFab, FabProps as MuiFabProps } from '@mui/material';
import { ReactNode } from 'react';

export interface FabProps extends Omit<MuiFabProps, 'color'> {
  children?: ReactNode,
  color?: Exclude<ColorCategory, "secondary">,
  disabled?: boolean,
  onClick?: () => void,
  size?: "medium" | "small",
  testID: string,
  variant?: "extended",
}

export const Fab = ({
  children,
  color,
  disabled = false,
  onClick,
  size,
  testID,
  variant,
  ...rest
}: FabProps) => {


  const props = {
    color,
    "data-testid": testID,
    disabled,
    focusRipple: false,
    onClick,
    size,
    variant,
    ...rest,
  };

  return (
    <MuiFab {...props}>
      {children}
    </MuiFab>
  );
};
