import { Button, Modal, Text, VSpacer } from '@/components/DesignSystem';
import { ButtonProps } from '@/components/DesignSystem/Button/Button';
import { QueryKeys } from '@/constants/QueryKeys';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { PaymentApi } from '@/utilities/api/PaymentApi';
import { PaymentEndpoint } from '@api/endpoints';
import { ApiPricingRequest } from '@api/interfaces';
import Error from '@mui/icons-material/Error';
import { Stack } from '@mui/material';
import {
  AllowedPaymentMethodType,
  PaymentStatus,
  PricingRequestPaymentStatus,
} from '@shared/enums';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

interface SubmitFarmerPaymentButtonProps extends ButtonProps {
  email: string,
  isFormComplete: boolean,
  onSuccess: (status: PricingRequestPaymentStatus) => void,
  productRequest: ApiPricingRequest,
  selectedPaymentMethod: AllowedPaymentMethodType,
}

const { userAgent } = window.navigator;

const SubmitFarmerPaymentButton = ({
  email,
  isFormComplete,
  onSuccess,
  productRequest,
  selectedPaymentMethod,
  ...rest
}: SubmitFarmerPaymentButtonProps) => {
  const elements = useElements();
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const queryClient = useQueryClient();

  const handleError = (error: string) => {
    setIsLoading(false);
    setErrorMessage(error);
    setShowErrorModal(true);
  };

  const handleServerResponse = async (
    response: PaymentEndpoint.CreateFarmerPaymentIntent.Response,
    paymentMethodId: string,
  ) => {
    if (!stripe) {
      return;
    }
    if (response.status === PaymentStatus.REQUIRES_ACTION && response.clientSecret) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const nextAction = await stripe.handleNextAction({
        clientSecret: response.clientSecret,
      });
      if (nextAction?.error?.message) {
        handleError(nextAction.error.message);
      } else {
        await queryClient.invalidateQueries([QueryKeys.GET_PRICING_REQUEST, productRequest.id]);
      }
    } else if (response.clientSecret) {
      const confirmResponse = await stripe.confirmPayment({
        clientSecret: response.clientSecret,
        redirect: 'if_required',
        confirmParams: { payment_method: paymentMethodId, receipt_email: email },
      });
      if (confirmResponse.error?.message) {
        handleError(confirmResponse.error.message);
      } else {
        const response = await PaymentApi.farmerPaymentTransfer(productRequest.id);
        onSuccess(response.pricingRequestPaymentStatus);
      }
    }
  };

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    setErrorMessage('');
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);

    const { error: submitError } = await elements.submit();
    if (submitError?.message) {
      handleError(submitError.message);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          email: productRequest.user?.email ?? undefined,
          name: productRequest.user?.firstName ?? undefined,
          phone: productRequest.user?.telephone ?? undefined,
        },
      },
    });

    if (error?.message) {
      handleError(error.message);
      return;
    }

    if (productRequest.id && paymentMethod) {
      try {
        const res = await PaymentApi.createFarmerPaymentIntent(productRequest.id, {
          paymentMethod: selectedPaymentMethod,
          paymentMethodId: paymentMethod.id,
          priceRequestId: productRequest.id,
          userAgent,
        });

        await handleServerResponse(res, paymentMethod.id);
      } catch (err) {
        if (err instanceof DetailedApiError) {
          const paymentElement = elements.getElement('payment');
          paymentElement?.clear();
          handleError(err.message);
        } else {
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <>
      <Button
        disabled={isLoading || !stripe || !elements || !productRequest || !isFormComplete}
        onClick={handleSubmit}
        {...rest}
        testID="submit-payment-button"
        variant="contained"
      >
        Submit
      </Button>
      {showErrorModal &&
        <Modal
          acceptButton={(props) => (
            <Button
              onClick={() => setShowErrorModal(false)}
              {...props}
            >
              Close
            </Button>
          )}
          heroIcon={<Error color="error" />}
          onClose={() => setShowErrorModal(false)}
          open
          testID="payment-error-modal"
        >
          <Stack alignItems="center" flex={1} textAlign="center">
            <Text category="title-large">Payment failed</Text>
            <VSpacer size="6" />
            <Text category="body-medium">{errorMessage}</Text>
          </Stack>
        </Modal>
      }
    </>
  );
};

export default SubmitFarmerPaymentButton;
