import { QueryKeys } from '@/constants/QueryKeys';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { CountyApi } from '@/utilities/api/CountyApi';
import { useQuery, useQueryClient } from 'react-query';

export const useCountyList = () => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();

  const invalidateCounties = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    queryClient.invalidateQueries([QueryKeys.GET_COUNTIES]);
  };

  const { data, isLoading } = useQuery(
    [QueryKeys.GET_COUNTIES],
    () => CountyApi.list(),
    {
      onError: (error: { message: string }) => {
        openSnackbar(
          error.message || "An error has occurred",
        );
      },
    },
  );

  return {
    counties: data || [],
    invalidateCounties,
    isLoading,
  };
};

