/* eslint-disable max-len -- ignored for long regex patterns */

export const EmailRegex: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PhoneRegex: RegExp = /^((?:(([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})([0-9]{4})|([0-1][0-9][0-9])(\d{7})|([6789]\d{9}))(#\w+)?$/;
export const ProdPhoneRegex: RegExp = /^((?:(([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})([0-9]{4})|([6789]\d{9}))(#\w+)?$/;
export const PostalCodeRegex: RegExp = /^\d{5}(-\d{4})?$/;

/**
 * @pattern ^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$ Please enter a valid email address
 * @example "user@domain.com"
 */
export type Email = string;


//^((?:(([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})([0-9]{4})|([0-1][0-9][0-9])(\d{7}))(#\w+)?$
//Adding temp check to allow IND numbers for testing purchase - 2023-08-31
/**
 * @pattern ^((?:(([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})([0-9]{4})|([0-1][0-9][0-9])(\d{7})|([6789]\d{9}))(#\w+)?$ Please enter a valid phone number
 * @example "3305143662"
 */
export type Phone = string;

/**
 * @pattern \d{5}
 * @example "10002"
 */
export type PostalCode = string;

/**
 * @example "string"
 * @pattern \S+ Value must not be an empty string or contain only spaces
 */
export type NonEmptyString = string;

export const validateEmail = (email: Email | undefined | null): boolean => {
  if (!email) {
    return false;
  }
  return EmailRegex.test(email);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- See TODO below
export const validatePhoneNumber = (phone: Phone | undefined | null, isTestEnv: boolean): boolean => {
  if (!phone) {
    return false;
  }
  // TODO: Re-enable rejecting invalid phone numbers in prod
  // return isTestEnv ? PhoneRegex.test(phone) : ProdPhoneRegex.test(phone);
  return PhoneRegex.test(phone);
};

export const validatePostalCode = (postalCode: PostalCode | undefined | null): boolean => {
  if (!postalCode) {
    return false;
  }
  return PostalCodeRegex.test(postalCode);
};
