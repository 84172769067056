import { PaymentMethodPreference } from '@shared/enums';
import { PaymentMethodNew } from '@shared/enums/PaymentMethod';

export const FriendlyPaymentMethod = {
  [PaymentMethodPreference.INSIDE]: 'Farmer pays in app (ACH or Credit Card)',
  [PaymentMethodPreference.OUTSIDE]: 'Farmer pays outside of app (Check or Financing)',
  [PaymentMethodPreference.ANY]: 'Any',
};

export const FriendlyBadgePaymentMethod = {
  [PaymentMethodPreference.INSIDE]: 'Payment in-app',
  [PaymentMethodPreference.OUTSIDE]: 'Payment outside of app',
};

export const FriendlyPaymentMethodNew = {
  [PaymentMethodNew.Bank]: 'ACH',
  [PaymentMethodNew.Card]: 'Credit card',
  [PaymentMethodNew.Financing]: 'Request financing',
  [PaymentMethodNew.OnAccount]: 'On account',
  [PaymentMethodNew.Rewards]: 'Rewards',
};
