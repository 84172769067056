import {
  Button,
  Card,
  Checkbox,
  HSpacer,
  IconButton,
  Input,
  NumericInput,
  Text,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import ConfirmModal from '@/components/shared/ConfirmModal';
import { ConfirmationDialog, ToastMessages } from '@/constants/constant';
import { FriendlyFulfillmentMethod } from '@/constants/FriendlyFulfillmentMethod';
import { FriendlyPaymentType } from '@/constants/FriendlyPaymentType';
import { QueryKeys } from '@/constants/QueryKeys';
import { Routes } from '@/constants/Routes';
import AddSalesPerson from '@/pages/Admin/PricingRequestList/AddSalesPerson';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { PricingRequestRetailersApi } from '@/utilities/api/PricingRequestRetailersApi';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import { ApiPricingRequest, ApiPricingRequestRetailer } from '@api/interfaces';
import Add from '@mui/icons-material/Add';
import Check from '@mui/icons-material/Check';
import Delete from '@mui/icons-material/Delete';
import Search from '@mui/icons-material/Search';
import { Stack } from "@mui/material";
import { FulfillmentMethod, PaymentType, PricingRequestStatus } from '@shared/enums';
import { formatDate } from '@shared/utilities/DateUtils';
import React, { Fragment, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ExtendPricingRequestModal from './ExtendPricingRequestModal';
import OfferModalReadOnly from './OfferModalReadOnly';

interface ReviewPricingRequestProps {
  pricingRequest: ApiPricingRequest,
}

interface SalespersonCardProps {
  salesperson: ApiPricingRequestRetailer,
}

const ReviewPricingRequestReadOnly = ({ pricingRequest }: ReviewPricingRequestProps) => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();
  const [offerId, setOfferId] = useState<string>();
  const [offerSalesperson, setOfferSalesperson] = useState<ApiPricingRequestRetailer>();
  const [showExtendWindowModal, setShowExtendWindowModal] = useState(false);
  const [showAddSalespersonModal, setShowAddSalespersonModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [salespersonId, setSalespersonId] = useState('');

  const openPlaceOfferLink = async (
    pricingRequestId: string,
    pricingRequestRetailerId: string,
  ) => {
    const inviteToken = await PricingRequestRetailersApi.getInviteToken(
      pricingRequestRetailerId,
    );
    const linkUrl = `${window.location.href.split("#")[0]}` +
      `#${Routes.PLACE_OFFER.replace(":id", pricingRequestId)}` +
      `?token=${inviteToken}`;
    window.open(linkUrl);
  };

  const isOpenOrAwaitingApproval = (
    pricingRequest.status === PricingRequestStatus.Open
    || pricingRequest.status === PricingRequestStatus.AwaitingApproval
  );

  const partners = pricingRequest.salespersons?.filter(({ salespersonId }) => salespersonId)
    || [];

  const { mutate: deleteSalesperson } = useMutation(
    ({ pricingRequestId, salespersonId }: { pricingRequestId: string, salespersonId: string }) => (
      PricingRequestsApi.deleteRetailerForAdmin(pricingRequestId, salespersonId)), {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.GET_PRICING_REQUEST]);
        openSnackbar(
          ToastMessages.deleteSalesPerson,
          5000,
        );
        setShowConfirmDelete(false);
      },
      onError: () => {
        openSnackbar(
          'There was an error deleting the salesperson',
          5000,
        );
      },
    },
  );

  const SalespersonCard = ({
    salesperson,
  }: SalespersonCardProps,
  ) => {
    const offer = (
      pricingRequest.offers?.find((offer) => offer.pricingRequestRetailerId === salesperson.id)
    );
    const canDeleteRetailer = pricingRequest.salespersons && pricingRequest.salespersons?.length > 1
      && !offer
      && isOpenOrAwaitingApproval;
    const isExists = salesperson.retailerSalesperson?.email
    && salesperson.retailerSalesperson?.telephone;
    const divider = isExists ? ' | ' : '';
    return (
      <>
        <VSpacer size="3" />
        <Card testID="pricing-request-read-only">
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Stack>
              <Text category="h6">{salesperson?.retailerSalesperson?.businessName}</Text>
              <Text category="p2">
                {salesperson.retailerSalesperson?.telephone}
                {divider}
                {salesperson.retailerSalesperson?.email}
              </Text>
              <VSpacer size="3" />
              <TextLink
                onClick={() => openPlaceOfferLink(pricingRequest.id, salesperson.id)}
                testID="pricing-request-details-read-only-text-link"
              >
                Invitation to place offer
              </TextLink>
            </Stack>
            {!!offer && (
              <Button
                onClick={() => {
                  setOfferId(offer.id);
                  setOfferSalesperson(salesperson);
                }}
                size="small"
                startIcon={<Search />}
                testID="pricing-request-read-only-view-offer-button"
                variant="outlined"
              >
                View offer
              </Button>
            )}
            {canDeleteRetailer && (
              <Stack>
                <IconButton
                  onClick={() => {
                    setSalespersonId(salesperson.id);
                    setShowConfirmDelete(true);
                  }}
                  testID="pricing-request-details-readonly-icon-button"
                >
                  <Delete />
                </IconButton>
              </Stack>
            )}
          </Stack>
        </Card>
      </>
    );
  };

  const ApprovalDetails = () => {
    if (pricingRequest.status === PricingRequestStatus.Draft) {
      return <></>;
    }
    if (!!pricingRequest.approvedDate || !!pricingRequest.rejectedDate) {
      return (
        <>
          <Text category="p2">
            {`This product request was ${(pricingRequest.approvedDate
              ? 'approved' : 'rejected')} on ${
              formatDate((pricingRequest.approvedDate || pricingRequest.rejectedDate) as Date)
            }`}
          </Text>
          <VSpacer size="3" />
        </>
      );
    }

    return (
      <>
        <Text category="p2">
          This product request needs to be reviewed.
          Allow or reject it using the buttons down below.
        </Text>
        <VSpacer size="3" />
      </>
    );
  };

  return (
    <Stack>
      <ApprovalDetails />
      {!!pricingRequest.products?.length && (
        <>
          <VSpacer size="10" />
          <Text category="s1">Products</Text>
          <VSpacer size="5" />
          {pricingRequest.products?.map((product, index) => (
            <Fragment key={product.id}>
              <VSpacer size="3" />
              <Card testID={`pricing-request-${index}`}>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Text category="h6">{product?.name}</Text>
                    <VSpacer size="7" />
                    {product.allowSubstitutions && (
                      <Stack alignItems="center" direction="row">
                        <Check />
                        <HSpacer size="4" />
                        <Text category="p2">Generic products OK</Text>
                      </Stack>
                    )}
                    <VSpacer size="7" />
                    <Stack direction="row">
                      <NumericInput
                        disabled
                        label="Qty"
                        testID="pricing-request-details-read-only-numeric-input"
                        value={product.quantity ?? undefined}
                      />
                      <HSpacer size="5" />
                      <Input
                        disabled
                        label="UoM"
                        testID="pricing-request-details-read-only-input"
                        value={product.uom?.toLowerCase()}
                      />
                    </Stack>
                    <VSpacer size="7" />
                    <Input
                      disabled
                      label="Package size"
                      testID="package-size-read-only-input"
                      value={product.package}
                    />
                    <VSpacer size="6" />
                  </Stack>
                </Stack>
              </Card>
            </Fragment>
          ))}
        </>
      )}
      <Stack>
        <VSpacer size="10" />
        <Stack direction="row" justifyContent="space-between">
          <Text category="s1">Salespersons</Text>
          {isOpenOrAwaitingApproval && (
            <Button
              onClick={() => setShowAddSalespersonModal(true)}
              startIcon={<Add />}
              testID="pricing-request-read-only-add-retailer-button"
            >
              Add Salesperson
            </Button>
          )}
        </Stack>
        {pricingRequest.salespersons?.length ? (
          <>
            <VSpacer size="5" />
            {pricingRequest.salespersons?.map((salesperson) => (
              <SalespersonCard key={salesperson.id} salesperson={salesperson} />
            ))}
          </>
        ) : (
          <Stack alignItems="center">
            <VSpacer size="5" />
            <Text category="body-large">No Salespersons</Text>
          </Stack>
        )}
      </Stack>
      <VSpacer size="10" />
      <Text category="h3">Financing</Text>
      <VSpacer size="5" />
      <Text category="p2">
        How will you be financing this order?
      </Text>
      <VSpacer size="8" />
      <Input
        disabled
        label="Payment type"
        sx={{ width: "372px" }}
        testID="pricing-request-details-read-only-disabled-input"
        value={FriendlyPaymentType[pricingRequest?.paymentType ?? PaymentType.CreditCard]}
      />
      <VSpacer size="10" />
      <Text category="h3">Fulfillment Method</Text>
      <VSpacer size="5" />
      <Text category="p2">
        How would you like to receive the products in this product request?
      </Text>
      <VSpacer size="8" />
      <Stack direction="row">
        <Checkbox
          checked={pricingRequest.fulfillmentMethod === FulfillmentMethod.PICKUP}
          disabled
          testID="pricing-request-details-pickup-read-only-checkbox"
          >
          <Text category="p2">
            {FriendlyFulfillmentMethod[FulfillmentMethod.PICKUP]}
          </Text>
        </Checkbox>
        <HSpacer size="3" />
        <Checkbox
          checked={pricingRequest.fulfillmentMethod === FulfillmentMethod.DELIVERY}
          disabled
          testID="pricing-request-details-delivery-read-only-checkbox"
        >
          <Text category="p2">
            {FriendlyFulfillmentMethod[FulfillmentMethod.DELIVERY]}
          </Text>
        </Checkbox>
        <HSpacer size="3" />
        <Checkbox
          checked={pricingRequest.fulfillmentMethod === FulfillmentMethod.NO_PREFERENCE}
          disabled
          testID="pricing-request-details-no-preference-read-only-checkbox"
        >
          <Text category="p2">
            {FriendlyFulfillmentMethod[FulfillmentMethod.NO_PREFERENCE]}
          </Text>
        </Checkbox>
      </Stack>
      {pricingRequest.fulfillmentMethod === FulfillmentMethod.DELIVERY && (
        <>
          <VSpacer size="8" />
          <Text category="c2">DELIVERY ADDRESS</Text>
          <VSpacer size="6" />
          <Stack alignItems="center">
            <Input
              disabled
              label="Address 1"
              sx={{ width: "372px" }}
              testID="pricing-request-details-address-read-only"
              value={pricingRequest.deliveryAddress1}
            />
            <VSpacer size="5" />
            <Input
              disabled
              label="Address 2"
              sx={{ width: "372px" }}
              testID="pricing-request-details-address-2-read-only"
              value={pricingRequest.deliveryAddress2}
            />
            <VSpacer size="5" />
            <Input
              disabled
              label="City"
              sx={{ width: "372px" }}
              testID="pricing-request-details-city-read-only"
              value={pricingRequest.deliveryCity}
            />
            <VSpacer size="5" />
            <Input
              disabled
              label="State"
              sx={{ width: "372px" }}
              testID="pricing-request-details-state-read-only"
              value={pricingRequest.deliveryState}
            />
            <VSpacer size="5" />
            <Input
              disabled
              label="Zip"
              sx={{ width: "372px" }}
              testID="pricing-request-details-zip-read-only"
              value={pricingRequest.deliveryPostalCode}
            />
          </Stack>
        </>
      )}
      <VSpacer size="10" />
      {!!pricingRequest.note && (
        <>
          <Text category="h3">Note</Text>
          <VSpacer size="5" />
          <Text category="p2">
            If you need, you can leave a note to the salesperson
          </Text>
          <VSpacer size="8" />
          <Stack direction="row" justifyContent="center">
            <Input
              disabled
              maxLength={2000}
              maxRows={6}
              multiline
              testID="pricing-request-details-note-read-only"
              value={pricingRequest.note}
              width={372}
            />
          </Stack>
        </>
      )}
      {!!offerId && (
        <OfferModalReadOnly
          offerId={offerId}
          onClose={() => {
            setOfferId(undefined);
            setOfferSalesperson(undefined);
          }}
          requestedProducts={pricingRequest?.products}
          salesperson={offerSalesperson}
        />
      )}
      {showExtendWindowModal && (
        <ExtendPricingRequestModal
          onClose={() => {
            setShowExtendWindowModal(false);
          }}
          pricingRequest={pricingRequest}
        />
      )}
      {showAddSalespersonModal && (
        <AddSalesPerson
          onClose={() => setShowAddSalespersonModal(false)}
          partners={partners}
          pricingRequestId={pricingRequest.id}
          salespersonListData={pricingRequest.salespersons}
          userId={pricingRequest.userId}
        />
      )}
      {showConfirmDelete && (
        <ConfirmModal
          confirmButtonText="Delete"
          message={ConfirmationDialog?.removeFarmerSalespersonMessage}
          onCancel={() => setShowConfirmDelete(false)}
          onConfirm={() =>
            deleteSalesperson({ pricingRequestId: pricingRequest.id, salespersonId })}
          open
          testID="confirm-delete-modal"
          title={ConfirmationDialog?.removeFarmerSalespersonTitle}
        />
      )}
    </Stack>
  );
};

export default ReviewPricingRequestReadOnly;
