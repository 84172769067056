import React, { createContext, ReactNode, useState } from 'react';

export interface ShoppingCartContextType {
  isRequestView: boolean,
  setIsRequestView: (isRequestView: boolean) => void,
  setShowCart: (show: boolean) => void,
  showCart: boolean,
}

export const ShoppingCartContext = createContext<ShoppingCartContextType | null>(null);

export const ShoppingCartProvider = (props: {
  children: ReactNode,
}) => {
  const [showCart, setShowCart] = useState(false);
  const [isRequestView, setIsRequestView] = useState(false);

  return (
    <>
      <ShoppingCartContext.Provider value={{
        isRequestView,
        setIsRequestView,
        setShowCart,
        showCart,
      }}>
        {props.children}
      </ShoppingCartContext.Provider>
    </>
  );
};
