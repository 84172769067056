/* eslint-disable max-len -- ignored for api keys */
import { SharedConfig } from '@shared/SharedConfig';

const devel = !!(process.env.REACT_APP_API_URL?.includes("devel.growers"));
const local = !process.env.REACT_APP_API_URL;
const prod = !!(process.env.REACT_APP_API_URL?.includes("bidding-api.mygrowers.io"));
const staging = !!(process.env.REACT_APP_API_URL?.includes("staging.growers"));

export const AppConfig = {
  biddingApiUrl: process.env.REACT_APP_API_URL ?? "http://localhost:3010",
  desktopBreakpoint: "sm",
  env: {
    devel,
    local,
    prod,
    staging,
    test: local || devel || staging,
  },
  GTM_ID: ((devel) && 'GTM-KFXCQXG') || ((prod) && 'GTM-TCBDG9J') || ((staging && 'GTM-KGH4C6B')) || '',
  mobileBreakpoint: "xs",
  recaptchaSiteKey: '6Lev_zIpAAAAADSVscaivlhWldBx12N_uAwm3x2e',
  staticImageHost: process.env.REACT_APP_STATIC_ASSETS_HOST?.includes('https')
    ? process.env.REACT_APP_STATIC_ASSETS_HOST
    : `https://${process.env.REACT_APP_STATIC_ASSETS_HOST}` ?? '',
  stripeFees: {
    achPercentFee: 0.008,
    creditCardPercentFee: 0.029,
    creditCardPerTransactionCost: 0.30,
  },
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    ?? 'pk_test_51MC6lxIDeTZTqEJbVhUeLDkxjotw94fgVglGt2JzWAueMCsjXUkigD807FVm0n4WdR86kHJJR1WVIn3HRGLWbUYJ00semUYIZL',
  version: SharedConfig.version,
};
