import React, { useRef, useState } from 'react';
import { Text, Button as Button } from '@/components/DesignSystem';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import ClearIcon from '@mui/icons-material/Clear';
import { useMutation, useQueryClient } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { ToastMessages, errorMessages } from '@/constants/constant';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AppConfig } from '@/constants/AppConfig';
import { ApiManufacturer } from '@api/interfaces';
import ConfirmDialog from './ConfirmDialog';
import { sanitizedFileName } from '@shared/utilities';

const UploadImage = ({
  id,
  manufacturerById,
}: {
  id: string;
  manufacturerById: ApiManufacturer;
}) => {
  const [error, setError] = useState('');
  const [isRemoveLogo, setIsRemoveLogo] = useState(false);
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    const imageFile = event.target.files?.[0];

    if (imageFile) {
      if (imageFile.size > 1024 * 1024) {
        setError(errorMessages.imageTooLarge);
      }
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;

        image.onload = () => {
          if (imageFile.type !== 'image/png') {
            setError(errorMessages.invalidImageType);
          }
          if (
            image.width !== 220 ||
            image.height !== 220 ||
            image.width !== image.height ||
            imageFile.type !== 'image/png'
          ) {
            setError(errorMessages.invalidImageSize);
          } else {
            setError('');
            uploadLogo({ name: imageFile.name?.replace(/\s/g, ''), base64: reader.result });
          }
        };
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const getFileName = (nameString: string) => {
    const lastIndex = nameString.lastIndexOf('-');
    return nameString.substring(lastIndex + 1);
  };

  const RenderImg = () => {
    return (
      <Box>
        <img
          alt={getFileName(manufacturerById?.logo ?? '')}
          height={220}
          src={`${AppConfig.staticImageHost}/${manufacturerById?.logo}`}
          style={{ objectFit: 'cover' }}
          width={220}
        />
      </Box>
    );
  };

  const { mutate: uploadLogo, isLoading } = useMutation(
    ({ name, base64 }: { name: string; base64: string | ArrayBuffer | null }) =>
      ManufacturerApi.update(id, {
        logo: `${sanitizedFileName(name)}+${base64}`,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURERS);
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURER_BY_ID);
        openSnackbar(ToastMessages.adminRetailerUploadLogoSuccess);
      },
      onError: () => {
        openSnackbar(ToastMessages.adminRetailerUploadLogoFailure);
      },
    },
  );

  const { mutate: deleteLogo, isLoading: isRemoveLogoLoading } = useMutation(
    () => ManufacturerApi.update(id, { logo: manufacturerById?.logo ?? '' }),
    {
      onSuccess: async () => {
        openSnackbar('Logo removed successfully');
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURERS);
        await queryClient.invalidateQueries(QueryKeys.GET_MANUFACTURER_BY_ID);
        setIsRemoveLogo(false);
      },
    },
  );

  return (
    <Stack
      alignItems='center'
      direction='row'
      justifyContent='space-between'
      sx={{ marginTop: '25px', marginBottom: '25px' }}
    >
      <Stack spacing={1}>
        <Text category='h6'>Logo</Text>
        {error?.length ? (
          <Typography color='error'>{error}</Typography>
        ) : (
          <Typography>
            File type: PNG Ratio: 1:1 Size: 220 x 220 pixels Max file size: 1MB
          </Typography>
        )}
      </Stack>
      <Box>
        {!manufacturerById?.logo ? (
          <Button
            loading={isLoading}
            onClick={() => (inputRef.current as HTMLInputElement)?.click()}
            testID='upload-logo-button'
            variant='outlined'
          >
            <UploadIcon /> Upload Logo{' '}
            <input
              accept='image/png'
              id='raised-button-file'
              onChange={(event) => handleImageChange(event)}
              ref={inputRef}
              style={{ display: 'none' }}
              type='file'
            />
          </Button>
        ) : (
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
          >
            <Typography sx={{ marginRight: '10px' }}>
              <Tooltip title={<RenderImg />}>
                <IconButton sx={{ background: 'transparent' }}>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
              {getFileName(manufacturerById?.logo ?? '')}
            </Typography>
            <Button
              onClick={() => {
                setIsRemoveLogo(true);
              }}
              startIcon={<ClearIcon />}
              testID='delete-logo'
              variant='text'
            >
              Remove
            </Button>
          </Stack>
        )}
        {isRemoveLogo && (
          <ConfirmDialog
            cancelButtonText='No'
            confirmButtonText='Yes'
            isLoading={isRemoveLogoLoading}
            isOpen={isRemoveLogo}
            message = 'Are you sure you want to delete the logo?'
            onClose={() => setIsRemoveLogo(false)}
            onConfirm={() => deleteLogo()}
            title= 'Delete Logo?'
          />
        )}
      </Box>
    </Stack>
  );
};

export default UploadImage;
