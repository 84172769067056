export enum State {
  Alabama = 'Alabama',
  Alaska = 'Alaska',
  Arizona = 'Arizona',
  Arkansas = 'Arkansas',
  California = 'California',
  Colorado = 'Colorado',
  Connecticut = 'Connecticut',
  Delaware = 'Delaware',
  Florida = 'Florida',
  Georgia = 'Georgia',
  Hawaii = 'Hawaii',
  Idaho = 'Idaho',
  Illinois = 'Illinois',
  Indiana = 'Indiana',
  Iowa = 'Iowa',
  Kansas = 'Kansas',
  Kentucky = 'Kentucky',
  Louisiana = 'Louisiana',
  Maine = 'Maine',
  Maryland = 'Maryland',
  Massachusetts = 'Massachusetts',
  Michigan = 'Michigan',
  Minnesota = 'Minnesota',
  Mississippi = 'Mississippi',
  Missouri = 'Missouri',
  Montana = 'Montana',
  Nebraska = 'Nebraska',
  Nevada = 'Nevada',
  NewHampshire = 'New Hampshire',
  NewJersey = 'New Jersey',
  NewMexico = 'New Mexico',
  NewYork = 'New York',
  NorthCarolina = 'North Carolina',
  NorthDakota = 'North Dakota',
  Ohio = 'Ohio',
  Oklahoma = 'Oklahoma',
  Oregon = 'Oregon',
  Pennsylvania = 'Pennsylvania',
  RhodeIsland = 'Rhode Island',
  SouthCarolina = 'South Carolina',
  SouthDakota = 'South Dakota',
  Tennessee = 'Tennessee',
  Texas = 'Texas',
  Utah = 'Utah',
  Vermont = 'Vermont',
  Virginia = 'Virginia',
  Washington = 'Washington',
  WestVirginia = 'West Virginia',
  Wisconsin = 'Wisconsin',
  Wyoming = 'Wyoming',
}

type StateAbbreviationsType = {
  [key in State]: string;
};

export const StateAbbreviations: StateAbbreviationsType = {
  [State.Alabama]: 'AL',
  [State.Alaska]: 'AK',
  [State.Arizona]: 'AZ',
  [State.Arkansas]: 'AR',
  [State.California]: 'CA',
  [State.Colorado]: 'CO',
  [State.Connecticut]: 'CT',
  [State.Delaware]: 'DE',
  [State.Florida]: 'FL',
  [State.Georgia]: 'GA',
  [State.Hawaii]: 'HI',
  [State.Idaho]: 'ID',
  [State.Illinois]: 'IL',
  [State.Indiana]: 'IN',
  [State.Iowa]: 'IA',
  [State.Kansas]: 'KS',
  [State.Kentucky]: 'KY',
  [State.Louisiana]: 'LA',
  [State.Maine]: 'ME',
  [State.Maryland]: 'MD',
  [State.Massachusetts]: 'MA',
  [State.Michigan]: 'MI',
  [State.Minnesota]: 'MN',
  [State.Mississippi]: 'MS',
  [State.Missouri]: 'MO',
  [State.Montana]: 'MT',
  [State.Nebraska]: 'NE',
  [State.Nevada]: 'NV',
  [State.NewHampshire]: 'NH',
  [State.NewJersey]: 'NJ',
  [State.NewMexico]: 'NM',
  [State.NewYork]: 'NY',
  [State.NorthCarolina]: 'NC',
  [State.NorthDakota]: 'ND',
  [State.Ohio]: 'OH',
  [State.Oklahoma]: 'OK',
  [State.Oregon]: 'OR',
  [State.Pennsylvania]: 'PA',
  [State.RhodeIsland]: 'RI',
  [State.SouthCarolina]: 'SC',
  [State.SouthDakota]: 'SD',
  [State.Tennessee]: 'TN',
  [State.Texas]: 'TX',
  [State.Utah]: 'UT',
  [State.Vermont]: 'VT',
  [State.Virginia]: 'VA',
  [State.Washington]: 'WA',
  [State.WestVirginia]: 'WV',
  [State.Wisconsin]: 'WI',
  [State.Wyoming]: 'WY',
};
