import { PaymentStatus } from '@shared/enums';

export const FriendlyPaymentStatus = {
  [PaymentStatus.FAILED]: 'Payment Failed',
  [PaymentStatus.PAID]: 'Paid',
  [PaymentStatus.PROCESSING]: 'Processing',
  [PaymentStatus.REFUNDED]: 'Refunded',
  [PaymentStatus.REQUIRES_ACTION]: 'Action Required',
  [PaymentStatus.UNPAID]: 'Payment Due',
};
