export const DayNames = ["S", "M", "T", "W", "T", "F", "S"];
export const MonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];