import stripeBadgeForDarkTheme from '@/assets/stripe-badge.svg';
import { useTheme } from '@mui/material';
import stripeBadgeForLightTheme from '../../assets/images/stripe-badge.blue.png';
import React from 'react';

export const StripeBadge = ()  => {
  const theme = useTheme();
  return (
    <img
      alt="Powered by Stripe"
      src={theme.palette.mode === 'dark' ? stripeBadgeForDarkTheme : stripeBadgeForLightTheme}
    />
  );
};
