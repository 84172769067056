export enum DryFormulationUnitOfMeasure {
  POUND = 'LB',
  TON = 'TON',
  OUNCE = 'OZ',
}

export enum LiquidFormulationUnitOfMeasure {
  FLUID_OUNCE = 'FL OZ',
  PINT = 'PT',
  QUART = 'QT',
  GALLON = 'GAL',
  TOTE = 'TOTE',
}

export enum SeedApplicationUnitOfMeasure {
  MSDS = 'MSDS',
  KSDS = 'KSDS',
  LBS = 'LBS',
}

export enum SeedProductUnitOfMeasure {
  POUND = 'LB',
  BAG = 'BAG',
  BUSHEL = 'BU',
}

export enum ServiceUnitOfMeasure {
  ACRE = 'AC',
}

export const ProductUomEnums = [
  DryFormulationUnitOfMeasure,
  LiquidFormulationUnitOfMeasure,
  SeedProductUnitOfMeasure,
  ServiceUnitOfMeasure,
];

export type ApplicationUom =
  SeedApplicationUnitOfMeasure
  | LiquidFormulationUnitOfMeasure
  | DryFormulationUnitOfMeasure;

export type ProductUom =
  SeedProductUnitOfMeasure
  | LiquidFormulationUnitOfMeasure
  | DryFormulationUnitOfMeasure
  | ServiceUnitOfMeasure;

export enum UomGroup {
  DRY = 'DRY',
  LIQUID = 'LIQUID',
  OTHER = 'OTHER',
}
