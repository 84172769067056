import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ReactNode } from 'react';
import { Stack, SvgIconProps } from '@mui/material';
import { Text } from '..';
import { useMediaQuery } from '@/hooks/useMediaQuery';

export interface NavigationDrawerProps {
  collapsed: boolean,
  children: ReactNode,
  isTransitioning: boolean,
  testID: string,
}

export interface NavigationDrawerItemProps {
  collapsed?: boolean,
  icon?: (props: SvgIconProps) => ReactNode,
  isTransitioning: boolean,
  onClick?: () => void,
  secondaryText?: string,
  selected: boolean,
  testID: string,
  text: string,
}

export const NavigationDrawerItem = ({
  collapsed = false,
  icon,
  isTransitioning = false,
  onClick,
  secondaryText,
  selected,
  testID,
  text,
}: NavigationDrawerItemProps) => (
  <ListItem
    data-testid={testID}
    disablePadding
    key={text}
    secondaryAction={!collapsed && !isTransitioning ? secondaryText : undefined}
    sx={{
      '.MuiListItemSecondaryAction-root': {
        color: selected ? 'primary' : undefined,
        paddingRight: "24px",
      },
    }}>
    <ListItemButton
      data-testid={`${testID}-item-button`}
      onClick={onClick}
      selected={selected}
      sx={{
        borderRadius: "100px",
        height: "56px",
        width: collapsed ? "56px" : undefined,
        marginLeft: !icon && collapsed ? "0px" : "12px",
        marginRight: !icon && collapsed ? "0px" : "12px",
      }}
     >
      {
        !!icon && (
          <ListItemIcon>
            {icon({})}
          </ListItemIcon>
        )
      }
      {
        !collapsed && (
          <ListItemText
            data-testid={`${testID}-item-text`}
            primary={text}
            sx={!collapsed && !icon ? { paddingLeft: "25px" } : {}} />
        )
      }
      {
        !icon && collapsed && (
          <Text
            category="label-medium"
            sx={{ paddingLeft: "2px" }}
          >
            {text}
          </Text>
        )
      }
    </ListItemButton>
  </ListItem>
);

export const NavigationDrawer = ({
  collapsed,
  children,
  testID,
}: NavigationDrawerProps) => {

  const { isMobile } = useMediaQuery();

  return (
    <>
      {
        (isMobile && collapsed) ? (
          <Stack data-testid={testID} direction="row" sx={{ marginTop: "12px" }}>
            {children}
          </Stack>
        ) : (
          <List data-testid={testID}>
            {children}
          </List>
        )
      }
    </>
  );
};
