import { Logo, Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { Box, Stack, useTheme } from "@mui/material";
import { GrowersContactInfo } from '@shared/enums';
import { useState } from 'react';
import { SubmitFeedbackModal } from './SubmitFeedbackModal';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { getSubdomain } from '@/utilities/ThemeUtilities';

export interface ContactFooterProps {
  fixedToBottom?: boolean,
  prefix?: string,
}

export const ContactFooter = ({
  fixedToBottom = false,
  prefix = "Need help?",
}: ContactFooterProps) => {
  const theme = useTheme();
  const [showSubmitFeedbackModal, setShowSubmitFeedbackModal] = useState(false);

  const styles: SXStyles = {
    container: {
      position: fixedToBottom ? "fixed" : undefined,
      textAlign: "center",
      width: "100%",
      bottom: fixedToBottom ? 48 : undefined,
      backgroundColor: theme.palette.background.default,
    },
    landusContainer: {
      textAlign: 'center',
      width: "100%",
      backgroundColor: theme.palette.background.default,
    },
  } as const;

  const subdomain = getSubdomain();
  const landusAddress = '220 SW 9th St., Ste. 300., Des Moines, IA 50309-4320';
  const copyright = `Copyright © ${new Date().getFullYear()} GROWERS. All Rights Reserved.`;

  if (subdomain === 'landus') {
    return (
      <Box sx={styles.landusContainer}>
        <VSpacer size="10" />
        <Logo category="landus-powered-by" size="large" />
        <VSpacer size="7" />
        <Text category="label-small">
          {landusAddress}
        </Text>
        <VSpacer size="7" />
        <Text category="label-small">
          {copyright}
        </Text>
        <VSpacer size="2" />
        <Stack direction="row" justifyContent="center">
        <TextLink
            category="label-small"
            href="https://growers.ag/privacy-policy/"
            rel="noopener noreferrer"
            target="_blank"
            testID="privacy-policy"
          >
            Privacy Policy
          </TextLink>
          <Text category="label-small">&nbsp;|&nbsp;</Text>
          <TextLink
            category="label-small"
            href="https://growers.ag/privacy-policy/#termsofuse"
            rel="noopener noreferrer"
            target="_blank"
            testID="terms-of-use"
          >
            Terms of use
          </TextLink>
          <Text category="label-small">&nbsp;|&nbsp;</Text>
          <TextLink
            category="label-small"
            onClick={() => setShowSubmitFeedbackModal(true)}
            testID="contact-footer-support-phone-link"
          >
            Need help?
          </TextLink>
        </Stack>
        <VSpacer size="10" />
        <SubmitFeedbackModal
          onClose={() => setShowSubmitFeedbackModal(false)}
          open={showSubmitFeedbackModal}
        />
      </Box>
    );
  }

  return (
    <>
      <Box sx={styles.container}>
        <Text
          category="p2"
          testID="contact-footer"
        >
          {!!prefix && prefix !== "" && (
            <>
              {prefix}
              &nbsp;
            </>
          )}
          <TextLink
            category="p2"
            href={`mailto:${GrowersContactInfo.supportEmail}`}
            testID="contact-footer-support-email-link"
          >
            Email GROWERS
          </TextLink>
          &nbsp;
          |
          &nbsp;
          <TextLink
            category="p2"
            href={`tel:${GrowersContactInfo.supportPhone}`}
            testID="contact-footer-support-phone-link"
          >
            Call GROWERS
          </TextLink>
          &nbsp;
          |
          &nbsp;
          <TextLink
            category="p2"
            onClick={() => setShowSubmitFeedbackModal(true)}
            testID="contact-footer-support-phone-link"
          >
            Submit feedback
          </TextLink>
        </Text>
      </Box>
      <SubmitFeedbackModal
        onClose={() => setShowSubmitFeedbackModal(false)}
        open={showSubmitFeedbackModal}
      />
    </>
  );
};
