import { TypographyVariantsOptions } from '@mui/material/styles';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    caption1: true,
    caption2: true,
  }
}

interface CustomTypographyOptions extends TypographyVariantsOptions {
  caption1: React.CSSProperties,
  caption2: React.CSSProperties,
}

export const defaultTypography = {
  button: {
    fontWeight: 500,
    fontSize: 14,
    textTransform: 'none',
  },
  h1: {
    fontSize: 48,
    fontWeight: 400,
  },
  h2: {
    fontSize: 34,
    fontWeight: 400,
  },
  h3: {
    fontSize: 26,
    fontWeight: 400,
  },
  h4: {
    fontSize: 24,
    fontWeight: 500,
  },
  h5: {
    fontSize: 24,
    fontWeight: 400,
  },
  h6: {
    fontSize: 20,
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 500,
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.45,
    lineHeight: 1.45,
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
    lineHeight: 1.45,
  },
  caption1: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.4,
    lineHeight: 1.5,
  },
  caption2: {
    fontSize: 12,
    fontWeight: 500,
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
  },
  overline: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.4,
    lineHeight: 1.5,
  },
} as CustomTypographyOptions;


//Figma documentation:
// https://www.figma.com/file/zeXEzk0N6iFD46hJ3CNKtK/Material-Component-Library?node-id=53315%3A44805&t=vsTIzPO6e1S8Pjq4-0
export const Typography = {
  'display-large': {
    fontSize: "57px",
    fontWeight: 400,
    lineHeight: "64px",
    letterSpacing: "0.1px",
  },
  'display-medium': {
    fontSize: "45px",
    fontWeight: 400,
    lineHeight: "52px",
    letterSpacing: "0.1px",
  },
  'display-small': {
    fontSize: "36px",
    fontWeight: 400,
    lineHeight: "44px",
    letterSpacing: "0.1px",
  },
  'title-small': {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.1px",
  },
  'title-medium': {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  'title-large': {
    fontSize: "22px",
    fontWeight: 400,
    lineHeight: "28px",
    letterSpacing: "0.1px",
  },
  'body-small': {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0.4px",
  },
  'body-medium': {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.25px",
  },
  'body-large': {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.5px",
  },
  'body-xlarge': {
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    letterSpacing: "0.5px",
  },
  'headline-small': {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32px",
    letterSpacing: "0.1px",
  },
  'headline-medium': {
    fontSize: "28px",
    fontWeight: 400,
    lineHeight: "36px",
    letterSpacing: "0.1px",
  },
  'headline-large': {
    fontSize: "32px",
    fontWeight: 400,
    lineHeight: "40px",
    letterSpacing: "0.1px",
  },
  'label-large': {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "1px",
  },
  'label-medium': {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.5px",
  },
  'label-small': {
    fontSize: "11px",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.5px",
  },
  'overline': {
    fontSize: "11px",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "1.25px",
  },

  // Legacy categories
  'h1': {
    fontSize: "48px",
    fontWeight: 400,
    lineHeight: "48px",
    letterSpacing: "0px",
  },
  'h2': {
    fontSize: "34px",
    fontWeight: 400,
    lineHeight: "34px",
    letterSpacing: "0px",
  },
  'h3': {
    fontSize: "26px",
    fontWeight: 400,
    lineHeight: "30px",
    letterSpacing: "0.15px",
  },
  'h4': {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  'h5': {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0px",
  },
  'h6': {
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.15px",
  },
  'p1': {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0.1px",
  },
  's1': {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0.2px",
  },
  'p2': {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20.3px",
    letterSpacing: "0.2px",
  },
  's2': {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0.5px",
  },
  'c2': {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0.4px",
  },
  'c1': {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0.4px",
  },
  'tiny': {
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "13.5px",
    letterSpacing: "0.5px",
  },
  'label': {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "14px",
    letterSpacing: "1px",
  },
} as const;
