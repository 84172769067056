export enum PackageType {
  Bag = 'Bag',
  Barrel = 'Barrel',
  Bottle = 'Bottle',
  Box = 'Box',
  Bulk = 'Bulk',
  BulkBag = 'Bulk Bag',
  Bucket = 'Bucket',
  Case = 'Case',
  Drum = 'Drum',
  MiniBulk = 'Mini Bulk',
  Package = 'Package',
  Pallet = 'Pallet',
  ProBox = 'Pro Box',
  Tote = 'Tote',
  Unit = 'Unit',
  Jug = 'Jug',
}
