import { ModalBottomSheet } from '@/components/shared/ModalBottomSheet/ModalBottomSheet';
import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { ApiPricingRequest } from '@api/interfaces';
import { PriceRequestDetails } from './PriceRequestDetails';

interface PriceRequestDetailsModalProps {
  onClose: () => void,
  open: boolean,
  priceRequest: ApiPricingRequest,
}

export const PriceRequestDetailsModal = ({
  onClose,
  open,
  priceRequest,
}: PriceRequestDetailsModalProps) => {
  return (
    <ModalBottomSheet
      containerSpacing={1}
      containerStyle={{
        px: getResponsiveValue(2, 0),
      }}
      onClose={onClose}
      open={open}
      testID="pricerequest-details-modal"
      title="Product Request Details"
      width={395}
    >
      <PriceRequestDetails priceRequest={priceRequest} />
    </ModalBottomSheet>
  );
};
