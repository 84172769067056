import { Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { StripeBadge } from '@/components/shared/StripeBadge';
import { Totals } from '@/pages/ReviewSelectOffers/ReviewSelectOffers';
import { PaymentApi } from '@/utilities/api/PaymentApi';
import { ApiPayment } from '@api/interfaces';
import { Divider, Stack } from '@mui/material';
import { AllowedPaymentMethodType, GrowersContactInfo, PaymentStatus } from '@shared/enums';
import { formatCurrency, formatPhoneNumber } from '@shared/utilities/StringUtils';
import {
  AddressElement,
  LinkAuthenticationElement,
  PaymentElement,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  PaymentIntent,
  StripeAddressElementOptions,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';

interface PaymentFormProps {
  onAddressUpdate?: (isComplete: boolean) => void,
  onEmailUpdate: (email: string) => void,
  onPaymentUpdate?: (isComplete: boolean) => void,
  onLoaded?: () => void,
  payment?: ApiPayment,
  paymentType?: AllowedPaymentMethodType,
  totals?: Totals,
}

const PaymentForm = ({
  onAddressUpdate,
  onEmailUpdate,
  onPaymentUpdate,
  onLoaded,
  payment,
  paymentType,
  totals,
}: PaymentFormProps) => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get('token') ?? undefined;
  const [paymentMethodOrder] = useState(
    paymentType === AllowedPaymentMethodType.ACH
      ? [AllowedPaymentMethodType.ACH, AllowedPaymentMethodType.CreditCard]
      : [AllowedPaymentMethodType.CreditCard, AllowedPaymentMethodType.ACH],
  );

  useEffect(() => {
    if (!stripe || !payment?.clientSecret) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    stripe.retrievePaymentIntent(payment.clientSecret).then(async ({ paymentIntent }) => {
      if (!paymentIntent) {
        return;
      }
      if (requiresSync(paymentIntent)) {
        await PaymentApi.syncRetailerPayment(payment.id, inviteToken);
        refreshPage();
      }
    });
  }, [stripe]);

  const refreshPage = () => {
    navigate(0);
  };

  const requiresSync = (paymentIntent: PaymentIntent) => {
    const { status: intentStatus } = paymentIntent;
    return intentStatus === 'succeeded' && payment?.status !== PaymentStatus.PAID
      || intentStatus === 'processing' && payment?.status !== PaymentStatus.PROCESSING
      || intentStatus === 'requires_action' && payment?.status !== PaymentStatus.REQUIRES_ACTION;
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
    fields: {
      billingDetails: {
        email: paymentType === AllowedPaymentMethodType.ACH ? 'never' : undefined,
        name: 'never',
      },
    },
    paymentMethodOrder,
  };

  const addressElementOptions: StripeAddressElementOptions = {
    allowedCountries: ['US'],
    mode: 'billing',
    fields: {
      phone: 'always',
    },
    validation: {
      phone: {
        required: 'always',
      },
    },
  };

  const lineItems = totals ? [
    {
      cost: totals.subtotal,
      name: 'SubTotal',
    },
    {
      cost: totals.shippingCost,
      name: 'Shipping',
    },
    {
      cost: totals.stripeFee,
      name: 'Convenience fee',
    },
  ] : [];

  const TotalsElement = () => {
    if (!totals) {
      return null;
    }
    const totalToChargeCents = Math.round(
      (totals.subtotal + totals.shippingCost + totals.stripeFee) * 100,
    );
    return (
      <>
        <Divider />
        <VSpacer size="5" />
        {lineItems.map((total) => {
          if (total.cost === 0) {
            return null;
          }
          return (
            <Fragment key={total.name}>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                <Text category="body-large">
                  {total.name}
                </Text>
                <Text
                  category="body-large"
                >
                  {formatCurrency(total.cost)}
                </Text>
              </Stack>
              {total.name !== "Convenience fee" && <VSpacer size="2" />}
            </Fragment>
          );
        })}
        <VSpacer size="5" />
        <Divider />
        <VSpacer size="5" />
        <Stack direction="row" justifyContent="space-between">
          <Text category="title-medium">Total</Text>
          <Text category="body-large">{formatCurrency(totalToChargeCents / 100)}</Text>
        </Stack>
        <VSpacer size="5" />
        <Divider />
      </>
    );
  };

  return (
    <>
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => onEmailUpdate(e.value.email)}
      />
      <VSpacer size="8" />
      <TotalsElement />
      <VSpacer size="8" />
      <Text category="headline-small">Payment method</Text>
      <VSpacer size="8" />
      <PaymentElement
        id="payment-element"
        onChange={(e) => onPaymentUpdate?.(e.complete)}
        onReady={onLoaded}
        options={paymentElementOptions}
      />
      <VSpacer size="8" />
      <Text category="headline-small">Billing address</Text>
      <VSpacer size="8" />
      <AddressElement
        onChange={(e) => onAddressUpdate?.(e.complete)}
        options={addressElementOptions}
      />
      <Stack alignItems="center" textAlign="center">
        <MobileOnly>
          <VSpacer size="8" />
          <StripeBadge />
        </MobileOnly>
        <VSpacer size="11" />
        <TextLink
          category="label-small"
          href={GrowersContactInfo.termsOfUseLink}
          onClick={() => window.open(GrowersContactInfo.termsOfUseLink)}
          testID="payment-form-term-of-use-link"
        >
          GROWERS Terms of Use
        </TextLink>
        <VSpacer size="8" />
        <Stack flexDirection="row" flexWrap="wrap" justifyContent="center">
          <Text>Need help? Call&nbsp;</Text>
          <DesktopOnly>
            <Text color="primary">
              {formatPhoneNumber(GrowersContactInfo.supportPhone)}&nbsp;
            </Text>
          </DesktopOnly>
          <MobileOnly>
            <TextLink
              href={`tel:${GrowersContactInfo.supportPhone}`}
              testID="payment-form-mobile-support-phone-link"
            >
              {formatPhoneNumber(GrowersContactInfo.supportPhone)}&nbsp;
            </TextLink>
          </MobileOnly>
          <Text>or email us&nbsp;</Text>
          <DesktopOnly>
            <Text color="primary">{GrowersContactInfo.supportEmail}</Text>
          </DesktopOnly>
          <MobileOnly>
            <TextLink
              href={`mailto:${GrowersContactInfo.supportEmail}`}
              testID="payment-form-mobile-support-email-link"
            >
              {GrowersContactInfo.supportEmail}
            </TextLink>
          </MobileOnly>
        </Stack>
      </Stack>
      <VSpacer size="9" />
    </>
  );
};

export default PaymentForm;
