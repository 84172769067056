import { Client } from '@/utilities/api/Client';
import { QueryUtil } from '../Query';
import { PromotionEndpoint } from '@api/endpoints/PromotionEndpoint';

export class PromotionApi {
  static create (
    body: PromotionEndpoint.Create.Request,
  ): Promise<PromotionEndpoint.Create.Response> {
    return Client(`promotions`, {
      method: 'POST',
      body,
    });
  }

  static list (
    query: PromotionEndpoint.List.Query,
  ): Promise<PromotionEndpoint.List.Response> {
    const queryString = QueryUtil.stringify(query);
    return Client(`promotions?${queryString}`);
  }

  static get (id: string): Promise<PromotionEndpoint.Get.Response> {
    return Client(`promotions/${id}`);
  }

  static update (
    id: string,
    body: PromotionEndpoint.Update.Request,
  ): Promise<PromotionEndpoint.Update.Response> {
    return Client(`promotions/${id}`, {
      method: 'PATCH',
      body,
    });
  }

  static delete (id: string): Promise<void> {
    return Client(`promotions/${id}`, { method: 'DELETE' });
  }
}
