import { AppConfig } from '@/constants/AppConfig';
import { getUserDisplayName } from '@/utilities/Farmer';
import { ApiUser } from '@api/interfaces';
import { UserType } from '@shared/enums';
import { userIsRetailer } from '@shared/utilities';

type AccountList = {
  [id: string]: {
    name: string,
    storefrontId: string | null,
    subdomain: string | null,
    token: string,
    type: UserType,
  },
}

type Account = {
  id: string,
  storefrontId: string | null,
  subdomain: string | null,
  name: string,
  type: UserType,
}

const key = "Bidding_Accounts";

function read () {
  return JSON.parse(localStorage.getItem(key) ?? '{}') as AccountList;
}

function write (list: AccountList) {
  localStorage.setItem(key, JSON.stringify(list));
}

/**
 * Used in concert with UserSwitcher to store account tokens in Local Storage.
 */
export class LocalAccountList {
  static clear () {
    write({});
  }

  static get (id: string) {
    return read()[id];
  }

  static getToken (id: string): string {
    return read()[id].token;
  }

  static isActive (): boolean {
    if (AppConfig.env.prod) { return false; }
    const accounts = Object.values(read());
    return accounts.some((account) => account.type === UserType.Internal);
  }

  static list ()  {
    const list = read();
    const accounts: Account[] = [];
    Object.keys(list).forEach((id) => {
      if ('storefrontId' in list[id]) {
        accounts.push({
          id,
          storefrontId: list[id].storefrontId,
          name: list[id].name,
          subdomain: list[id].subdomain,
          type: list[id].type,
        });
      } else {
        this.remove(id);
      }
    });
    return accounts;
  }

  static remove (id: string) {
    const list = read();
    delete list[id];
    write(list);
  }

  static set (user: ApiUser, token: string, subdomain: string | null) {
    if (user.userType !== UserType.Internal && !this.isActive()) {
      return;
    }
    const userName = getUserDisplayName(user);
    const name = userIsRetailer(user)
      ? user.businessName!
      : userName;

    const list = read();
    list[user.id] = {
      name,
      storefrontId: user.storefrontId,
      subdomain,
      token,
      type: user.userType,
    };
    write(list);
  }
}
