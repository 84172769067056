import { Button, Card, HSpacer, Text, VSpacer } from "@/components/DesignSystem";
import { FriendlyOfferStatus } from "@/constants/FriendlyOfferStatus";
import { FriendlyPaymentType } from "@/constants/FriendlyPaymentType";
import { FriendlyUoM } from "@/constants/FriendlyUoM";
import ContactInformation from '@/pages/ReceivePlaceOffers/OfferOverview/ContactInformation';
import { PaymentDetails } from '@/pages/ReceivePlaceOffers/OfferOverview/PaymentDetails';
import { ApiPayment, ApiPricingRequestPublic } from "@api/interfaces";
import StickyNote2Outlined from "@mui/icons-material/StickyNote2Outlined";
import { Divider, Stack } from "@mui/material";
import { OfferStatus } from "@shared/enums";
import { localizeNumber } from '@shared/utilities';
import { DateTime } from 'luxon';
import { Fragment } from "react";
import { LastLookData, Offer, Product } from "../interfaces";
import { LastLookSameOrganizationBanner } from "./LastLookSameOrganizationBanner";
import OfferCostSummary from "./OfferCostSummary";
import OfferFarmerNote from "./OfferFarmerNote";
import OfferFulfillmentMethod from "./OfferFulfillmentMethod";
import OfferHeader from "./OfferHeader";
import OfferLicenses from "./OfferLicenses";
import OfferNote from "./OfferNote";
import ProductCard from "./ProductCard";

interface OfferOverviewMobileProps {
  expired?: boolean,
  lastLookData?: LastLookData,
  pricingRequest: ApiPricingRequestPublic,
  offer: Offer,
  onChangeOffer: (offer: Offer) => void,
  onEditNote: () => void,
  payment?: ApiPayment | null,
  readonly?: boolean,
  reviewMode?: boolean,
}

export const OfferOverviewMobile = ({
  expired = false,
  lastLookData,
  pricingRequest,
  offer,
  onChangeOffer,
  onEditNote,
  payment,
  readonly = false,
  reviewMode = false,
}: OfferOverviewMobileProps) => {
  const offerStatus = pricingRequest.offer?.status;
  const isAcceptedOffer = offerStatus === OfferStatus.Accepted
    || offerStatus === OfferStatus.Partial;
  const hasPlacedOffer = !!pricingRequest.offer;

  const getPageTitle = () => {
    if (readonly) {
      if (expired && !hasPlacedOffer) {
        return `Product Request #${pricingRequest.publicId}`;
      } else {
        return `Offer ${offer.publicId}`;
      }
    } else {
      if (reviewMode) {
        return 'Review and Submit Offer';
      } else {
        return 'Create Offer';
      }
    }
  };

  return (
    <Stack>
      <VSpacer size="8" />
      <Stack alignItems="center">
        <Text category="h3">
          {getPageTitle()}
        </Text>
        <VSpacer size="3" />
        <Text category="p2">
          {!readonly
            ? `Expires ${DateTime.fromJSDate(pricingRequest!.expiration!).toFormat("ccc LLL dd hh:mm a")}`
            : (offerStatus ? FriendlyOfferStatus[offerStatus] : "")
          }
        </Text>
        <LastLookSameOrganizationBanner
          bottomSpacing="1"
          lastLookData={lastLookData}
          topSpacing="8"
        />
        {(readonly && hasPlacedOffer) && (
          <>
            <VSpacer size="2" />
            <Text category="p2">
              This offer has already been placed and cannot be changed
            </Text>
            <VSpacer size="2" />
            <Text category="p2">
              {`Financing: ${FriendlyPaymentType[pricingRequest.paymentType!]}`}
            </Text>
          </>
        )}
        {(expired && !hasPlacedOffer) && (
          <>
            <VSpacer size="2" />
            <Text category="p2">
              This product request has expired and no new offers can be placed
            </Text>
          </>
        )}
      </Stack>
      <VSpacer size="9" />
      {(!!pricingRequest.user && isAcceptedOffer) && (
        <>
          <ContactInformation user={pricingRequest.user}/>
          <VSpacer size="7" />
        </>
      )}
      {(!!pricingRequest.user && !isAcceptedOffer) && (
        <>
          <OfferHeader pricingRequest={pricingRequest} user={pricingRequest.user} />
          <VSpacer size="5" />
        </>
      )}
      {(!!payment && !!offer.publicId && !!payment.feePercent) && (
        <>
          <PaymentDetails offerPublicId={offer.publicId} payment={payment} />
          <VSpacer size="10" />
        </>
      )}
      {readonly && (
        <Card testID="offer-overview-mobile-card">
          {
            offer.products?.map((product, index) => (
              <Fragment key={product.pricingRequestProductId}>
                <Stack>
                  <Text category="p1">{product.name}</Text>
                  <Stack direction="row">
                    <Text category="p1">Qty:</Text>
                    <HSpacer size="2" />
                    <Text category="p1">
                      {product.quantity && product.uom && (
                        `${localizeNumber(product.quantity, 2, true)} ${FriendlyUoM[product.uom]}`
                      )}
                    </Text>
                  </Stack>
                  {(index < (offer.products?.length || 0) - 1) && (
                    <>
                      <VSpacer size="3" />
                      <Divider light />
                      <VSpacer size="3" />
                    </>
                  )}
                </Stack>
              </Fragment>
            ))
          }
        </Card>
      )}
      <VSpacer size="7" />
      {(!readonly && (offer.products?.length ?? 0) > 0) && (
        <>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Text category="c2">PRODUCTS</Text>
            {
              !readonly && (
                <Button
                  onClick={onEditNote}
                  startIcon={<StickyNote2Outlined />}
                  testID="offer-view-mobile-edit-button"
                  variant="text"
                >
                  { !offer.note ? "Add note" : "Edit note" }
                </Button>
              )
            }
          </Stack>
          <VSpacer size="9" />
        </>
      )}
      {!readonly && (
        <>
          { offer.products?.map((product, index) => (
            <Fragment key={product.pricingRequestProductId}>
              <ProductCard
                disabled={reviewMode}
                lastLookData={lastLookData}
                onChange={(updatedProduct: Product) => {
                  if (offer.products) {
                    offer.products[index] = updatedProduct;
                  }
                  onChangeOffer(offer);
                }}
                product={product}
                readonly={readonly} />
              { (index < (offer.products?.length || 0) - 1) && <VSpacer size="3" /> }
            </Fragment>
          )) }
        </>
      )}
      
      {pricingRequest.note && (
        <>
          <VSpacer size="9" />
          <OfferFarmerNote note={pricingRequest.note} />
        </>
      )}
      {!expired &&
        <>
          <VSpacer size="10" />
          <OfferCostSummary offer={offer} />
          <VSpacer size="10" />
          <OfferFulfillmentMethod
            offerMethod={offer.fulfillmentMethod}
            onEditFulfillmentMethod={(fulfillmentMethod) => {
              onChangeOffer({ ...offer, fulfillmentMethod });
            }}
            pricingRequest={pricingRequest}
            readonly={readonly} />
        </>
      }
      {(pricingRequest.user && isAcceptedOffer) && (
        <>
          <VSpacer size="10" />
          <Stack direction="row" justifyContent="center">
            <Text category="h3">Licenses</Text>
          </Stack>
          <VSpacer size="7" />
          <OfferLicenses user={pricingRequest.user} />
        </>
      )}
      {!!offer.note && (
        <>
          <VSpacer size="10" />
          <OfferNote offer={offer} onEditNote={onEditNote} readonly={readonly} />
        </>
      )}
      <VSpacer size="11" />

    </Stack>
  );
};
