import { DataPoint, Text, TextLink } from '@/components/DesignSystem';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';

interface DetailSectionProps {
  details: string | ReactNode,
  detailsOnClick?: () => void,
  label: string,
  padding?: number,
  testID: string,
}

const DetailSection = ({ 
  details, 
  detailsOnClick, 
  label, 
  padding = 1,
  testID, 
}: DetailSectionProps) => (
  <Stack 
    alignItems="center"
    direction="row"
    justifyContent="start"
    padding={padding}
    sx={{
      wordBreak: 'break-all',
    }}
  >
    <DataPoint 
      containerStyle={{ alignItems: "center" }}
      label={`${label}:`}
      size="large"
      testID={testID}
    >
      { detailsOnClick
        ? <TextLink
            category="body-medium"
            onClick={() => {
              detailsOnClick();
            }}
            sx={{ cursor: 'pointer' }}
            testID="click-on-details"
          >
            {details}
          </TextLink>
        : <Text category="p1">{details}</Text>
      }
    </DataPoint>
  </Stack>
);

export default DetailSection;
