import { Client } from "./Client";

export class FarmerLocationSettingApi {
  static delete (
    userId: string,
    locationId: string,
  ): Promise<void> {
    return Client(`farmer-location-settings`, {
      method: 'DELETE',
      body: { userId, locationId },
    });
  }
}
