import {
  PreferredRetailerCard,
} from '@/components/DesignSystem/PreferredRetailerCard/PreferredRetailerCard';
import { Stack } from '@mui/system';
import { DemoBlock } from '../../components/DemoBlock';
import { Text } from '@/components/DesignSystem';
import { useState } from 'react';
import { Retailer } from '@/pages/CreateSendPriceRequest/interfaces';

const demoRetailers: Retailer = {
  email: 'landus@email.com',
  name: 'Landus Ag',
  telephone: '928-382-1821',
};

export const PreferredRetailerCardDemo = () => {
  const [preferredRetailer, setPreferredRetailer] = useState(demoRetailers);
  const handleDelete = () => {
    setPreferredRetailer({});
  };

  return (
    <Stack>
      <DemoBlock sx={{ width: "343px" }}>
        {Object.values(preferredRetailer).length
          ? (
            <PreferredRetailerCard
              onChange={() => handleDelete}
              preferredRetailer={preferredRetailer}
              testID="preferred-retailer-card-demo"
            />
          )
          : <Text>No Preferred Retailer</Text>}
      </DemoBlock>
    </Stack>
  );
};
