import { Button, HSpacer, Text } from '@/components/DesignSystem';
import { Box, Stack, Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { useState } from 'react';
import ViewCounties from './ViewCounties';
import SelectCounties from './SelectCounties';
import ImportAllowedCountiesModal from '@/pages/PartnerRetailers/ImportAllowedCountiesModal';
import { useGetCounties } from '@/hooks/useRetailerLocations';
import { useParams } from 'react-router-dom';
import { ApiCounty } from '@api/interfaces';
import { UserEndpoint } from '@api/endpoints';
import { stringifyCount } from '@/constants/HierarchyOfRetailers';

interface CountiesProps {
  serveAllCounties: boolean;
}
const CountiesCard = ({ serveAllCounties = false }: CountiesProps) => {
  const { locationId = '' } = useParams<{ locationId: string }>();
  const [isUploadCSV, setIsUploadCSV] = useState(false);
  const [isViewCounties, setIsViewCounties] = useState(false);
  const [isSelectCounties, setIsSelectCounties] = useState(false);

  const { getCounties } = useGetCounties(locationId);

  const countiesByState: UserEndpoint.AllCounties.Response = {};
  getCounties?.forEach((county: ApiCounty) => {
    const state = county.state;
    if (!countiesByState[state]) {
      countiesByState[state] = [];
    }
    countiesByState[state].push(county);
  });

  return (
    <Stack
      alignItems='center'
      direction='row'
      justifyContent='space-between'
      sx={{ marginTop: '25px', marginBottom: '25px' }}
    >
      <Stack spacing={2}>
        <Text category='h6'>Counties</Text>
        {serveAllCounties ? (
          <Typography color='primary'>
            This retailer is serving in all counties
          </Typography>
        ) : (
          <Typography>{stringifyCount(getCounties?.length, 'County', 'Counties')}</Typography>
        )}
      </Stack>
      <Box>
        <Button
          onClick={() => setIsUploadCSV(true)}
          size='medium'
          startIcon={<UploadIcon />}
          testID='upload-csv-btn'
          variant='text'
        >
          Upload CSV
        </Button>
        <HSpacer size='4' />
        <Button
          
          onClick={() => setIsViewCounties(true)}
          size='medium'
          testID='view-counties-btn'
          variant='outlined'
        >
          View Counties
        </Button>
        <HSpacer size='4' />
        <Button
          onClick={() => setIsSelectCounties(true)}
          size='medium'
          testID='select-counties-btn'
          variant='outlined'
        >
          Select Counties
        </Button>
      </Box>
      {isViewCounties && (
        <ViewCounties
          countiesByState={countiesByState}
          isviewCounties={isViewCounties}
          locationCounties={getCounties}
          setIsViewCounties={setIsViewCounties}
        />
      )}
      {isSelectCounties && (
        <SelectCounties
          isSelectCounties={isSelectCounties}
          setIsSelectCounties={setIsSelectCounties}
        />
      )}
      {isUploadCSV && (
        <ImportAllowedCountiesModal
          isVisible={isUploadCSV}
          onClose={() => setIsUploadCSV(false)}
          partnerRetailerId={locationId}
        />
      )}
    </Stack>
  );
};

export default CountiesCard;
