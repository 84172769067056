import {
  Stack,
} from '@mui/material';
import { Text, VSpacer } from '..';
import { ReactNode } from 'react';

export interface EmptyStateProps {
  actionButton?: ReactNode,
  subline?: string,
  testID: string,
  headingMessage: string,
}

export const EmptyState = ({
  actionButton,
  subline,
  testID,
  headingMessage,
}: EmptyStateProps) => {
  return (
    <Stack
      alignItems="center"
      data-testid={testID}
    >
      <Text
        category="title-large"
        testID={`${testID}-heading`}
        textAlign="center"
      >
        {headingMessage}
      </Text>
      {
        !!subline && (
          <>
            <VSpacer size="3" />
            <Text
              category="body-medium"
              testID={`${testID}-subline`}
              textAlign="center"
            >
              {subline}
            </Text>
          </>
        )
      }
      {
        !!actionButton && (
          <>
            <VSpacer size="8" />
            {actionButton}
          </>
        )
      }
    </Stack>
  );
};
