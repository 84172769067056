import { Button, Modal, Text, VSpacer } from '@/components/DesignSystem';
import { ButtonProps } from '@/components/DesignSystem/Button/Button';
import { Routes } from '@/constants/Routes';
import Error from '@mui/icons-material/Error';
import { Stack } from '@mui/material';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface SubmitPaymentButtonProps extends ButtonProps {
  email: string,
  pricingRequestId: string | null,
}

const SubmitPaymentButton = ({
  email,
  pricingRequestId,
  ...rest
}: SubmitPaymentButtonProps) => {
  const elements = useElements();
  const stripe = useStripe();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const inviteToken = searchParams.get('token') ?? undefined;

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    let returnUrl = (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `${window.location.origin}/#${Routes.PLACE_OFFER.replace(':id', pricingRequestId!)}`
    );

    if (inviteToken) {
      returnUrl += `?token=${inviteToken}`;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        receipt_email: email,
        return_url: returnUrl,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if ((error.type === 'card_error' || error.type === 'validation_error') && error.message) {
      if (error.code === 'incomplete_address') {
        setErrorMessage('Billing address is incomplete');
      } else {
        setErrorMessage(error.message);
      }
      setShowErrorModal(true);
    } else if (error.code === 'payment_intent_authentication_failure' && error.message) {
      setErrorMessage(error.message);
      setShowErrorModal(true);
    } else {
      setErrorMessage('Payment unable to process due to an unexpected error. Please try again.');
      setShowErrorModal(true);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Button
        disabled={isLoading || !stripe || !elements || !pricingRequestId}
        onClick={handleSubmit}
        size="giant"
        {...rest}
        testID="submit-payment-button"
      >
        Pay now
      </Button>
      {showErrorModal &&
        <Modal
          acceptButton={(props) => (
            <Button
              onClick={() => setShowErrorModal(false)}
              {...props}
            >
              Close
            </Button>
          )}
          heroIcon={<Error color="error" />}
          onClose={() => setShowErrorModal(false)}
          open
          testID="payment-error-modal"
        >
          <Stack alignItems="center" flex={1} textAlign="center">
            <Text category="title-large">Payment failed</Text>
            <VSpacer size="6" />
            <Text category="body-medium">{errorMessage}</Text>
          </Stack>
        </Modal>
      }
    </>
  );
};

export default SubmitPaymentButton;
