import { DetailedApiError } from './DetailedApiError';
import { ApiError } from './ApiError';

export class ApiUtils {
  public static handleError = async (response: Response) => {
    if ([400, 403, 404, 409].includes(response.status)) {
      const errorMessage = await response.json();
      throw new DetailedApiError(`${response.status}`, errorMessage.message, errorMessage);
    }
    throw new ApiError(response.status);
  };
}
