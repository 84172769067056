import { Client } from '@/utilities/api/Client';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import { OauthEndpoint } from '@api/endpoints';

export class OauthApi {
  static verifyCode (request: Omit<OauthEndpoint.Verify.Request, 'subdomain'>) {
    const subdomain = getSubdomain();
    return Client('oauth/verify', { body: { ...request, subdomain }, method: 'POST' });
  }
}
