import { IconButton, Snackbar as MuiSnackbar, SnackbarContent, Stack } from '@mui/material';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { HSpacer } from '..';
import { ReactNode } from 'react';

interface SnackbarProps {
  action?: ReactNode,
  children?: ReactNode,
  dismissible?: boolean,
  duration?: number,
  longerAction?: boolean,
  onClose: () => void,
  open: boolean,
  testID: string,
}

export const Snackbar = ({
  action,
  children,
  dismissible = false,
  duration = 5000,
  longerAction = false,
  onClose,
  open,
  testID,
}: SnackbarProps) => {

  const { isMobile } = useMediaQuery();

  const snackbarAction = (
    <Stack alignItems="center" direction="row">
      {
        <>
          {action}
          <HSpacer size="5" />
        </>
      }
      {
        dismissible && (
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        )
      }
    </Stack>
  );

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: isMobile ? 'center' : 'right' }}
      autoHideDuration={duration}
      data-testid={testID}
      onClose={onClose}
      open={open}
      sx={{ maxWidth: '95vw' }}
    >
      {longerAction ? (
        <SnackbarContent message={(
          <Stack sx={{ minWidth: "344px" }}>
            {children}
            <Stack alignSelf="flex-end" direction="row">
              {snackbarAction}
            </Stack>
          </Stack>
        )} />
      ) : (
        <SnackbarContent
          action={action || dismissible ? snackbarAction : undefined}
          message={children}
        />
      )}
    </MuiSnackbar>
  );
};
