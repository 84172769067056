import { Button, VSpacer, Text, Dialog, FullscreenDialog } from '@/components/DesignSystem';
import { Stack } from '@mui/material';
import Check from "@mui/icons-material/Check";
import { DesktopOnly } from '../DesktopOnly';
import { MobileOnly } from '../MobileOnly';

interface CreateOfferSuccessDialogProps {
  daysToAccept: number,
  onDone: () => void,
}

export const CreateOfferSuccessDialog = ({
  daysToAccept,
  onDone,
}: CreateOfferSuccessDialogProps) => {

  const SuccessScreen = () => (
    <Stack alignItems="center">
      <MobileOnly>
        <VSpacer size="11" />
      </MobileOnly>
      <Check color="success" />
      <VSpacer size="7" />
      <Text category="headline-large">
        Success!
      </Text>
      <VSpacer size="7" />
      <Text
        category="body-large"
        textAlign="center"
      >
        Your offer has been successfully submitted! They will have {daysToAccept} days
        to accept once the Offer Window has closed.
      </Text>
      <VSpacer size="9" />
      <MobileOnly>
        <Button
          onClick={onDone}
          testID="create-offer-success-dialog-accept"
          variant="outlined"
        >
          Done
        </Button>
      </MobileOnly>
    </Stack>
  );

  return (
    <>
      <DesktopOnly>
        <Dialog
          acceptButton={() => (
            <Button
              onClick={onDone}
              testID="create-offer-success-dialog-accept"
              variant="text"
            >
              Done
            </Button>
          )}
          onClose={onDone}
          open
          showCloseButton={false}
          testID="create-offer-success-dialog"
        >
          <SuccessScreen />
        </Dialog>
      </DesktopOnly>
      <MobileOnly>
        <FullscreenDialog
          actionButton={undefined}
          onClose={onDone}
          open
          testID="create-offer-success-dialog"
        >
          <SuccessScreen />
        </FullscreenDialog>
      </MobileOnly>
    </>
  );
};
