import { Client } from '@/utilities/api/Client';
import { FeaturedManufacturerEndpoint } from '@api/endpoints';

export class FeaturedManufacturerApi {
  static Create (body: FeaturedManufacturerEndpoint.Create.Request) {
    return Client('featured-manufacturer', { body, method: 'POST' });
  }
  static list (
    search?: string,
  ): Promise<FeaturedManufacturerEndpoint.ListFeaturedManufacturer.Response[]> {
    if(search) {
      return Client(`featured-manufacturer?search=${search}`);
    }
    return Client(`featured-manufacturer`);
  }

  static delete (id: string): Promise<void> {
    return Client(`featured-manufacturer/${id}`, { method: 'DELETE' });
  }

  static reOrderFeaturedManufacturer (
    ids: string[],
  ): Promise<FeaturedManufacturerEndpoint.ListFeaturedManufacturer.Response[]> {
    return Client(`featured-manufacturer`, { method: 'PATCH', body: ids });
  }
}
