import { Dialog, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { QueryKeys } from '@/constants/QueryKeys';
import { getIcon, Wallet } from '@/pages/Admin/FarmerList/helper';
import { UserApi } from '@/utilities/api/UserApi';
import {
  CircularProgress,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  useTheme,
} from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useQuery } from 'react-query';

type ViewRewardsDialogProps = {
  show: boolean,
  setShow: Dispatch<SetStateAction<boolean>>,
  userId: string,
}

const ViewRewardsDialog = ({
  show,
  setShow,
  userId,
} : ViewRewardsDialogProps) => {
  const theme = useTheme();
  const { data: rewards, isLoading } = useQuery(
    [QueryKeys.GET_USER_REWARDS, userId],
    () => UserApi.getFarmerRewards(userId),
  );

  const getWallets = () => {
    let wallets: Wallet[] = [];
    if (rewards?.subledgerBalances) {
      wallets = [...rewards.subledgerBalances];
    }
    wallets.sort((a, b) => a.name.localeCompare(b.name));
    const growersWallet: Wallet = {
      activePoints: rewards?.balance?.activePoints ?? 0,
      retailerId: null,
      retailerName: null,
      name: 'Growers',
      manufacturerId: null,
      manufacturerName: null,
    };
    wallets.unshift(growersWallet);
    return wallets;
  };

  const renderWallets = () => {
    const wallets = getWallets();
    return wallets.map((wallet, index) => (
      <>
        <VSpacer size="3" />
        <ListItem key={wallet.name}>
          <ListItemIcon>
            <img
              src={getIcon(wallet)}
              style={{
                backgroundColor: theme.palette.getContrastText(theme.palette.background.paper),
                borderRadius: '100px',
                padding: '8px',
              }}
            />
          </ListItemIcon>
          <HSpacer size="8" />
          <ListItemText>
            <Text category="body-large">{wallet.name}</Text>
          </ListItemText>
          <HSpacer size="5" />
          <Text category="body-large">{wallet.activePoints} points</Text>
        </ListItem>
        <VSpacer size="3" />
        {index !== wallets.length - 1 && <Divider />}
      </>
    ));
  };

  return (
    <Dialog
      dialogWidth="500px"
      onClose={() => setShow(false)}
      open={show}
      showCloseButton={true}
      testID="view-rewards-dialog"
      title="View Rewards"
    >
      {isLoading &&
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      }
      {renderWallets()}
    </Dialog>
  );
};

export default ViewRewardsDialog;
