import { Radio } from '@/components/DesignSystem';
import { FriendlyFulfillmentMethod } from '@/constants/FriendlyFulfillmentMethod';
import { RadioGroup } from '@mui/material';
import { FulfillmentMethod } from '@shared/enums';

interface FulfillmentMethodPickerProps {
  disabled?: boolean,
  hideNoPreferenceOption?: boolean,
  onChange: (value: FulfillmentMethod) => void,
  value?: FulfillmentMethod,
}

export const FulfillmentMethodPicker = ({
  disabled = false,
  hideNoPreferenceOption = false,
  onChange,
  value,
}: FulfillmentMethodPickerProps) => {
  return (
    <RadioGroup
      name="fulfillmentMethod"
      onChange={(e) => onChange(e.target.value as FulfillmentMethod)}
      row
      value={value}>
      {
        !hideNoPreferenceOption && (
          <Radio
            disabled={disabled}
            testID="fulfillment-method-picker-no-preference"
            value={FulfillmentMethod.NO_PREFERENCE}
          >
            {FriendlyFulfillmentMethod[FulfillmentMethod.NO_PREFERENCE]}
          </Radio>
        )
      }
      <Radio
        disabled={disabled}
        testID="fulfillment-method-picker-pickup"
        value={FulfillmentMethod.PICKUP}
      >
        {FriendlyFulfillmentMethod[FulfillmentMethod.PICKUP]}
      </Radio>
      <Radio
        disabled={disabled}
        testID="fulfillment-method-picker-delivery"
        value={FulfillmentMethod.DELIVERY}
      >
        {FriendlyFulfillmentMethod[FulfillmentMethod.DELIVERY]}
      </Radio>
    </RadioGroup>
  );
};
