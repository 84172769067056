import { Alert, AlertTitle } from '@mui/material';

export type BannerStatus = 'error' | 'info' | 'success' | 'warning';
interface BannerProps {
  includeIcon?: boolean,
  message: string,
  status: BannerStatus,
  title?: string,
  variant?: 'filled' | 'outlined' | 'standard',
}

export const Banner = ({
  includeIcon = false,
  message,
  status,
  title,
  variant = 'filled',
}: BannerProps) => {

  return (
    <Alert icon={includeIcon ? undefined : false} severity={status} variant={variant}>
      {!!title && <AlertTitle>{title}</AlertTitle>}
      {message}
    </Alert>
  );
};
