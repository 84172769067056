import { PaletteOptions } from '@mui/material';

export const palette: PaletteOptions =  {
  mode: 'light',
  primary: {
    main: '#00629d',
  },
  secondary: {
    main: '#00629d',
  },
  background: {
    default: '#fafcff',
    paper: '#EBF3F9',
  },
  text: {
    primary: '#001f2a',
    secondary: '#42474e',
    disabled: 'rgba(0,31,42,0.38)',
  },
  divider: '#c2c7cf',
  error: {
    main: '#BA1A1A',
  },
  warning: {
    main: '#885200',
  },
  info: {
    main: '#00658c',
  },
  success: {
    main: '#316b00',
  },
};
