import React, { Fragment, useState } from 'react';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { Button, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import AddIcon from '@mui/icons-material/Add';
import { Search } from '@/components/DesignSystem';
import CardListItem from './CardListItem';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from '@hello-pangea/dnd';

const centeredStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: '56px !important',
  minHeight: '250px',
};

interface CardData {
  id: string;
  rank: number;
  name: string;
  productId?: string;
  manufacturerId?: string;
  retailerId?: string;
  bannerImage?: string;
}

export interface DetailedCardInterface {
  title: string;
  isSearch: boolean;
  addNew: (args: boolean) => void;
  deleteCard: (id: string) => void;
  data: CardData[] | undefined;
  setSearch: (args: string) => void;
  search: string;
  cardTitle: string;
  isDisabled?: boolean;
  isClickable?: boolean;
  saveChanges?: (args: string[]) => void;
  isSaveChangesLoading: boolean;
}

const DetailedCard = ({
  title,
  isSearch,
  addNew,
  deleteCard,
  data,
  setSearch,
  search,
  cardTitle,
  isDisabled = false,
  isClickable = false,
  saveChanges = () => {
    return;
  },
  isSaveChangesLoading,
}: DetailedCardInterface) => {
  const [draggableState, setDraggableState] = useState(data ?? []);
  const [reorderIds, setReorderIds] = useState<string[]>([]);
  const [isOrdersChange, setIsOrderChanged] = useState(false);
  React.useEffect(() => {
    setDraggableState(data ?? []);
  }, [data]);

  const reorder = (list: CardData[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result?.map((item, index) => ({
      ...item,
      rank: index + 1,
    }));
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      draggableState,
      result.source.index,
      result.destination.index,
    );
    setReorderIds(items.map((item) => item.id));
    setDraggableState(items);
    const initialIds = data?.map((item) => item.id);
    const reorderedIds = items.map((item) => item.id);
    if (initialIds?.join('') !== reorderedIds.join('')) {
      setIsOrderChanged(true);
    } else {
      setIsOrderChanged(false);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Card sx={{ width: '600px', display: 'flex', flexDirection: 'column' }}>
        <CardContent>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
          >
            <Text category='h6'>{title}</Text>
            <Stack alignItems='center' direction='row'>
              {isOrdersChange && (
                <Button
                  disabled={isSaveChangesLoading}
                  loading={isSaveChangesLoading}
                  onClick={() => {
                    saveChanges(reorderIds);
                    setIsOrderChanged(false);
                  }}
                  testID='confirm reorder'
                  variant='outlined'
                >
                  Save Re-Order
                </Button>
              )}
              <HSpacer size='4' />
              <Button
                disabled={
                  isDisabled || !!search?.length || isSaveChangesLoading
                }
                onClick={() => addNew(true)}
                size='medium'
                testID='add-new'
              >
                <AddIcon /> Add new
              </Button>
            </Stack>
          </Stack>
        </CardContent>
        <CardContent
          sx={
            !isSearch ? centeredStyle : { height: '100%', minHeight: '250px' }
          }
        >
          {!isSearch ? (
            <Text category='h6'>No featured {cardTitle.toLowerCase()}</Text>
          ) : (
            <Fragment>
              <Search onChangeText={setSearch} testID='search' value={search} />
              <VSpacer size='9' />
              {data?.length ? (
                <Fragment>
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    p={'5px 0px 10px 0px'}
                  >
                    <Typography sx={{ marginLeft: '62px' }}>
                      Position
                    </Typography>
                    <Typography sx={{ marginLeft: '50px' }}>
                      {cardTitle} name
                    </Typography>
                  </Stack>
                  <Droppable droppableId='droppable'>
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {draggableState.map(
                          (cardData: CardData, index: number) => (
                            <Draggable
                              draggableId={JSON.stringify(index)}
                              index={index}
                              key={index}
                            >
                              {(provided, snapshot) => (
                                <CardListItem
                                  cardDetails={cardData}
                                  cardId={cardData?.id}
                                  currentCardName={cardTitle}
                                  deleteCard={deleteCard}
                                  isClickable={isClickable}
                                  key={cardData?.id}
                                  name={cardData?.name}
                                  position={cardData?.rank.toString()}
                                  provided={provided}
                                  snapshot={snapshot}
                                />
                              )}
                            </Draggable>
                          ),
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Fragment>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text category='h6'>No results found</Text>
                </div>
              )}
            </Fragment>
          )}
        </CardContent>
      </Card>
    </DragDropContext>
  );
};

export default DetailedCard;
