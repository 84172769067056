import { ReactNode } from 'react';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import VerifiedIcon from '@mui/icons-material/Verified';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import SalesPersonContactCard from './SalesPersonContactCard';

export interface SalesPersonTabProps {
  actions?: ReactNode[] | undefined | JSX.Element[];
  children?: ReactNode;
  hasDrawer?: boolean;
  isOpen?: boolean;
  overline?: string;
  rightContent?: ReactNode;
  subtitle?: string;
  testID: string;
  isPreferredRetailer?: boolean;
  salespersonCard: {
    email: string;
    isPreferredRetailer: boolean;
    lastLook: boolean;
    location: string;
    name: string;
    phone: string;
  };
}

const SalesPersonTab = ({
  actions,
  overline,
  rightContent,
  subtitle,
  testID,
  salespersonCard,
}: SalesPersonTabProps) => {
  const { email, isPreferredRetailer, lastLook, location, name, phone } =
    salespersonCard;

  return (
    <Grid
      alignItems='flex-start'
      container
      direction='row'
      p={0}
      sx={{ alignItems: 'center' }}
    >
      <Grid>
        <>
          <Stack>
            {!!overline && (
              <Text category='overline' testID={`${testID}-overline`}>
                {overline}
              </Text>
            )}
            <Stack direction='row' justifyContent='space-between'>
              {!!name && (
                <Text flex={1} fontSize='16px' sx={{ textTransform: 'capitalize' }} testID={`${testID}-name`}>
                  {name}
                </Text>
              )}
              {!!rightContent && (
                <>
                  <HSpacer size='5' />
                  {rightContent}
                  {!actions?.length && <HSpacer size='6' />}
                </>
              )}
            </Stack>
            <VSpacer size='2' />
            {!!subtitle && (
              <>
                <VSpacer size='3' />
                <Text
                  category='body-medium'
                  fontSize='13px'
                  lineHeight='20px'
                  testID={`${testID}-subtitle`}
                >
                  {subtitle}
                </Text>
              </>
            )}
            <VSpacer size='3' />
            <SalesPersonContactCard
              salesPersonContactInfo={{
                email: email ?? '',
                location: location ?? '',
                phone: phone ?? '',
              }}
            />
          </Stack>
          <VSpacer size='3' />
          <Stack alignItems='top' direction='row' spacing={1}>
            {isPreferredRetailer && (
              <Text
                color='success'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '10px 10px 0 0',
                }}
              >
                <VerifiedIcon sx={{ marginRight: '5px' }} /> Preferred Retailer
              </Text>
            )}
            {lastLook && (
              <Text
                color='warning'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '10px 0 0 0',
                  paddingTop: '5px',
                }}
              >
                <TimerOutlinedIcon sx={{ marginRight: '5px' }} /> Last Look
              </Text>
            )}
          </Stack>
          <VSpacer size='5' />
        </>
      </Grid>
      {!!actions?.length && (
        <DesktopOnly>
          <Grid xs>
            <Stack
              alignContent='center'
              alignItems='center'
              direction='row'
              justifyContent='flex-end'
            >
              {actions?.map((action, idx) => {
                return (
                  <Stack direction='row' key={`button-container-${idx}`}>
                    {action}
                    {!!actions?.length && <HSpacer size='3' />}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </DesktopOnly>
      )}
    </Grid>
  );
};
export default SalesPersonTab;
