import { PaymentType } from '@shared/enums';

export const FriendlyPaymentType = {
  [PaymentType.ACH]: 'ACH',
  [PaymentType.Cash]: 'Cash',
  [PaymentType.CreditCard]: 'Credit card',
  [PaymentType.Finance]: 'Need financing',
  [PaymentType.Prequalified]: 'Pre-qualified for financing',
  [PaymentType.PrequalifiedGrowersFinancing]: 'Pre-qualified with GROWERS Financing Partner',
  [PaymentType.Rewards]: 'Rewards',
};
