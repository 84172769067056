import { BottomBar, Button, HSpacer } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { DemoBlock } from '../../components/DemoBlock';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';

export const BottomBarDemo = () => (
  <>
    <DesktopOnly>
      <DemoBlock label="Simple desktop usage, centered">
        <BottomBar isStatic justifyContent="center">
          <Button
            size="giant"
            startIcon={<Add />}
            testID="bottom-bar-demo-add-button"
          >
            Create Product Request
          </Button>
        </BottomBar>
      </DemoBlock>
      <DemoBlock label="Simple desktop usage, space between">
        <BottomBar isStatic>
          <Button testID="bottom-bar-demo-button">Button</Button>
          <Button testID="bottom-bar-demo-button">Button</Button>
          <Button testID="bottom-bar-demo-button">Button</Button>
        </BottomBar>
      </DemoBlock>
      <DemoBlock label="Simple desktop usage, flex end">
        <BottomBar isStatic justifyContent="flex-end">
          <Button testID="bottom-bar-demo-button">Button</Button>
          <HSpacer size="3" />
          <Button testID="bottom-bar-demo-button">Button</Button>
          <HSpacer size="3" />
          <Button testID="bottom-bar-demo-button">Button</Button>
        </BottomBar>
      </DemoBlock>
    </DesktopOnly>
    <MobileOnly>
      <DemoBlock label="Simple mobile usage, centered">
        <BottomBar isStatic justifyContent="center">
        <Button
          size="giant"
          startIcon={<Add />}
          testID="bottom-bar-demo-button"
        >
          Create Product Request
        </Button>
        </BottomBar>
      </DemoBlock>
      <DemoBlock label="Simple mobile usage, space between">
        <BottomBar isStatic justifyContent="space-between">
          <Button
            fullWidth
            startIcon={<Edit />}
            testID="bottom-bar-demo-edit-button"
          >
            Edit
          </Button>
          <HSpacer size="3" />
          <Button
            fullWidth
            testID="bottom-bar-demo-cancel-button"
          >
            Cancel
          </Button>
        </BottomBar>
      </DemoBlock>
      <DemoBlock label="Simple mobile usage, flex end">
        <BottomBar isStatic justifyContent="flex-end">
          <Button testID="bottom-bar-demo-button">Button</Button>
          <HSpacer size="3" />
          <Button testID="bottom-bar-demo-button">Button</Button>
        </BottomBar>
      </DemoBlock>
    </MobileOnly>
  </>
);
