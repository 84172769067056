import { Client } from '@/utilities/api/Client';
import { QueryUtil } from '../Query';
import { ScheduledNotificationEndpoint } from '@api/endpoints';

export class ScheduledNotificationApi {
  static create (
    body: ScheduledNotificationEndpoint.Create.Request,
  ): Promise<ScheduledNotificationEndpoint.Create.Response> {
    return Client('scheduled-notifications', {
      method: 'POST',
      body,
    });
  }

  static list (
    query: ScheduledNotificationEndpoint.List.Query,
  ): Promise<ScheduledNotificationEndpoint.List.Response> {
    const queryString = QueryUtil.stringify(query);
    return Client(`scheduled-notifications?${queryString}`);
  }

  static update (
    id: string,
    body: ScheduledNotificationEndpoint.Update.Request,
  ): Promise<ScheduledNotificationEndpoint.Update.Response> {
    return Client(`scheduled-notifications/${id}`, {
      method: 'PATCH',
      body,
    });
  }

  static delete (id: string): Promise<void> {
    return Client(`scheduled-notifications/${id}`, { method: 'DELETE' });
  }
}
