import {
  DataSet,
  HSpacer,
  IconButton,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { DeleteOutlined, Edit, EditOutlined } from '@mui/icons-material';
import { Box, Divider, Stack } from '@mui/material';
import { formatPhoneNumber } from '@shared/utilities';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { ApiSavedRetailer } from '@api/interfaces';

interface SavedRetailerListItemProps {
  onDelete?: () => void,
  onEdit?: () => void,
  retailer: ApiSavedRetailer,
  testID: string,
}

export const SavedRetailerListItem = ({
  onDelete,
  onEdit,
  retailer,
  testID,
}: SavedRetailerListItemProps) => {
  const contactInfo = [
    retailer.email,
    retailer.telephone && formatPhoneNumber(retailer.telephone, 'paren'),
  ].filter(Boolean).join(' • ');

  return (
    <>
      <MobileOnly>
        <Divider />
        <VSpacer size="3" />
        <Stack alignItems="start" direction="row" justifyContent="space-between">
          <Box sx={{ wordBreak: "break-word" }}>
            <Text
              category="body-large"
              paddingTop="8px"
              testID={`${testID}-name`}
            >
              {retailer.name}
            </Text>
          </Box>
          <Stack alignItems="start" direction="row">
            <IconButton
              aria-label="edit"
              onClick={onEdit}
              testID={`edit-${testID}`}
            >
              <Edit />
            </IconButton>
            <HSpacer size="5" />
            <IconButton
              aria-label="delete"
              onClick={onDelete}
              testID={`delete-${testID}`}
            >
              <DeleteOutlined />
            </IconButton>
          </Stack>
        </Stack>
        <VSpacer size="2" />
        <Box sx={{ wordBreak: "break-word" }}>
          <Text category="body-medium">
            {contactInfo}
          </Text>
        </Box>
        <VSpacer size="6" />
      </MobileOnly>
      <DesktopOnly>
        <DataSet
          actions={[
            (
              <IconButton
                aria-label="edit"
                key="edit-button"
                onClick={onEdit}
                size="small"
                testID={`edit-${testID}`}
              >
                <EditOutlined />
              </IconButton>
            ),
            (
              <IconButton
                aria-label="delete"
                key="delete-button"
                onClick={onDelete}
                testID={`delete-${testID}`}
              >
                <DeleteOutlined/>
              </IconButton>
            ),
          ]}
          key={retailer.id}
          metadata={[
            {
              children: retailer.email,
              label: 'Email:',
            },
            {
              children: retailer.telephone,
              label: 'Phone:',
            },
          ]}
          testID={testID}
          title={retailer.name}
        />
      </DesktopOnly>
    </>
  );
};
