import { QueryKeys } from '@/constants/QueryKeys';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { UserApi } from '@/utilities/api/UserApi';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const VerifyEmail = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { openSnackbar } = useSnackbar();
  const { refreshUser } = useAuthentication();

  const token = searchParams.get('token');

  const { mutate: verifyEmailUpdateToken } = useMutation(
    [QueryKeys.VALIDATE_TOKEN],
    (token: string) => UserApi.verifyEmail(token),
    {
      onError: () => {
        navigate(`${Routes.LANDING}`);
      },
      onSuccess: async () => {
        openSnackbar('Email successfully verified');
        await refreshUser();
        navigate(`${Routes.LANDING}`);
      },
      retry: false,
    },
  );
  useEffect(() => {
    if (token) {
      verifyEmailUpdateToken(token);
    } else {
      navigate(Routes.LANDING);
    }
  }, [navigate, token, verifyEmailUpdateToken]);

  return <></>;
};
