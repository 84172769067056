import {
  Button,
  DatePickerDropdown,
  HSpacer,
  Input,
  Modal,
  NumericInput,
  ProgressLoader,
  Radio,
  Text,
  TextAreaInput,
  VSpacer,
} from '@/components/DesignSystem';
import { Divider, RadioGroup, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { PromotionType } from '@shared/enums/PromotionType';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { formatDateOnly, pick } from '@shared/utilities';
import { ApiProduct, ApiRetailerLocation } from '@api/interfaces';
import { SelectProductsModal } from '@/pages/Admin/Promotions/SelectProductsModal';
import { SelectLocationsModal } from './SelectLocationsModal';

export type Mode = 'add' | 'edit' | 'view';

interface PromotionModalProps {
  mode: Mode,
  onClose: () => void,
  onSave: (promotion: PromotionInputs) => void,
  promotionIdToEdit?: string,
  testID: string,
}

export type PromotionInputs = {
  id?: string,
  description: string,
  name: string,
  type: PromotionType,
  products: ApiProduct[],
  locations: ApiRetailerLocation[],
  startDate?: Date,
  endDate?: Date,
  value?: number,
}

function getDate (dateString: string) {
  return DateTime.fromISO(dateString).toJSDate();
}

export const PromotionModal = ({
  mode,
  onClose,
  onSave,
  promotionIdToEdit,
  testID,
}: PromotionModalProps) => {
  const [showSelectProductsModal, setShowSelectProductsModal] = useState(false);
  const [showSelectLocationsModal, setShowSelectLocationsModal] = useState(false);
  const [promotion, setPromotion] =
    useState<PromotionInputs>({
      id: undefined,
      description: '',
      name: '',
      type: PromotionType.Farmer,
      products: [],
      locations: [],
      startDate: undefined,
      endDate: undefined,
      value: undefined,
    });

  const { data: promotionToEdit, isFetching: isPromotionFetching } = useQuery(
    [QueryKeys.GET_PROMOTION, promotionIdToEdit],
    async () => await PromotionApi.get(promotionIdToEdit!),
    {
      enabled: !!promotionIdToEdit,
    },
  );

  useEffect(() => {
    if (promotionToEdit) {
      setPromotion({
        products: promotionToEdit.products ?? [],
        locations: promotionToEdit.locations ?? [],
        startDate: getDate(promotionToEdit.startDate),
        endDate: getDate(promotionToEdit.endDate),
        ...pick(
          promotionToEdit,
          ['id', 'description', 'name', 'type', 'value'],
        ),
      });
    }
  }, [promotionToEdit]);

  const isStartDateValid = useMemo(() => (
    !!promotion.startDate
    && formatDateOnly(promotion.startDate) >= formatDateOnly(new Date())
  ), [promotion.startDate]);

  const isEndDateValid = useMemo(() => (
    !!promotion.endDate
    && formatDateOnly(promotion.endDate) >= formatDateOnly(new Date())
    && formatDateOnly(promotion.endDate) >= formatDateOnly(promotion.startDate ?? new Date())
  ), [promotion.startDate, promotion.endDate]);

  const readOnly = mode === 'view';
  const isFormValid = (
    !!promotion.type
    && !!promotion.name
    && !!promotion.description
    && !!promotion.products?.length
    && !!promotion.locations?.length
    && !!promotion.value
    && isStartDateValid
    && isEndDateValid
    && !readOnly
  );
  const productNames = promotion.products.map(({ name }) => name).join(', ');
  const locationNames = promotion.locations.map(({ name }) => name).join(', ');

  const TypeSelector = (
    <Stack>
      <Text>
        Displays for
      </Text>
      <VSpacer size="3" />
      <Stack direction="row">
        <HSpacer size="5" />
        <RadioGroup
          name="promotion-type"
          onChange={(e) => (
            setPromotion({ ...promotion, type: e.target.value as PromotionType })
          )}
          row
          value={promotion.type}
        >
          <Radio
            disabled={readOnly}
            sx={{ mr: '40px' }}
            testID={`${testID}-type-farmer`}
            value={PromotionType.Farmer}
          >
            Farmer
          </Radio>
          <Radio
            disabled={readOnly}
            testID={`${testID}-type-retailer`}
            value={PromotionType.Retailer}
          >
            Retailer
          </Radio>
        </RadioGroup>
      </Stack>
    </Stack>
  );

  let selectProductsText = readOnly ? 'Products' : 'Select Products';
  if (promotion.products.length) {
    selectProductsText += ` (${promotion.products.length})`;
  }
  let selectLocationsText = readOnly ? 'Retailer Locations' : 'Select Retailer Locations';
  if (promotion.locations.length) {
    selectLocationsText += ` (${promotion.locations.length})`;
  }

  const ProductSelector = (
    <Stack>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Text category="body-large">
          {selectProductsText}
        </Text>
        {!readOnly && (
          <Stack direction="row">
            <Button
              onClick={() => setShowSelectProductsModal(true)}
              square
              testID={`${testID}-select-products-button`}
              variant={promotion.products.length ? 'outlined' : 'contained'}
            >
              {promotion.products.length ? 'Edit Products' : 'Add Products'}
            </Button>
          </Stack>
        )}
      </Stack>
      {!!promotion.products.length && (
        <>
          <VSpacer size="4" />
          <Text category="body-small">
            {productNames}
          </Text>
        </>
      )}
    </Stack>
  );

  const LocationSelector = (
    <Stack>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Text category="body-large">
          {selectLocationsText}
        </Text>
        {!readOnly && (
          <Stack direction="row">
            <Button
              onClick={() => setShowSelectLocationsModal(true)}
              square
              testID={`${testID}-select-locations-button`}
              variant={promotion.locations.length ? 'outlined' : 'contained'}
            >
              {promotion.locations.length ? 'Edit Locations' : 'Add Locations'}
            </Button>
          </Stack>
        )}
      </Stack>
      {!!promotion.locations.length && (
        <>
          <VSpacer size="4" />
          <Text category="body-small">
            {locationNames}
          </Text>
        </>
      )}
    </Stack>
  );

  const Inputs = (
    <>
      <Input
        disabled={readOnly}
        isTransparent={readOnly}
        label="Name"
        maxCharacterLimit={75}
        onChangeText={(name) => setPromotion({ ...promotion, name })}
        required={!readOnly}
        testID={`${testID}-name`}
        value={promotion.name}
      />
      <TextAreaInput
        disabled={readOnly}
        label="Description"
        maxCharacterLimit={500}
        onChangeText={(description) => setPromotion({ ...promotion, description })}
        required={!readOnly}
        testID={`${testID}-description`}
        value={promotion.description}
      />
      <NumericInput
        disabled={readOnly}
        isTransparent={readOnly}
        label="Points value"
        maxValue={9999.99}
        minValue={0.01}
        onChangeNumber={(value) => setPromotion({ ...promotion, value })}
        required={!readOnly}
        testID={`${testID}-value`}
        value={promotion.value}
        width="50%"
      />
      {!readOnly && <Text>
          Please select the dates when the promotion will be actively running
      </Text>}
      <Stack direction="row">
        <DatePickerDropdown
          disabled={readOnly}
          errorMessage={(readOnly || isStartDateValid || !promotion.startDate)
            ? undefined
            : 'Invalid start date'}
          label="Start date"
          onChange={(startDate) => setPromotion({ ...promotion, startDate })}
          testID={`${testID}-start-date`}
          value={promotion.startDate}
        />
        <HSpacer size="7" />
        <DatePickerDropdown
          disabled={readOnly}
          errorMessage={(readOnly || isEndDateValid || !promotion.endDate)
            ? undefined
            : 'Invalid end date'}
          label="End date"
          onChange={(endDate) => setPromotion({ ...promotion, endDate })}
          testID={`${testID}-end-date`}
          value={promotion.endDate}
        />
      </Stack>
    </>
  );

  return (
    <>
      <Modal
        acceptButton={readOnly ? undefined : (props) => (
          <Button
            {...props}
            disabled={!isFormValid}
            onClick={() => {
              onClose();
              onSave(promotion);
            }}
            variant="contained"
          >
            Save
          </Button>
        )}
        cancelButton={(props) => (
          <Button
            {...props}
            onClick={onClose}
            sx={{ color: '#D8D8D8' }}
          >
            {readOnly ? 'Close' : 'Cancel'}
          </Button>
        )}
        onClose={onClose}
        open
        testID={testID}
        title={`${mode.charAt(0).toUpperCase() + mode.slice(1)} Promotion`}
        width={560}
      >
        {isPromotionFetching ? (
          <Stack alignItems="center" py="180px">
            <ProgressLoader type="circular" />
          </Stack>
        ) : (
          <Stack gap="28px">
            {TypeSelector}
            <Divider />
            {ProductSelector}
            <Divider />
            {LocationSelector}
            {Inputs}
          </Stack>
        )}
      </Modal>
      {showSelectProductsModal && (
        <SelectProductsModal
          existingProducts={promotion.products}
          onClose={() => setShowSelectProductsModal(false)}
          onSave={(products) => setPromotion({ ...promotion, products })}
          testID={`${testID}-select-products-modal`}
        />
      )}
      {showSelectLocationsModal && (
        <SelectLocationsModal
          existingLocations={promotion.locations}
          onClose={() => setShowSelectLocationsModal(false)}
          onSave={(locations) => setPromotion({ ...promotion, locations })}
          testID={`${testID}-select-locations-modal`}
        />
      )}
    </>
  );
};
