import { Color } from '@/themes/MUITheme/palette';
import Clear from '@mui/icons-material/Clear';
import { Chip as MuiChip, ChipProps as MuiChipProps, styled } from '@mui/material';
import { MouseEventHandler, ReactElement } from 'react';

export interface ChipProps extends MuiChipProps {
  color?: Color,
  disabled?: boolean,
  icon?: ReactElement,
  onClick?: MouseEventHandler<HTMLDivElement>,
  onDelete?: () => void,
  rounded?: boolean,
  testID: string,
  variant?: "filled" | "outlined",
}

export const Chip = ({
  color,
  disabled,
  icon,
  label,
  onClick,
  onDelete,
  rounded,
  size = 'small',
  sx,
  testID,
  variant,
}: ChipProps) => {

  const RoundedChip = styled((props: MuiChipProps) => (
    <MuiChip {...props} />
  ))(() => ({
    borderRadius: "100px",
    '.MuiChip-label': {
      paddingLeft: "2px",
    },
  }));

  const props = {
    "data-testid": testID,
    color,
    deleteIcon: <Clear data-testid={`${testID}-delete`} />,
    disabled,
    icon,
    label,
    onClick,
    onDelete,
    size,
    sx,
    variant,
  };

  if (!label) {
    props.sx = {
      ...props.sx,
      "& .MuiChip-label": {
        paddingLeft: '0px',
      },
    };
  }

  if (rounded) {
    return (
      <RoundedChip {...props} />
    );
  }

  return (
    <MuiChip {...props} />
  );
};
