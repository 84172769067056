import { ResponsivePicker } from './ResponsivePicker';
import { offerWindowItems, OfferWindowItem } from '@/constants/OfferWindowItems';

export interface OfferWindowPickerProps {
  offerWindow?: number,
  onChange: (offerWindow: number) => void,
  onClose: () => void,
  open: boolean,
  testID: string,
}

export const OfferWindowPicker = ({
  offerWindow = 3,
  onChange,
  onClose,
  open,
  testID,
}: OfferWindowPickerProps) => {

  return (
    <ResponsivePicker<OfferWindowItem>
      data={offerWindowItems}
      onChange={(item) => onChange(item!.days)}
      onClose={onClose}
      open={open}
      testID={testID}
      title="Offer window"
      value={offerWindowItems?.find(x => x.days === offerWindow)}
    />
  );
};
