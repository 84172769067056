import { Client } from '@/utilities/api/Client';
import { FeaturedProductEndpoint } from '@api/endpoints';

export class FeaturedProductApi {
  static Create (body: FeaturedProductEndpoint.Create.Request) {
    return Client('featured-product', { body, method: 'POST' });
  }
  static list (
    search?: string,
  ): Promise<FeaturedProductEndpoint.ListFeaturedProduct.Response[]> {
    if (search) {
      return Client(`featured-product?search=${search}`);
    }
    return Client(`featured-product`);
  }

  static delete (id: string) {
    return Client(`featured-product/${id}`, { method: 'DELETE' });
  }

  static reOrderFeaturedProduct (
    ids: string[],
  ): Promise<FeaturedProductEndpoint.ListFeaturedProduct.Response[]> {
    return Client(`featured-product`, { method: 'PATCH', body: ids });
  }
}
