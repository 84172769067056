import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export interface TopAppBarProps {
  children?: React.ReactNode,
  height?: number,
  isStatic?: boolean, // for demo purposes
  leftAccessory?: ReactNode,
  rightAccessory?: ReactNode,
  testID: string,
  title?: ReactNode,
  zIndex?: number,
}

export const TopAppBar = ({
  children,
  height = 64,
  isStatic = false,
  leftAccessory,
  rightAccessory,
  testID,
  zIndex,
}: TopAppBarProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const padding = '8px 16px 8px 16px';

  return (
    <Box
      data-testid={testID}
      sx={{
        backgroundColor: 'background.default',
        boxSizing: 'border-box',
        height: height,
        position: isStatic ? "static" : "fixed",
        width: "100%",
        zIndex: zIndex ?? theme.zIndex.appBar,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        height="64px"
        justifyContent="space-between"
        p={!isMobile ? padding : 1}
      >
        {!!leftAccessory && <>{leftAccessory}</>}
        <Stack>
          {children}
        </Stack>
        {!!rightAccessory && <>{rightAccessory}</>}
      </Stack>
    </Box>
  );
};

