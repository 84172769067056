import { useEffect, useState } from 'react';
import {
  Button,
  MenuItem,
  Select,
  SideSheet,
  Text,
} from '@/components/DesignSystem';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ApiCounty } from '@api/interfaces';
import SelectCounties from './SelectCounties';
import { CountiesByStateTypes } from '../Types';
import { useDeleteLocationCounty } from '@/hooks/useRetailerLocations';
import { useParams } from 'react-router-dom';

type ViewCountiesProps = {
  countiesByState?: CountiesByStateTypes
  isviewCounties: boolean;
  locationCounties: ApiCounty[] | undefined;
  setIsViewCounties: (value: boolean) => void;
};

const ViewCounties = ({
  countiesByState = {},
  isviewCounties,
  locationCounties,
  setIsViewCounties,
}: ViewCountiesProps) => {
  const [selectedState, setSelectedState] = useState('');
  const [showAddCounties, setShowAddCounties] = useState(false);

  const { locationId = '' } = useParams();

  const { deleteLocationCounty } = useDeleteLocationCounty(locationId);

  const onDeleteClick = (countyId: string) => {
    deleteLocationCounty(countyId);
  };

  useEffect(() => {
    if(!countiesByState[selectedState]) {
      setSelectedState('');
    }
  }, [countiesByState, selectedState]);

  return (
    <SideSheet
      hideScrollbar={true}
      onClose={() => {
        setIsViewCounties(false);
      }}
      onOpen={() => setIsViewCounties(true)}
      open={isviewCounties}
      testID='sidesheet'
    >
      <Button
        onClick={() => {
          setShowAddCounties(true);
        }}
        size='medium'
        sx={{ position: 'absolute', top: 20, left: 20 }}
        testID='add'
        variant='outlined'
      >
        Add
      </Button>
      <Stack p='12px 24px 16px 24px'>
        <Text category='h6'>Counties</Text>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          p='24px 0px'
        >
          <Text>This seller services {locationCounties?.length} Counties.</Text>
        </Stack>
        <Select
          label='State'
          onChangeValue={(value) => {
            setSelectedState(value);
          }}
          onClear={() => setSelectedState('')}
          testID='counties-modal-select-state'
          value={selectedState}
        >
          {Object.keys(countiesByState)?.length === 0 && (
            <MenuItem disabled testID='no-state'>
              No States available
            </MenuItem>
          )}
          {Object.keys(countiesByState)?.map((state: string) => (
            <MenuItem key={state} testID='select-county' value={state}>
              {state}
            </MenuItem>
          ))}
        </Select>
        {!selectedState && (
          <Stack alignItems='center' justifyContent='center' p='56px 0px'>
            <Text>Select a state</Text>
          </Stack>
        )}
        <Stack>
          {selectedState && (
            <>
              <Stack
                alignItems='center'
                direction='row'
                justifyContent='space-between'
                p='24px 0px'
              >
                <Text>{countiesByState[selectedState]?.length} Counties</Text>
              </Stack>
              <List>
                {countiesByState[selectedState]?.map(
                  (countyData: ApiCounty) => (
                    <>
                      <ListItem
                        key={countyData.county}
                        sx={{ background: 'rgba(33,33,32,1)' }}
                      >
                        <ListItemText primary={`${countyData.county}`} />
                        <ListItemIcon>
                          <IconButton onClick={() => onDeleteClick(countyData?.id)}>
                            <DeleteOutlinedIcon />
                          </IconButton>
                        </ListItemIcon>
                      </ListItem>
                      <Divider />
                    </>
                  ),
                )}
              </List>
            </>
          )}
        </Stack>
      </Stack>
      {showAddCounties && (
        <SelectCounties
          isSelectCounties={showAddCounties}
          setIsSelectCounties={setShowAddCounties}
        />
      )}
    </SideSheet>
  );
};

export default ViewCounties;
