import { AppHeader } from '@/components/shared/AppHeader';
import { Routes } from '@/constants/Routes';
import { VerifyEmail } from '@/pages/Auth/VerifyEmail';
import { PlaceOfferSuccess } from '@/pages/ReceivePlaceOffers/PlaceOfferSuccess';
import RedirectToOs from '@/pages/RedirectToOs/RedirectToOs';
import { ViewPriceRequest } from '@/pages/ViewPriceRequest/ViewPriceRequest';
import { Route, Routes as AppRoutes } from 'react-router-dom';

export const PublicView = () => {

  const createLayout = (element: React.ReactNode) => {
    return (
      <>
        <AppHeader />
        {element}
      </>
    );
  };

  return (
    <AppRoutes>
      <Route element={<ViewPriceRequest />} path={Routes.PLACE_OFFER} />
      <Route element={<RedirectToOs />} path={Routes.REDIRECT} />
      <Route element={createLayout(<PlaceOfferSuccess />)} path={Routes.PLACE_OFFER_SUCCESS} />
      <Route element={createLayout(<VerifyEmail />)} path={Routes.VERIFY_EMAIL} />
    </AppRoutes>
  );
};
