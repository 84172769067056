import { Card, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ApiUserPublic } from '@api/interfaces';
import { Container, Stack } from '@mui/material';

interface ContactInformationProps {
  user: ApiUserPublic,
}

const ContactInformation = ({ user }: ContactInformationProps) => {
  const { isMobile } = useMediaQuery();

  return (
    <>
      <Text category={isMobile ? 'c2' : 'h3'} upperCase={isMobile}>Contact Information</Text>
      <VSpacer size="7" />
      <Card testID="contact-information">
        <Stack direction={isMobile ? 'column' : 'row'}>
          <Container disableGutters>
            <Stack direction="row">
              <Text category="p1">Email:</Text>
              <HSpacer size="2"/>
              <Text category="p1">{user.email}</Text>
            </Stack>
            <VSpacer size="2" />
            <Stack direction="row">
              <Text category="p1">Name:</Text>
              <HSpacer size="2"/>
              <Text category="p1">{`${user.firstName} ${user.lastName}`}</Text>
            </Stack>
            <VSpacer size="2" />
            <Stack direction="row">
              <Text category="p1">Farm:</Text>
              <HSpacer size="2"/>
              <Text category="p1">{user.businessName}</Text>
            </Stack>
            <VSpacer size="2" />
            <Stack direction="row">
              <Text category="p1">Phone:</Text>
              <HSpacer size="2"/>
              <Text category="p1">{user.telephone}</Text>
            </Stack>
          </Container>
          { isMobile && <VSpacer size="5" /> }
          <Container disableGutters>
            <Stack direction="row">
              <Text category="p1">City:</Text>
              <HSpacer size="2"/>
              <Text category="p1">{user.city}</Text>
            </Stack>
            <VSpacer size="2" />
            <Stack direction="row">
              <Text category="p1">County:</Text>
              <HSpacer size="2"/>
              <Text category="p1">{user.countyData?.county}</Text>
            </Stack>
            <VSpacer size="2" />
            <Stack direction="row">
              <Text category="p1">State:</Text>
              <HSpacer size="2"/>
              <Text category="p1">{user.state}</Text>
            </Stack>
            <VSpacer size="2" />
            <Stack direction="row">
              <Text category="p1">Address 1:</Text>
              <HSpacer size="2"/>
              <Text category="p1">{user.address1}</Text>
            </Stack>
            <VSpacer size="2" />
            <Stack direction="row">
              <Text category="p1">Address 2:</Text>
              <HSpacer size="2"/>
              <Text category="p1">{user.address2}</Text>
            </Stack>
          </Container>
        </Stack>
      </Card>
    </>
  );
};

export default ContactInformation;
