import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import {
  FormControlLabel,
  FormControlLabelProps,
  Radio as MuiRadio,
} from '@mui/material';
import { ReactNode } from 'react';

interface RadioProps extends Partial<FormControlLabelProps> {
  children?: ReactNode,
  disabled?: boolean,
  testID: string,
}

export const Radio = ({
  children,
  disabled = false,
  testID,
  ...rest
}: RadioProps) => {
  return (
    <FormControlLabel
      control={
        <MuiRadio
          checkedIcon={
            <RadioButtonChecked />
          }
          disabled={disabled}
          icon={<RadioButtonUnchecked />}
        />
      }
      data-testid={testID}
      disabled={disabled}
      label={children}
      {...rest}
    />
  );
};
