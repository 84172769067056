import { useMediaQuery } from '@/hooks/useMediaQuery';
import { DataSet, DataSetProps, Text } from '..';

export type OptionalDataSetProps = Pick<
  Partial<DataSetProps>,
  | 'actions'
  | 'badges'
  | 'subtitle'
  | 'title'>;

export interface ProductListItemProps extends OptionalDataSetProps {
  price?: string | number, 
  testID: string,
}

export const ProductListItem = ({
  actions,
  badges,
  price,
  subtitle,
  testID,
  title,
}: ProductListItemProps) => {
  const { isMobile } = useMediaQuery();

  return (
    <DataSet
      actions={!isMobile ? actions : []}
      badges={badges}
      rightContent={<Text category="body-large" testID={`${testID}-price`}>{price}</Text>}
      subtitle={subtitle}
      testID={testID}
      title={title}
    />
  );
};
