import { FriendlyUoM } from '@/constants/FriendlyUoM';
import {
  LiquidFormulationUnitOfMeasure,
  ProductUom,
  SeedProductUnitOfMeasure,
  DryFormulationUnitOfMeasure,
  ServiceUnitOfMeasure,
} from '@shared/enums';
import { MenuItem, Select } from '../../DesignSystem';
import { SelectProps } from '../../DesignSystem/Select/Select';

interface Option {
  label: string,
  value: ProductUom,
}

interface ProductUomSelectProps extends SelectProps {
  allowedUoms?: ProductUom[],
  isUomDisabled?: (value: string) => boolean,
  label?: string,
  onChangeUoM: (uom?: ProductUom) => void,
  value?: ProductUom,
}

export const ProductUomSelect = ({
  allowedUoms,
  isUomDisabled,
  label = "UoM",
  onChangeUoM,
  ...rest
}: ProductUomSelectProps) => {
  const options: Option[] = [];
  const allowedOptions: Option[] = allowedUoms?.map((uom) => ({
    label: FriendlyUoM[uom],
    value: uom,
  }) || []) as Option[];

  options.push(
    {
      label: FriendlyUoM[SeedProductUnitOfMeasure.BUSHEL],
      value: SeedProductUnitOfMeasure.BUSHEL,
    },
    {
      label: FriendlyUoM[SeedProductUnitOfMeasure.BAG],
      value: SeedProductUnitOfMeasure.BAG,
    },
    {
      label: FriendlyUoM[DryFormulationUnitOfMeasure.POUND],
      value: DryFormulationUnitOfMeasure.POUND,
    },
    {
      label: FriendlyUoM[DryFormulationUnitOfMeasure.TON],
      value: DryFormulationUnitOfMeasure.TON,
    },
    {
      label: FriendlyUoM[DryFormulationUnitOfMeasure.OUNCE],
      value: DryFormulationUnitOfMeasure.OUNCE,
    },
    {
      label: FriendlyUoM[LiquidFormulationUnitOfMeasure.PINT],
      value: LiquidFormulationUnitOfMeasure.PINT,
    },
    {
      label: FriendlyUoM[LiquidFormulationUnitOfMeasure.QUART],
      value: LiquidFormulationUnitOfMeasure.QUART,
    },
    {
      label: FriendlyUoM[LiquidFormulationUnitOfMeasure.GALLON],
      value: LiquidFormulationUnitOfMeasure.GALLON,
    },
    {
      label: FriendlyUoM[LiquidFormulationUnitOfMeasure.TOTE],
      value: LiquidFormulationUnitOfMeasure.TOTE,
    },
    {
      label: FriendlyUoM[LiquidFormulationUnitOfMeasure.FLUID_OUNCE],
      value: LiquidFormulationUnitOfMeasure.FLUID_OUNCE,
    },
    {
      label: FriendlyUoM[ServiceUnitOfMeasure.ACRE],
      value: ServiceUnitOfMeasure.ACRE,
    },
  );

  const filteredOptions = allowedOptions?.length
    ? options.filter(({ value }) => allowedOptions.some((allowed) => allowed.value === value))
    : options;

  return (
    <Select
      {...rest}
      label={label}
      onChangeValue={(uom) => onChangeUoM(uom as ProductUom)}
    >
      {filteredOptions.map((option, idx) => (
        <MenuItem
          disabled={isUomDisabled?.(option.value)}
          key={option.value}
          testID={`product-uom-select-item-${idx}`}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
