import { DataPoint, Heading, Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { ModalBottomSheet } from '@/components/shared/ModalBottomSheet/ModalBottomSheet';
import { FriendlyFulfillmentMethod } from '@/constants/FriendlyFulfillmentMethod';
import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { useFarmerOfferInfo } from '@/pages/ViewPriceRequest/helpers';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { formatLicense } from '@/utilities/PricingRequest';
import { ApiPricingRequestPublic } from '@api/interfaces';
import { Mail } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { FulfillmentMethod } from '@shared/enums';

const styles: SXStyles = {
  note: {
    border: '1px solid',
    borderRadius: 2,
    padding: '20px 20px 24px 20px',
  },
  dataPoint: {
    py: 0,
  },
  lineBreaks: {
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
  breakAll: { wordBreak: 'break-all' },
  breakWord: { wordBreak: 'break-word' },
} as const;

interface ContactInfoModalBottomSheetProps {
  onClose: () => void,
  onOpen: () => void,
  priceRequest: ApiPricingRequestPublic,
}

export const ContactInfoModalBottomSheet = ({
  onClose,
  onOpen,
  priceRequest,
}: ContactInfoModalBottomSheetProps) => {
  const {
    address,
    deliveryAddress,
    expirationDate,
    farmerName,
    telephone,
  } = useFarmerOfferInfo(priceRequest);

  return (
    <ModalBottomSheet
      containerSpacing={1}
      containerStyle={{
        px: getResponsiveValue(2, 0),
      }}
      mobileTitle="Details"
      onClose={onClose}
      onOpen={onOpen}
      open
      testID="view-price-request-contact-info-dialog"
      title="Contact Information"
      width={395}
    >
      <MobileOnly>
        <DataPoint
          containerStyle={styles.dataPoint}
          label="Expires on"
          spacing="fixed"
          testID="details-expiration"
        >
          {expirationDate}
        </DataPoint>
      </MobileOnly>
      <DataPoint
        childrenStyle={styles.breakWord}
        containerStyle={styles.dataPoint}
        label="Farmer"
        spacing="fixed"
        testID="contact-info-farmer"
      >
        {farmerName === '' ? '-' : farmerName}
      </DataPoint>
      <DataPoint
        childrenStyle={styles.breakWord}
        containerStyle={styles.dataPoint}
        label="Farm name"
        spacing="fixed"
        testID="contact-info-farm-name"
      >
        {priceRequest?.user?.businessName ?? '-'}
      </DataPoint>
      <MobileOnly>
        <DataPoint
          childrenStyle={styles.breakWord}
          containerStyle={styles.dataPoint}
          label="Fulfillment"
          spacing="fixed"
          testID="details-fulfillment"
        >
          {priceRequest?.fulfillmentMethod === FulfillmentMethod.DELIVERY ? (
            deliveryAddress === '' ? '-' : `Delivery to ${deliveryAddress}`
          ) : (
            !priceRequest?.fulfillmentMethod
              ? '-'
              : FriendlyFulfillmentMethod[priceRequest.fulfillmentMethod]
          )}
        </DataPoint>
        <DataPoint
          containerStyle={styles.dataPoint}
          label="Contact info"
          spacing="fixed"
          testID="details-contact-info"
        >
          <Stack spacing={1}>
            {!!priceRequest?.user?.telephone && (
              <TextLink
                category="body-medium"
                href={`tel:${priceRequest.user.telephone}`}
                testID="contact-info-telephone"
              >
                {telephone}
              </TextLink>
            )}
            {!!priceRequest?.user?.email && (
              <TextLink
                category="body-medium"
                href={`mailto:${priceRequest.user.email}`}
                sx={styles.breakAll}
                testID="contact-info-email"
              >
                {priceRequest.user.email}
              </TextLink>
            )}
            <Text multiline testID="contact-info-address">
              {address}
            </Text>
          </Stack>
        </DataPoint>
        <DataPoint
          containerStyle={styles.dataPoint}
          label="Restricted Use License"
          spacing="fixed"
          testID="contact-info-restricted-use-license"
        >
          {formatLicense(
            priceRequest.user?.restrictedUseLicense,
            priceRequest.user?.restrictedUseLicenseExpirationDate,
          )}
        </DataPoint>
        <DataPoint
          containerStyle={styles.dataPoint}
          label="Tax-exempt License"
          spacing="fixed"
          testID="contact-info-tax-exempt-license"
        >
          {formatLicense(
            priceRequest.user?.taxExemptLicense,
            priceRequest.user?.taxExemptLicenseExpirationDate,
          )}
        </DataPoint>
        <DataPoint
          containerStyle={styles.dataPoint}
          label="Technology License"
          spacing="fixed"
          testID="contact-info-technology-license"
        >
          {formatLicense(
            priceRequest.user?.technologyLicense,
            priceRequest.user?.technologyLicenseExpirationDate,
          )}
        </DataPoint>
        {!!priceRequest.note && (
          <>
            <VSpacer size="5" />
            <Stack spacing={2} sx={styles.note}>
              <Heading
                leftAccessory={<Mail fontSize="inherit" />}
                level="4"
                testID="view-price-request-details-note-from-farmer-header"
                title="NOTE FROM FARMER"
              />
              <Text sx={styles.lineBreaks}>
                {priceRequest.note}
              </Text>
            </Stack>
          </>
        )}
      </MobileOnly>
      <DesktopOnly>
        <DataPoint
          containerStyle={styles.dataPoint}
          label="Phone"
          spacing="fixed"
          testID="contact-info-telephone"
        >
          {!priceRequest?.user?.telephone ? '-' : (
            <TextLink
              category="body-medium"
              href={`tel:${priceRequest.user.telephone}`}
              testID="contact-info-telephone"
            >
              {telephone}
            </TextLink>
          )}
        </DataPoint>
        <DataPoint
          containerStyle={styles.dataPoint}
          label="Email"
          spacing="fixed"
          testID="contact-info-email"
        >
          {!priceRequest?.user?.email ? '-' : (
            <TextLink
              category="body-medium"
              href={`mailto:${priceRequest.user.email}`}
              testID="contact-info-email"
            >
              {priceRequest.user.email}
            </TextLink>
          )}
        </DataPoint>
        <DataPoint
          containerStyle={styles.dataPoint}
          label="Address"
          spacing="fixed"
          testID="contact-info-address"
        >
          <Text category="body-medium" multiline testID="contact-info-address">
            {address}
          </Text>
        </DataPoint>
      </DesktopOnly>
    </ModalBottomSheet>
  );
};
