import {
  DryFormulationUnitOfMeasure,
  LiquidFormulationUnitOfMeasure,
  SeedApplicationUnitOfMeasure,
  parseEnum,
} from '../enums';

export const LiquidScale = {
  [LiquidFormulationUnitOfMeasure.GALLON]: 1,
  [LiquidFormulationUnitOfMeasure.FLUID_OUNCE]: 0.0078125,
  [LiquidFormulationUnitOfMeasure.PINT]: 0.125,
  [LiquidFormulationUnitOfMeasure.QUART]: 0.25,
  [LiquidFormulationUnitOfMeasure.TOTE]: 275,
};

export const DryScale = {
  [DryFormulationUnitOfMeasure.POUND]: 1,
  [DryFormulationUnitOfMeasure.TON]: 2000,
  [DryFormulationUnitOfMeasure.OUNCE]: 0.0625,
};

export const SeedScale = {
  [SeedApplicationUnitOfMeasure.KSDS]: 1,
  [SeedApplicationUnitOfMeasure.MSDS]: 1000,
};

export class ConversionError extends Error { }

export class ConversionUtility {
  static convertProductUoM (value: number, sourceUoM: string, targetUoM: string): number {
    if (sourceUoM === targetUoM) { return value; }

    const isLiquid = sourceUoM in LiquidScale && targetUoM in LiquidScale;
    const isDry = sourceUoM in DryScale && targetUoM in DryScale;
    const isSeed = sourceUoM in SeedScale && targetUoM in SeedScale;

    if (isLiquid) {
      return this.convertValue(value, sourceUoM, targetUoM, LiquidScale);
    }
    if (isDry) {
      return this.convertValue(value, sourceUoM, targetUoM, DryScale);
    }
    if (isSeed) {
      return this.convertValue(value, sourceUoM, targetUoM, SeedScale);
    }

    throw new ConversionError(
      `Unable to determine conversion scale, the source (${sourceUoM}) or target (${targetUoM}) unit of'
      + ' (${value}) units of measurement is not accounted for by either a wet or dry scale.`,
    );
  }

  static isSameCategory (sourceUom: string, targetUoM: string) {
    if (sourceUom === targetUoM) {
      return true;
    }
    const isLiquidSource = !!parseEnum(LiquidFormulationUnitOfMeasure, sourceUom);
    const isDrySource = !!parseEnum(DryFormulationUnitOfMeasure, sourceUom);

    const isLiquidTarget = !!parseEnum(LiquidFormulationUnitOfMeasure, targetUoM);
    const isDryTarget  = !!parseEnum(DryFormulationUnitOfMeasure, targetUoM);

    return (isLiquidSource && isLiquidTarget) || (isDrySource && isDryTarget);
  }

  private static convertValue (
    value: number,
    sourceUoM: string,
    targetUoM: string,
    scale: {[uom: string]: number},
  ): number {
    const result = ((value * scale[sourceUoM]) / scale[targetUoM]);

    if (result == null || Number.isNaN(result)) {
      return 0;
    }

    return result;
  }
}
