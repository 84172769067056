import {
  Button,
  Card,
  Dialog,
  Text,
  TextElement,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { ShortDivider } from '@/components/DesignSystem/ShortDivider/ShortDivider';
import { Retailer } from '@/pages/CreateSendPriceRequest/interfaces';
import Verified from '@mui/icons-material/Verified';
import { Divider, Stack } from '@mui/material';
import { formatPhoneNumber } from '@shared/utilities';
import { useState } from 'react';

const styles = {
  icon: {
    padding: '8px',
    color: 'success',
  },
  retailerCard: {
    width: '100%',
    borderRadius: '8px',
  },
  cardContent: {
    padding: '24px',
  },
  card: {
    borderRadius: '12px',
  },
};

interface InfoDialogProps {
  onChange: () => void,
  preferredRetailer: Retailer,
  setShowInfoDialog: (trigger: boolean) => void,
  testID: string,
}

interface PreferredRetailerCardProps {
  onChange: () => void,
  preferredRetailer: Retailer,
  testID: string,
}

export const PreferredRetailerCard = ({
  onChange,
  preferredRetailer,
  testID,
}: PreferredRetailerCardProps) => {
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  return (
    <>
      <Card
        cardContentStyle={styles.cardContent}
        sx={styles.card}
        testID={`${testID}-card`}
        variant="outlined"
      >
        <Stack>
          <Stack
            alignItems="center"
            direction="row"
          >
            <Verified
              sx={styles.icon}
            />
            <Text category="body-xlarge">
              Preferred Retailer
            </Text>
          </Stack>
          <VSpacer size="5" />
          <Text
            category="body-medium"
          >
            Allow your preferred retailer to see the most competitive
            offer and have additional time to submit their best offer.
          </Text>
          <VSpacer size="8" />
          <ShortDivider />
          <VSpacer size="8" />
          <Stack alignItems="center">
            <TextLink
              category="body-small"
              onClick={() => setShowInfoDialog(true)}
              testID={`${testID}-about-preferred-retailers-link`}
            >
              About Preferred Retailers
            </TextLink>
          </Stack>
          <VSpacer size="5" />
        </Stack>
      </Card>
      {showInfoDialog && (
        <InfoDialog
          onChange={onChange}
          preferredRetailer={preferredRetailer}
          setShowInfoDialog={setShowInfoDialog}
          testID={testID}
        />
      )}
    </>
  );
};

const InfoDialog = ({
  onChange,
  setShowInfoDialog,
  preferredRetailer,
  testID,
}: InfoDialogProps) => {
  const retailerInfoText =
    (preferredRetailer.email && preferredRetailer.telephone)
      ? `${preferredRetailer.email} • ${formatPhoneNumber(preferredRetailer.telephone)}`
      : preferredRetailer.email ?? formatPhoneNumber(preferredRetailer.telephone ?? '');

  const [
    showRemoveRetailerConfirmationModal,
    setShowRemoveRetailerConfirmationModal,
  ] = useState(false);

  return (
    <Dialog
      cancelButton={(props) => (
        <Button
          onClick={() => setShowInfoDialog(false)}
          {...props}
        >
          Close
        </Button>
      )}
      dialogWidth='343px'
      fullWidth={false}
      onClose={() => setShowInfoDialog(false)}
      open
      showCloseButton={false}
      showTitle={false}
      testID={`${testID}-info-dialog`}
    >
      <Stack alignItems="center">
        <Verified color="success" sx={styles.icon} />
        <VSpacer size="3" />
        <Text category="headline-small">
          Preferred Retailers
        </Text>
        <VSpacer size="5" />
        <Text category="body-medium">
        Preferred retailers are able to view the most competitive offer for each product
        once all retailer offers have been received. They also have an additional small
        window of time to submit their best offer.
        </Text>
        <VSpacer size="8" />
        <Card
          cardContentStyle={{ padding: '20px !important' }}
          sx={styles.retailerCard}
          testID={`preferred-card-detail`}
          variant="outlined"
        >
          <Stack>
            <TextElement
              overline="Your Preferred Retailer"
              subline={retailerInfoText ?? ''}
              testID={`${testID}-retailer-info-text`}
              title={preferredRetailer.businessName ?? ''}
            />
            <VSpacer size="5" />
            <Divider />
            <VSpacer size="5" />
            <TextLink
              category="body-small"
              onClick={() => setShowRemoveRetailerConfirmationModal(true)}
              testID={`${testID}-remove-link`}
            >
              Remove preferred retailer from my account
            </TextLink>
          </Stack>
        </Card>
        <Dialog
          acceptButton={(props) => (
            <Button
              onClick={() => {
                onChange();
                setShowRemoveRetailerConfirmationModal(false);
                setShowInfoDialog(false);
              }}
              variant="text"
              {...props}
            >
              Confirm
            </Button>
          )}
          cancelButton={(props) => (
            <Button
              onClick={() => setShowRemoveRetailerConfirmationModal(false)}
              variant="text"
              {...props}
            >
              Cancel
            </Button>
          )}
          dialogWidth="343px"
          onClose={() => setShowRemoveRetailerConfirmationModal(false)}
          open={showRemoveRetailerConfirmationModal}
          showCloseButton={false}
          testID={`${testID}-remove-confirmation-dialog`}
          title="Remove Preferred Retailer from your account?"
        >
          <Stack justifyContent="center">
            <Text
              category="body-medium"
              testID={`${testID}-info-text`}
            >
              This retailer will no longer display on any future product requests
              you create.
            </Text>
          </Stack>
        </Dialog>
      </Stack>
    </Dialog>
  );
};
