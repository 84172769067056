import { Badge as MuiBadge, BadgeProps } from '@mui/material';
import { ReactNode } from 'react';

interface CornerBadgeProps extends BadgeProps {
  children: ReactNode,
  content?: string | number,
  invisible?: boolean,
  testID: string,
}

export const Badge = ({
  children,
  content,
  invisible,
  testID,
  ...rest
}: CornerBadgeProps) => {

  return (
    <MuiBadge
      badgeContent={content}
      data-testid={testID}
      invisible={invisible}
      variant={content === undefined ? 'dot' : 'standard'}
      {...rest}
    >
      {children}
    </MuiBadge>
  );
};
