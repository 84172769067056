import { Text } from "@/components/DesignSystem";
import { ApiUser } from "@api/interfaces";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Stack } from "@mui/material";
import { useState } from "react";
import AdminDetails from './AdminDetails';

interface AdminListItemProps {
  admin: ApiUser,
}
const AdminListItem = ({ admin }: AdminListItemProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion disableGutters expanded={expanded} sx={{ padding: '28px' }}>
      <AccordionSummary
        expandIcon={
          <ExpandMore onClick={
            () => setExpanded(!expanded)}
          />
        }
        sx={{ padding: '0px' }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Text category="h6">
            {admin.firstName} {admin.lastName}
          </Text>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {expanded && <AdminDetails admin={admin} />}
      </AccordionDetails>
    </Accordion>
  );
};

export default AdminListItem;
