import { Text, TextLink, VSpacer } from '@/components/DesignSystem';
import { Stack, Grid } from '@mui/material';
import Square from '@mui/icons-material/Square';
import { DemoBlock } from '../../components/DemoBlock';
import { Category } from '@/themes/variant-interfaces/Category';
import { Fragment } from 'react';

type TextLinkType = {
  title: string,
  category: Category,
};

const textLink: TextLinkType[] = [
  {
    title: 'Small',
    category: 'body-small',
  },
  {
    title: 'Medium',
    category: 'body-medium',
  },
  {
    title: 'Large',
    category: 'body-large',
  },
];

export const TextLinkDemo = () => {
  return (
    <>
      {textLink.map(({ title, category }) => (
        <Fragment key={title}>
          <DemoBlock label={title}>
            <Grid container spacing={4}>
              <Grid item>
                <Text>Default</Text>
                <VSpacer size="3" />
                <Stack spacing={1}>
                  <TextLink
                    category={category}
                    testID={`text-link-demo-${category}`}
                  >
                    Text link
                  </TextLink>

                  <TextLink
                    accessoryRight={<Square />}
                    category={category}
                    testID={`text-link-demo-${category}-square-accessory-right`}
                  >
                    Text link
                  </TextLink>

                  <TextLink
                    accessoryLeft={<Square />}
                    category={category}
                    testID={`text-link-demo-${category}-square-accessory-right`}
                  >
                    Text link
                  </TextLink>

                  <TextLink
                    accessoryLeft={<Square />}
                    accessoryRight={<Square />}
                    category={category}
                    testID={`text-link-demo-${category}-square-accessory-right`}
                  >
                    Text link
                  </TextLink>
                </Stack>
                <VSpacer size="3" />
              </Grid>

              <Grid item>
                <Text>Disabled</Text>
                <VSpacer size="3" />
                <Stack spacing={1}>
                  <TextLink
                    category={category}
                    disabled
                    testID={`text-link-demo-${category}`}
                  >
                    Text link
                  </TextLink>

                  <TextLink
                    accessoryRight={<Square />}
                    category={category}
                    disabled
                    testID={`text-link-demo-${category}-square-accessory-right`}
                  >
                    Text link
                  </TextLink>

                  <TextLink
                    accessoryLeft={<Square />}
                    category={category}
                    disabled
                    testID={`text-link-demo-${category}-square-accessory-right`}
                  >
                    Text link
                  </TextLink>

                  <TextLink
                    accessoryLeft={<Square />}
                    accessoryRight={<Square />}
                    category={category}
                    disabled
                    testID={`text-link-demo-${category}-square-accessory-right`}
                  >
                    Text link
                  </TextLink>
                </Stack>
              </Grid>
            </Grid>
            <VSpacer size="6" />
          </DemoBlock>
        </Fragment>
      ))}

      <DemoBlock label="Accessory Left">
        <Stack direction="row" spacing={3}>
          <TextLink
            accessoryLeft={<Square />}
            category="body-large"
            testID="text-link-demo-body-large-square-accessory-left"
          >
            Text link
          </TextLink>
          <TextLink
            accessoryLeft={<Square />}
            category="body-large"
            disabled
            testID="text-link-demo-body-large-square-accessory-left"
          >
            Text link
          </TextLink>
        </Stack>
      </DemoBlock>
      <VSpacer size="6" />

      <DemoBlock label="Accessory Right">
        <Stack direction="row" spacing={3}>
          <TextLink
            accessoryRight={<Square />}
            category="body-large"
            testID="text-link-demo-body-large-square-accessory-right"
          >
            Text link
          </TextLink>
          <TextLink
            accessoryRight={<Square />}
            category="body-large"
            disabled
            testID="text-link-demo-body-large-square-accessory-right"
          >
            Text link
          </TextLink>
        </Stack>
      </DemoBlock>
      <VSpacer size="6" />

      <DemoBlock label="Accessory Left and Right">
        <Stack direction="row" spacing={3}>
          <TextLink
            accessoryLeft={<Square />}
            accessoryRight={<Square />}
            category="body-large"
            testID="text-link-demo-body-large-square-accessory-left-right"
          >
            Text link
          </TextLink>
          <TextLink
            accessoryLeft={<Square />}
            accessoryRight={<Square />}
            category="body-large"
            disabled
            testID="text-link-demo-body-large-square-accessory-left-right"
          >
            Text link
          </TextLink>
        </Stack>
      </DemoBlock>
    </>
  );
};

// <DemoBlock label={title}>
//   <Stack alignItems="center" direction="row" spacing={12}>
//     <Text>Default</Text>
//
//     <Text>Disabled</Text>
//   </Stack>
//   <VSpacer size="3" />
//
//   <Stack alignItems="center" direction="row" spacing={12}>
//     <TextLink
//       category={category}
//       testID={`text-link-demo-${category}`}
//     >
//       Text link
//     </TextLink>
//
//     <TextLink
//       category={category}
//       disabled
//       testID={`text-link-demo-${category}`}
//     >
//       Text link
//     </TextLink>
//   </Stack>
//   <VSpacer size="3" />
//
//   <Stack direction="row" spacing={8}>
//     <TextLink
//       accessoryRight={<Square />}
//       category={category}
//       testID={`text-link-demo-${category}-square-accessory-right`}
//     >
//       Text link
//     </TextLink>
//
//     <TextLink
//       accessoryRight={<Square />}
//       category={category}
//       disabled
//       testID={`text-link-demo-${category}-square-accessory-right`}
//     >
//       Text link
//     </TextLink>
//   </Stack>
//   <VSpacer size="3" />
//
//   <Stack direction="row" spacing={8}>
//     <TextLink
//       accessoryLeft={<Square />}
//       category={category}
//       testID={`text-link-demo-${category}-square-accessory-right`}
//     >
//       Text link
//     </TextLink>
//
//     <TextLink
//       accessoryLeft={<Square />}
//       category={category}
//       disabled
//       testID={`text-link-demo-${category}-square-accessory-right`}
//     >
//       Text link
//     </TextLink>
//   </Stack>
//   <VSpacer size="3" />
//
//   <Stack direction="row" spacing={4}>
//     <TextLink
//       accessoryLeft={<Square />}
//       accessoryRight={<Square />}
//       category={category}
//       testID={`text-link-demo-${category}-square-accessory-right`}
//     >
//       Text link
//     </TextLink>
//
//     <TextLink
//       accessoryLeft={<Square />}
//       accessoryRight={<Square />}
//       category={category}
//       disabled
//       testID={`text-link-demo-${category}-square-accessory-right`}
//     >
//       Text link
//     </TextLink>
//   </Stack>
// </DemoBlock>
