import { Button, Card, HSpacer, IconButton, Text, VSpacer } from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { Action } from '@/pages/Admin/CustomNotifications/helper';
import { ApiRetailer } from '@api/interfaces';
import { KeyboardArrowRight } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Store from '@mui/icons-material/Store';
import { Avatar, Stack } from '@mui/material';
import React from 'react';

interface RetailerCardProps {
  addedSalespersonIds: string[],
  onAction: (action: Action) => void,
  onSelectRetailer: (retailer: ApiRetailer) => void,
  retailer: ApiRetailer,
}

export const RetailerCard = ({
  addedSalespersonIds,
  onAction,
  onSelectRetailer,
  retailer,
}: RetailerCardProps) => {
  const imageUrl = retailer?.image && `${AppConfig.staticImageHost}/${retailer.image}`;
  const getSubline = () => {
    const locationsCount = retailer.locationDetails?.length ?? 0;
    const salespersonsCount = getSalespersonsCount();
    let locationsSublineText = `${locationsCount} location`;
    if (locationsCount !== 1) {
      locationsSublineText += 's';
    }
    let salespersonsSublineText = `${salespersonsCount} salesperson`;
    if (salespersonsCount !== 1) {
      salespersonsSublineText += 's';
    }
    return (
      <Stack direction="row">
        <Text category="body-medium">{locationsSublineText}</Text>
        <HSpacer size="2" />
        •
        <HSpacer size="2" />
        <Text category="body-medium">{salespersonsSublineText}</Text>
      </Stack>
    );
  };

  const getAddedSalespersonCount = () => {
    const salespersons = retailer.locationDetails?.reduce((acc: { id: string }[], location) => {
      location.salespersons.forEach((salesperson) => acc.push(salesperson));
      return acc;
    }, []) ?? [];
    return salespersons.filter(
      (salesperson) => addedSalespersonIds.includes(salesperson.id),
    ).length ?? 0;
  };

  const getSalespersonsCount = () => {
    return retailer.locationDetails?.reduce((acc, location) => {
      acc += location.salespersons.length;
      return acc;
    }, 0) ?? 0;
  };

  const addedRecipientsCount = getAddedSalespersonCount();
  const salespersonCount = getSalespersonsCount();
  const showAddAllButton = salespersonCount > 0 && addedRecipientsCount !== salespersonCount;
  const showRemoveAllButton = addedRecipientsCount > 0;
  const showNavigateButton = salespersonCount > 0;

  return (
    <Card testID={`retailer-${retailer.id}-salesperson-recipient-card`}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack direction="row">
          <Avatar
            alt={`${retailer?.name ?? 'retailer'} logo`}
            src={imageUrl ?? undefined}
            sx={{
              bgcolor: '#EBEBEB',
              height: 56,
              width: 56,
            }}
          >
            {imageUrl ? null : (
              <Store sx={{ height: 42, width: 42 }} />
            )}
          </Avatar>
          <HSpacer size="4" />
          <Stack justifyContent="flex-end">
            <Text category="title-small">{retailer.name}</Text>
            <VSpacer size="2" />
            <Text category="body-small">
              {getSubline()}
            </Text>
          </Stack>
        </Stack>
        <Stack alignItems="center" flexDirection="row">
          {showRemoveAllButton && (
            <>
              <Text category="body-medium">
                {addedRecipientsCount} recipient{addedRecipientsCount > 1 ? 's' : ''}
              </Text>
              <HSpacer size="4" />
              <Button
                onClick={() => onAction(Action.RemoveAll)}
                testID={`retailer-${retailer.id}-salesperson-recipient-remove-button`}
                variant="text"
              >
                Remove all
              </Button>
            </>
          )}
          {showRemoveAllButton && showAddAllButton && (
            <HSpacer size="4" />
          )}
          {showAddAllButton && (
            <Button
              onClick={() => onAction(Action.AddAll)}
              startIcon={<AddIcon />}
              testID={`retailer-${retailer.id}-salesperson-recipient-add-button`}
              variant="outlined"
            >
              Add all
            </Button>
          )}
          {showNavigateButton && (
            <>
              <HSpacer size="4" />
              <IconButton
                onClick={() => onSelectRetailer(retailer)}
                testID={`${retailer.id}-location-navigate-button`}
              >
                <KeyboardArrowRight />
              </IconButton>
            </>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
