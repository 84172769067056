import { Button, Text, Toolbar, VSpacer } from '@/components/DesignSystem';
import { Filter, FilterResult } from '@/components/DesignSystem/Toolbar/interfaces';

import { productConstant } from '@/constants/productConstant';
import { QueryKeys } from '@/constants/QueryKeys';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { UseCategoryList, useGetRetailerList, UseManufacturerList } from '@/hooks/useProductQuery';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { ProductApi } from '@/utilities/api/ProductApi';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { RetailerEndpoint } from '@api/endpoints';
import { ApiCategory, ApiManufacturer, ApiProduct } from '@api/interfaces';
import { ApiPromotion } from '@api/interfaces/ApiPromotion';
import AddIcon from '@mui/icons-material/Add';
import { Alert, Container, Pagination, Stack } from '@mui/material';
import { PromotionType } from '@shared/enums/PromotionType';
import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ProductDrawer from './ProductDrawer';
import ProductListItem from './ProductListItem';
import ViewProductDrawer from './ViewProductDrawer';
import { formatDateOnly } from '@shared/utilities';

interface StepperProduct {
  rank: number;
  productDetails: {
    id: string;
    name: string;
  };
}
interface DataItem {
  id: string;
  name: string;
  description: string | null;
  icon: string | null;
  isFeatured: boolean;
  createdAt: string;
  updatedAt: string;
}
const initialProductDataField = {
  activeIngredientsIds: [],
  alternativeProducts: [],
  categoryId: '',
  companionProductsId: [],
  description: '',
  image: '',
  isFeatured: false,
  isRestrictedUse: false,
  keywords: [],
  manufacturer: '',
  name: '',
  packageSize: [],
  productCrops: [],
  productPractices: [],
  productPrimaryNutrients: [],
  productRetailers: [],
  productSubcategories: [],
  purchaseUom: [],
  retailersId: [],
  sellSheet: '',
  subcategoryA1: '',
};

export interface ProductData {
  activeIngredientsIds: string[],
  alternativeProducts: object[],
  categoryId: string,
  companionProducts?: object[],
  description?: string,
  id: string,
  image: string,
  isFeatured: boolean,
  isRestrictedUse: boolean,
  keywords: string[],
  manufacturer: string,
  name: string,
  packageSize: string[],
  productCrops: string[],
  productPractices: string[],
  productPrimaryNutrients?: string[],
  productRetailers: string[],
  productSubcategories: string[],
  purchaseUom: string[],
  retailersId: string[],
  sellSheet: string,
  subcategoryA1?: string,
}

const getAllData =
  (data: (
    ApiCategory[] | ApiManufacturer[] | RetailerEndpoint.RetailerList.Response | undefined)) => {
    const list: { id: string; label: string }[] = [];
    data && data?.map((item: { name: string | null ; id: string }) => {
      list.push({
        id: item.id,
        label: item.name ?? '',
      });
    });
    return list;
  };

const filterData = (
  categoryList?: ApiCategory[],
  partnerRetailerList?: RetailerEndpoint.RetailerList.Response,
  manufacturerList?: ApiManufacturer[],
) => {
  const filters: Filter[] = [
    {
      id: 'sortDesc',
      label: 'Sort By',
      options: [
        { id: 'false', label: 'A-Z', default: false },
        { id: 'true', label: 'Z-A', default: false },
      ],
      selectionMethod: 'single-select',
    },
    {
      id: 'categoryId',
      label: 'Category',
      options: getAllData(categoryList),
      selectionMethod: 'single-select',
    },
    {
      id: 'retailerId',
      label: 'Retailer',
      options: getAllData(partnerRetailerList),
      selectionMethod: 'single-select',
    },
    {
      id: 'manufacturerId',
      label: 'Manufacturer',
      options: getAllData(manufacturerList),
      selectionMethod: 'single-select',
    },
  ];
  return filters;
};

const Product = () => {
  const { internal: userIsInternal } = useAuthentication();
  const navigate = useNavigate();
  const [isAddProductFormVisible, setIsAddProductFormVisible] = useState(false);
  const [isEditProductField, setIsEditProductField] = useState<boolean>(false);
  const [isViewProduct, setIsViewProduct] = useState(false);
  const [errorMessage] = useState('');
  const [getCurrentProductId, setCurrentProductId] = useState<string>('');
  const [productField, setProductField] = useState<any>(
    initialProductDataField,
  );
  const [selectedFilters, setSelectedFilters] = useState<FilterResult>({});
  const { categoryList } = UseCategoryList();
  const { manufacturerList } = UseManufacturerList();
  const { retailerList } = useGetRetailerList();
  const { openSnackbar } = useSnackbar();
  const [noProductRecord, setNoProductRecord] = useState('');
  const [imageChange, setImageChange] = useState(false);
  const [currentPage, setPage] = useState(0);

  const filtersList: Record<string, string | boolean> = Array.from(
    selectedFilters?.selections?.entries() ?? [],
  )
    .map(([filterId, selection]) => {
      return {
        filterId,
        value: Array.from(selection).join(', '),
      };
    })
    .reduce((obj, filter) => {
      obj[filter.filterId] = filter.value;
      return obj;
    }, {} as Record<string, string | boolean>);

  const filters = {
    ...filtersList,
    page: currentPage,
    ...(selectedFilters?.search &&
      selectedFilters?.search?.length > 2 ? { search: selectedFilters.search } : {}),
  };

  const dataHandler = (data: DataItem[] | null | undefined) => {

    return data?.map((item) => item?.id ?? item) || [];
  };

  const stepperDataList = (data: StepperProduct[]) => {
    return data.map((item: StepperProduct) => {
      return {
        id: item.productDetails.id,
        rank: item.rank,
        name: item.productDetails.name,
      };
    });
  };
  const handleClearData = () => {
    setProductField(initialProductDataField);
    setIsViewProduct(false);
  };

  if (!userIsInternal) {
    navigate(Routes.HOMEPAGE);
  }

  const updateProductList = useQueryClient();

  const handleViewProductListDrower = (e: MouseEvent) => {
    e.stopPropagation();
    setIsViewProduct(true);
  };

  const handleEdit = (id: string) => {
    setImageChange(false);
    setCurrentProductId(id);
    getoneDataList(id);
    setIsEditProductField(true);
  };

  const { data: productListData } = useQuery(
    [QueryKeys.GET_PRODUCT, filters],
    () => ProductApi.productListData(filters),
  );
  const {
    mutate: getoneDataList,
    data,
    isLoading,
  } = useMutation(
    [QueryKeys.GET_PRODUCT],
    (id: string) => ProductApi.get(id, true),
    {
      onSuccess: (response) => {
        setProductField(response);
      },
    },
  );

  const productIds = productListData?.data.map(
    (product) => product.id,
  ).filter(Boolean) as string[] ?? [];

  const { data: promotions } = useQuery(
    [QueryKeys.GET_PROMOTIONS, productIds],
    async () => PromotionApi.list({
      isActive: true,
      productIds: productIds,
    }),
    {
      enabled: !!productIds.length,
    },
  );

  const currentDate = formatDateOnly(new Date());

  const getFarmerPromotions = (product: ApiProduct): ApiPromotion[] => {
    return promotions?.data.filter(
      (promotion) => (
        promotion.type === PromotionType.Farmer
        && promotion.productIds.includes(product.id)
        && promotion.startDate <= currentDate && promotion.endDate >= currentDate
      ),
    ) ?? [];
  };

  const getRetailerPromotions = (product: ApiProduct): ApiPromotion[] => {
    return promotions?.data.filter(
      (promotion) => (
        promotion.type === PromotionType.Retailer
        && promotion.productIds.includes(product.id)
        && promotion.startDate <= currentDate && promotion.endDate >= currentDate
      ),
    ) ?? [];
  };

  const handleAddData = () => {
    addProduct();
    setIsAddProductFormVisible(false);
  };
  const handleEditData = () => {
    editProductList();
    setIsAddProductFormVisible(false);
  };
  let updatedSubCategories = productField.productSubcategories;
  if (productField.subcategoryA1) {
    const updatedSubcategoryA1 = {
      id: productField.subcategoryA1,
    };
    updatedSubCategories = [
      ...productField.productSubcategories,
      updatedSubcategoryA1,
    ];
  }
  const { mutate: addProduct } = useMutation(
    () =>
      ProductApi.create({
        name: productField.name,
        categoryId: productField.categoryId,
        manufacturerId: productField.manufacturerId,
        image: productField.image,
        description: '',
        isFeatured: productField.isFeatured,
        purchaseUom: productField.purchaseUom,
        packageSize: productField.packageSize,
        sellSheet: productField.sellSheet,
        keywords: productField.keywords,
        isRestrictedUse: productField.isRestrictedUse,
        retailersIds: dataHandler(productField.productRetailers),
        companionProductsIds: productField.companionProducts,
        alternativeProductsIds: productField.alternativeProducts,
        cropIds: dataHandler(productField.productCrops),
        practicesIds: dataHandler(productField.productPractices),
        activeIngredientsIds: dataHandler(
          productField.activeIngredientsIds,
        ),
        subcategoryIds: dataHandler(updatedSubCategories),
        primaryNutrientIds: dataHandler(
          productField.primaryNutrients,
        ),
      }),
    {
      onError: (error: { code: string; message: string }) => {
        openSnackbar(error.message);
      },
      onSuccess: async () => {
        await updateProductList.invalidateQueries(QueryKeys.GET_PRODUCT);
        setProductField(initialProductDataField);
        openSnackbar('Product Added Successfully');
      },
    },
  );
  const { mutate: editProductList } = useMutation(
    () => {
      const productData = {
        name: productField.name,
        categoryId: productField.categoryId,
        description: '',
        image: productField.image,
        isFeatured: productField.isFeatured,
        purchaseUom: productField.purchaseUom,
        packageSize: productField.packageSize,
        sellSheet: productField.sellSheet,
        keywords: productField.keywords,
        isRestrictedUse: productField.isRestrictedUse,
        retailersIds: dataHandler(
          productField.productRetailers,
        ),
        manufacturerId: productField?.manufacturerId,
        companionProductsIds: stepperDataList(productField.companionProducts),
        alternativeProductsIds: stepperDataList(
          productField.alternativeProducts,
        ),
        cropIds: dataHandler(productField.productCrops),
        practicesIds: dataHandler(
          productField.productPractices,
        ),
        activeIngredientsIds: productField.activeIngredientsIds,
        subcategoryIds: dataHandler(updatedSubCategories),
        primaryNutrientIds: dataHandler(
          productField.primaryNutrients,
        ),
      };

      if (!imageChange) {
        delete productData.image;
      }

      return ProductApi.update(productData, getCurrentProductId);
    },
    {
      onError: (error: { code: string; message: string }) => {
        openSnackbar(error.message);
      },
      onSuccess: async () => {
        await updateProductList.invalidateQueries(QueryKeys.GET_PRODUCT);
        setIsAddProductFormVisible(false);
        setProductField(initialProductDataField);
        openSnackbar('Product Updated Successfully');
      },
    },
  );
  const memoizedFilters = useMemo(
    () => filterData(categoryList, retailerList, manufacturerList),
    [categoryList, retailerList, manufacturerList],
  );

  useEffect(() => {
    if (productListData && productListData?.data?.length <= 0) {
      setNoProductRecord('Searched product not available');
    }
  }, [productListData]);

  const handleOpenAddDrower = () => {
    setIsAddProductFormVisible(true);
    handleClearData();
  };

  return (
    <Container maxWidth='xl'>
      <Stack>
        <VSpacer size='8' />
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
        >
          <Text category='h3'>{productConstant.products}</Text>
          <Button
            onClick={handleOpenAddDrower}
            size='giant'
            startIcon={<AddIcon />}
            testID='manage-category-add-button'
          >
            {productConstant.addProduct}
          </Button>
        </Stack>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
        ></Stack>
        <VSpacer size='10' />
        <Stack alignItems='center' direction='row' justifyContent='flex-start'>
          <Toolbar
            filters={memoizedFilters}
            hideAllFilter={false}
            onChange={setSelectedFilters}
            testID='searchAndFilter'
          />
        </Stack>
        <VSpacer size='8' />
        {!!errorMessage && (
          <>
            <Alert color='error' icon={false}>
              {errorMessage}
            </Alert>
            <VSpacer mobileSize='5' size='8' />
          </>
        )}
        {productListData && productListData?.total > 0
          ? `${productListData?.total} Items`
          : '0 Item'}
        <VSpacer size='5' />
        {productListData?.data &&
          productListData?.data?.map((item) => {
            return (
              <>
                <ProductListItem
                  farmerPromotions={getFarmerPromotions(item)}
                  getoneDataList={getoneDataList}
                  handleEdit={handleEdit}
                  handleViewProductListDrawer={handleViewProductListDrower}
                  productListData={{
                    id: item.id,
                    name: item.name ?? '',
                  }}
                  retailerPromotions={getRetailerPromotions(item)}
                  setIsEditProductField={setIsEditProductField}
                />
                <VSpacer size='3' />
              </>
            );
          })}

        <VSpacer size='3' />
        {productListData && productListData.lastPage !== 0 && (
          <Stack alignItems='center'>
            <Pagination
              count={productListData.lastPage + 1}
              onChange={(event, page) => {
                setPage(page - 1);
              }}
              page={productListData.page + 1}
            />
          </Stack>
        )}
        <VSpacer size='14' />
        <Stack>
          {productListData?.data && productListData?.data.length <= 0 && (
            <Stack sx={{ textAlign: 'center' }}>
              {noProductRecord}
              <VSpacer size='14' />
            </Stack>
          )}
        </Stack>
      </Stack>
      {isEditProductField && productListData && productField && (
        <ProductDrawer
          data={data}
          handleSubmitData={handleEditData}
          isEdit={true}
          isVisible={!isLoading}
          onClose={() => {
            setIsEditProductField(false);
            handleClearData();
          }}
          onSave={() => {
            setIsEditProductField(false);
            handleClearData();
          }}

          productField={productField}
          setImageChange={setImageChange}
          setProductField={setProductField}
        />
      )}

      {isAddProductFormVisible && (
        <ProductDrawer
          data={data}
          handleSubmitData={handleAddData}
          isEdit={false}
          isVisible
          onClose={() => {
            setIsAddProductFormVisible(false);
            handleClearData();
          }}
          onSave={() => setIsAddProductFormVisible(false)}
          productField={productField}
          setImageChange={setImageChange}
          setProductField={setProductField}
        />
      )}
      {isViewProduct && (
        <ViewProductDrawer
          isVisible
          onClose={handleClearData}
          onEdit={handleEdit}
          productId={data?.id}
          // productField={productField}
        />
      )}
    </Container>
  );
};

export default Product;
