import {
  Button,
  Modal,
  ProgressLoader,
  Switch,
  Text,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import DetailSection from '@/components/shared/DetailsSections';
import { RewardDetailsURLConstant, RewardsConstants } from '@/constants/constant';
import { stringifyCount } from '@/constants/HierarchyOfRetailers';
import { QueryKeys } from '@/constants/QueryKeys';
import OfferLicenses from '@/pages/ReceivePlaceOffers/OfferOverview/OfferLicenses';
import { UserApi } from '@/utilities/api/UserApi';
import { ApiUser } from '@api/interfaces';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Alert, Box, Divider, Grid, Stack } from '@mui/material';
import { getFarmerName } from '@shared/utilities';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import PricingRequestListModal from '../PricingRequestListModal';
import { PricingRequestsApi } from "@/utilities/api/PricingRequestsApi";

type FarmerDetailsProps = {
  farmer: ApiUser & {
    lastCreditedInfo?: {
      amount: number;
      date: Date;
    };
  };
};

const FarmerDetails = ({ farmer }: FarmerDetailsProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showPricingRequestListModal, setShowPricingRequestListModal] = useState(false);
  const [showLicensesModal, setShowLicensesModal] = useState(false);

  const queryClient = useQueryClient();

  const {
    applications, applicationId, baseUrl, customers, loyaltyPrograms,
  } = RewardDetailsURLConstant;

  const onTransactionClick = () => {
    window.open(
      `${baseUrl}/${applications}/${applicationId}/${customers}/${farmer?.id}#${loyaltyPrograms}`,
      '_blank',
    );
  };

  const { data: devices, isFetching: isFetchingDevices } = useQuery(
    [QueryKeys.GET_USER_DEVICES, farmer.id],
    () => UserApi.getDevicesForUser(farmer.id), {
      onError: (error: { message: string }) => {
        setErrorMessage(error.message || 'An error has occurred fetching user devices');
      },
    },
  );

  const pricingRequests = useQuery(
    [QueryKeys.GET_PRICING_REQUESTS, farmer.id],
    () => PricingRequestsApi.getPricingRequests({
      userId: farmer.id,
      sortDesc: true,
      sort: 'updatedAt',
    }),
    {
      onError: (error: { message: string }) => {
        setErrorMessage(error.message || 'An error has occurred fetching pricing requests');
      },
    },
  ).data?.data ?? [];

  const onTestUserToggle = async (isChecked: boolean) => {
    await UserApi.update(farmer.id, { isTest: isChecked });
    await queryClient.invalidateQueries([QueryKeys.GET_FARMERS]);
  };

  if (errorMessage) {
    return (
      <Alert color='error' icon={false}>
        {errorMessage}
      </Alert>
    );
  }

  const farmerName = getFarmerName(farmer, true);
  const farmerCityState = farmer.city && farmer.state ? `${farmer.city}, ${farmer.state}` : '';

  const getLastRewardAddedInfo = () => {
    if (!farmer?.lastCreditedInfo) return ' N/A';

    const date = DateTime.fromJSDate(
      farmer.lastCreditedInfo.date,
    ).toFormat('MMM dd, yyyy');

    const points = farmer?.lastCreditedInfo?.amount;

    return (
      <Text color="primary">
        {`${stringifyCount(points, 'Point', 'Points')} on ${date}`}
      </Text>
    );
  };

  const displayDevices = () => {
    if (!devices || devices.length === 0) {
      return (<Text category='body-medium'>
        No registered devices
      </Text>);
    }
    return <Grid columnSpacing={12} container direction='row' rowSpacing={4}>
      {devices.map((device) => (
        <Grid item key={device.id} xs={4}>
          <Stack direction='row' justifyContent='space-between' width='100%'>
            <Text category='body-medium'>
              Device ID {device.deviceId}:
            </Text>
            <Text category='title-small'>
              {device.isNotificationsEnabled ? 'Enabled' : 'Disabled'}
            </Text>
          </Stack>
        </Grid>
      ))}
    </Grid>;
  };

  return (
    <>
      <Grid alignItems='start' container direction='row' spacing={4}>
        <Grid item md={4}>
          <Stack>
            <DetailSection details={farmerName} label='Name' testID='farmer-name' />
            <DetailSection details={farmer.email} label='Email' testID='farmer-email' />
            <DetailSection details={farmer.telephone} label='Phone' testID='farmer-phone' />
            <DetailSection
              details={farmer.updatedAt.toLocaleDateString()}
              label='Updated'
              testID='farmer-updated'
            />
            <VSpacer size="3" />
            <Switch
              checked={farmer.isTest}
              key={farmer.id}
              onChangeChecked={onTestUserToggle}
              testID={`${farmer.id}-test-user-switch`}
            >
              Test User
            </Switch>
          </Stack>
        </Grid>
        <Grid item md={4}>
          <Stack>
            <DetailSection details={farmer.address1} label='Address1' testID='farmer-address1' />
            <DetailSection details={farmer.address2} label='Address2' testID='farmer-address2' />
            <DetailSection details={farmer.city} label='City' testID='farmer-city' />
            <DetailSection details={farmer.state} label='State' testID='farmer-state' />
            <DetailSection details={farmer.postal} label='Zip' testID='farmer-zip' />
          </Stack>
        </Grid>
        <Grid item md={4}>
          <Stack>
            <Stack alignItems='center' direction='row'>
              {pricingRequests.length > 0 &&
                <>
                  <DetailSection details={pricingRequests.length}
                                 label='Product requests' testID='farmer-pricing-requests'/>
                  <InfoOutlined
                    fontSize='small'
                    onClick={() => setShowPricingRequestListModal(true)}
                    sx={{
                      cursor: 'pointer',
                    }}
                  />
                </>
              }
            </Stack>
            <DetailSection details='View licenses'
            detailsOnClick={() => setShowLicensesModal(true)}
            label='License numbers' testID='farmer-license-numbers' />
            <Stack ml='8px' mt='15px' spacing={3}>
              <Text>
                {RewardsConstants?.rewardsLastCreditedOnLabel}
                {getLastRewardAddedInfo()}
              </Text>
              <TextLink onClick={onTransactionClick} testID='navigate-transaction'>
                Transactions
              </TextLink>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <VSpacer size="5"/>
      <Divider/>
      <Box>
        <VSpacer size='5'/>
        <Text category='title-medium'>Push notification status</Text>
        <VSpacer size='5'/>
        {isFetchingDevices
          ? <ProgressLoader type="circular"/>
          : displayDevices()
        }
      </Box>
      {showPricingRequestListModal &&
      <PricingRequestListModal
      farmer={farmer}
      onClose={() =>
        setShowPricingRequestListModal(false)}
      pricingRequests={pricingRequests} />}
      {showLicensesModal && (
        <Modal
          cancelButton={(props) => (
            <Button
            onClick={() => setShowLicensesModal(false)}
            sx={{ background: 'transparent' }} {...props}>
              Close
            </Button>
          )}
          onClose={() => setShowLicensesModal(false)}
          open
          subline={[farmerName, farmerCityState].filter(Boolean).join(' | ')}
          testID='farmer-license-modal'
          title='License numbers'
        >
          <OfferLicenses user={farmer} />
        </Modal>
      )}
    </>
  );
};

export default FarmerDetails;
