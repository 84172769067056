import { QueryKeys } from '@/constants/QueryKeys';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { UserApi } from '@/utilities/api/UserApi';
import { UserEndpoint } from '@api/endpoints';
import { useQuery, useQueryClient } from 'react-query';

export const useUser = (query?: UserEndpoint.List.Query, usePost?: boolean, enabled = true) => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();

  const invalidateUsers = async () => {
    await queryClient.invalidateQueries([QueryKeys.GET_USERS]);
  };

  const { data, isFetched, isFetching } = useQuery(
    [QueryKeys.GET_FARMERS, query],
    () => UserApi.list(query, usePost),
    {
      enabled,
      onError: (error: { message: string }) => {
        openSnackbar(error.message || "An error has occurred");
      },
    },
  );

  return {
    invalidateUsers,
    isFetched,
    isFetching,
    users: data,
  };
};

