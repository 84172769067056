import { Container } from '@mui/material';
import { ContainerProps } from '@mui/system';
import { DesktopOnly } from './DesktopOnly';
import { MobileOnly } from './MobileOnly';

export const InnerContainer = (props: ContainerProps) => {
  return (
    <>
      <MobileOnly>
        {props.children}
      </MobileOnly>
      <DesktopOnly>
        <Container {...props}>
            {props.children}
        </Container>
      </DesktopOnly>
    </>
  );
};