import {
  ServiceUnitOfMeasure,
  DryFormulationUnitOfMeasure,
  LiquidFormulationUnitOfMeasure,
  SeedApplicationUnitOfMeasure,
  SeedProductUnitOfMeasure,
} from "@shared/enums";

export const FriendlyUoM = {
  [SeedProductUnitOfMeasure.BAG]: "bag",
  [SeedProductUnitOfMeasure.BUSHEL]: "bu",
  [SeedProductUnitOfMeasure.POUND]: "lb",

  [SeedApplicationUnitOfMeasure.KSDS]: "ksds",
  [SeedApplicationUnitOfMeasure.LBS]: "lbs",
  [SeedApplicationUnitOfMeasure.MSDS]: "msds",

  [LiquidFormulationUnitOfMeasure.FLUID_OUNCE]: 'fl oz',
  [LiquidFormulationUnitOfMeasure.GALLON]: 'gal',
  [LiquidFormulationUnitOfMeasure.PINT]: 'pt',
  [LiquidFormulationUnitOfMeasure.QUART]: 'qt',
  [LiquidFormulationUnitOfMeasure.TOTE]: 'tote',

  [DryFormulationUnitOfMeasure.OUNCE]: 'oz',
  [DryFormulationUnitOfMeasure.POUND]: 'lb',
  [DryFormulationUnitOfMeasure.TON]: 'ton',

  [ServiceUnitOfMeasure.ACRE]: 'ac',
};
