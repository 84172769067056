import { MenuItem } from '@mui/material';
import { State } from '@shared/enums';
import { Select } from '../../DesignSystem';
import { SelectProps } from '../../DesignSystem/Select/Select';

interface StateSelectProps extends SelectProps {
  onChangeState: (state: State) => void,
  required?: boolean,
  value?: State | null,
}

export const StateSelect = ({ 
  onChangeState,
  required = false,
  ...rest
}: StateSelectProps) => {
  
  return (
    <Select 
      label="State" 
      onChangeValue={(state) => onChangeState(state as State)} 
      required={required}
      {...rest}>
      {Object.entries(State).map((s) => (
        <MenuItem key={s[0]} value={s[1]}>
          {s[1]}
        </MenuItem>
      ))}
    </Select>
  );
};