import { ProgressLoader } from '@/components/DesignSystem';
import { Stack } from '@mui/material';
import { DemoBlock } from '../../components/DemoBlock';

export const ProgressLoaderDemo = () => (
  <Stack>
    <DemoBlock label="Circular Progress Loader">
      <ProgressLoader type="circular" />
    </DemoBlock>
    <DemoBlock label="Linear Progress Loader">
      <ProgressLoader type="linear" />
    </DemoBlock>
  </Stack>
);
