import { ToastMessages } from '@/constants/constant';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSearch } from '@/hooks/useSearch';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { FarmerLocationSettingApi } from '@/utilities/api/FarmerLocationSettingApi';
import { RetailerLocationApi } from '@/utilities/api/RetailerLocationApi';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const useViewFarmers = (id: string) => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();
  const [showSearch, setShowSearch] = useState(false);
  const [deleteFarmerId, setDeleteFarmerId] = useState('');

  const { search, setSearch, debouncedSearch } = useSearch();
  const { data: allFarmersData } = useQuery(
    [QueryKeys.GET_RETAILER_FARMERS_BY_ID, debouncedSearch],
    () => RetailerLocationApi.getFarmersForLocation(id, debouncedSearch, true),
    {
      onSuccess: (response) => {
        if (response?.total || debouncedSearch) {
          return setShowSearch(true);
        }
        return setShowSearch(false);
      },
    },
  );

  const { mutate: deleteFarmer, isLoading: isDeleteFarmerLoading } = useMutation(
    ({ userId, locationId }: { userId: string, locationId: string }) => (
      FarmerLocationSettingApi.delete(userId, locationId)
    ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          QueryKeys.GET_RETAILER_FARMERS_BY_ID,
        ]);
        openSnackbar(ToastMessages.farmersDeleteSuccess);
      },
      onError: (error: DetailedApiError) => {
        if (error?.code === '500') {
          return openSnackbar('Something went wrong');
        }
        return openSnackbar(error?.message);
      },
    });

  return {
    search,
    setSearch,
    debouncedSearch,
    setDeleteFarmerId,
    allFarmersData,
    showSearch,
    deleteFarmer,
    isDeleteFarmerLoading,
    deleteFarmerId,
  };
};
