import { Stack, Popover, Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { Button, HSpacer, IconButton, Input } from '@/components/DesignSystem';
import './DatePickerStyles.css';
import Today from '@mui/icons-material/Today';
import { DatePickerCalendar } from './DatePickerCalendar';

export interface DatePickerDropdownProps {
  disabled?: boolean,
  errorMessage?: string,
  label: string,
  onChange: (value?: Date) => void,
  testID: string,
  value?: Date,
  width?: number,
}

export const DatePickerDropdown = ({
  disabled,
  errorMessage,
  label,
  onChange,
  testID,
  value,
  width,
}: DatePickerDropdownProps) => {

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [internalValue, setInternalValue] = useState(value);
  const [showFooter, setShowFooter] = useState(true);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOk = () => {
    onChange(internalValue);
    handleClose();
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const formattedValue = useMemo(() => {
    if (!value) {
      return "";
    }
    return DateTime.fromJSDate(value).toFormat("MM/dd/yyyy");
  }, [value]);

  return (
    <>
      <Input
        disabled={disabled}
        error={!!errorMessage}
        helperText={errorMessage ?? 'MM/DD/YYYY'}
        label={label}
        onClick={disabled ? undefined : (e) => {
          setAnchorEl((e as any).currentTarget);
        }}
        testID={`${testID}-input`}
        trailingIcon={disabled ? undefined : () => (
          <Box mr={0.8}>
            <IconButton testID={`${testID}-calendar-button`}>
              <Today />
            </IconButton>
          </Box>
        )}
        value={formattedValue}
        variant="outlined"
        width={width}
      />
      <Popover
        PaperProps={{
          sx: {
            borderRadius: "16px",
            width: "360px",
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
        open={open}
        sx={{
          marginTop: "7px",
        }}
      >
        <DatePickerCalendar
          internalStartDate={internalValue}
          onChange={(date) => setInternalValue(date)}
          onShowMonthPicker={(show) => setShowFooter(!show)}
          onShowYearPicker={(show) => setShowFooter(!show)}
          testID={testID}
          variant="dropdown"
        />
        {showFooter && (
          <Stack
            alignItems="center"
            direction="row"
            height={52}
            justifyContent="flex-end"
            pb={1}
            px={1.5}
          >
            <Button
              onClick={handleClose}
              testID={`${testID}-cancel-button`}
              variant="text"
            >
              Cancel
            </Button>
            <HSpacer size="2" />
            <Button onClick={handleOk}
              testID={`${testID}-ok-button`}
              variant="text"
            >
              OK
            </Button>
          </Stack>
        )}
      </Popover>
    </>
  );
};
