import { HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { PromotionsSideSheet } from '@/pages/Admin/PromotionsSideSheet';
import { ApiPricingRequestProduct, ApiPromotion, RecommendationProduct } from '@api/interfaces';
import { ApiBundleProduct } from '@api/interfaces/ApiBundleProduct';
import { Chip, Divider, Stack } from '@mui/material';
import { PromotionType } from '@shared/enums/PromotionType';
import { formatCurrency } from '@shared/utilities';
import React, { useState } from 'react';

interface ProductCardProps {
  farmerPromotions: ApiPromotion[],
  product: ApiPricingRequestProduct | RecommendationProduct | ApiBundleProduct,
  retailerPromotions: ApiPromotion[],
  showPrices?: boolean,
}

export const ProductCard = ({
  farmerPromotions,
  product,
  retailerPromotions,
  showPrices,
}: ProductCardProps) => {
  const [showFarmerPromotions, setShowFarmerPromotions] = useState(false);
  const [showRetailerPromotions, setShowRetailerPromotions] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<string | undefined>();

  let price;
  if ('price' in product) {
    price = product.price;
  } else if ('pricePerUnit' in product) {
    price = product.pricePerUnit;
  }
  return (
    <>
      <Stack sx={{ padding: '20px 0' }}>
        <Stack direction="row" justifyContent="space-between" pr="12px">
          <Text fontSize='20px' testID={`${product.id} name`}>
            {product.name}
          </Text>
          {showPrices && !!product.quantity && !!price && (
            <Text fontSize='20px' testID={`${product.id}-price`}>
              Total: {formatCurrency(product.quantity * price)}
            </Text>
          )}
        </Stack>
        <VSpacer size='5' />
        <Text category='overline' testID={`${product.id} quantity`}>
          {product.quantity} {product.uom}{product.package ? ` • ${product.package}` : ''}
        </Text>
        {(!!farmerPromotions.length || !!retailerPromotions.length) && !!product.productId && (
          <>
            <VSpacer size="3" />
            <Stack direction="row">
              {!!farmerPromotions.length && (
                <>
                  <Chip
                    color="warning"
                    label="Farmer Rewards"
                    onClick={() => {
                      setSelectedProductId(product.productId!);
                      setShowFarmerPromotions(true);
                    }}
                  />
                  <HSpacer size="4" />
                </>
              )}
              {!!retailerPromotions.length && (
                <Chip
                  color="info"
                  label="Active Sales Incentives"
                  onClick={() => {
                    setSelectedProductId(product.productId!);
                    setShowRetailerPromotions(true);
                  }}
                />
              )}
            </Stack>
          </>
        )}
      </Stack>
      <Divider />
      {(showFarmerPromotions || showRetailerPromotions) && !!selectedProductId && (
        <PromotionsSideSheet
          onClose={() => {
            setShowFarmerPromotions(false);
            setShowRetailerPromotions(false);
            setSelectedProductId(undefined);
          }}
          promotionType={showFarmerPromotions ? PromotionType.Farmer : PromotionType.Retailer}
          promotions={showFarmerPromotions ? farmerPromotions : retailerPromotions}
        />
      )}
    </>
  );
};
