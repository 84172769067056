import { Button, IconButton, VSpacer } from '@/components/DesignSystem';
import { ProductCard } from '@/components/DesignSystem/ProductCard/ProductCard';
import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { CreateOfferEventType, useLogEvent } from '@/utilities/Analytics';
import { ApiOfferProduct } from '@api/interfaces';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import { Box, Divider, Stack } from '@mui/material';
import { useState } from 'react';
import { OfferProduct } from '../CreateSendPriceRequest/interfaces';
import { LastLookData } from '../ReceivePlaceOffers/interfaces';

interface ProductListProps {
  lastLookData?: LastLookData,
  offerProducts?: ApiOfferProduct[],
  onAddEditPrice?: (product: OfferProduct) => void,
  products?: OfferProduct[],
  variant: 'createOffer' | 'viewProductRequest',
}

export const ProductListDesktop = ({
  lastLookData,
  offerProducts,
  onAddEditPrice,
  products = [],
  variant,
}: ProductListProps) => {
  return (
    <Stack
      divider={
        <>
          {variant === 'createOffer' && (
            <Divider />
          )}
          {variant === 'viewProductRequest' && (
            <VSpacer size="4" />
          )}
        </>
      }
    >
      {products.map((product, index) => (
        <ProductListCard
          key={index}
          lastLookData={lastLookData}
          offerProducts={offerProducts}
          onAddEditPrice={onAddEditPrice}
          product={product}
          testID={`view-price-request-product-card-${index}`}
          variant={variant}
        />
      ))}
    </Stack>
  );
};

export const ProductListMobile = ({
  lastLookData,
  offerProducts,
  onAddEditPrice,
  products = [],
  variant,
}: ProductListProps) => {
  return (
    <>
      {products.map((product, index) => (
        <Box key={product.id} position="relative">
          <ProductListCard
            lastLookData={lastLookData}
            offerProducts={offerProducts}
            onAddEditPrice={onAddEditPrice}
            product={product}
            testID={`view-price-request-product-card-${index}`}
            variant={variant}
          />
          <Divider />
        </Box>
      ))}
    </>
  );
};

interface ProductCardProps {
  lastLookData?: LastLookData,
  offerProducts?: ApiOfferProduct[],
  onAddEditPrice?: (product: OfferProduct) => void,
  product: OfferProduct,
  testID: string,
  variant: 'createOffer' | 'viewProductRequest',
}

const ProductListCard = ({
  lastLookData,
  offerProducts,
  onAddEditPrice,
  product,
  testID,
  variant,
}: ProductCardProps) => {
  const logEvent = useLogEvent();
  const [expandedCompanionProduct, setExpandedCompanionProduct] = useState(false);

  return (
    <Box
      sx={{
        overflowX: "hidden",
        overflowY: "auto",
        padding: variant === 'createOffer'
          ? getResponsiveValue(
            `18px 16px 28px 16px`,
            `18px 20px 28px 16px`,
          )
          : getResponsiveValue(
            '16px 16px 20px 14px',
            '16px 20px 20px 18px',
          ),
        borderLeftWidth: 2,
        borderLeftStyle: 'solid',
        borderLeftColor: expandedCompanionProduct ? 'warning' : 'transparent',
      }}
    >
      <ProductCard
        companionRightAccessory={(companionProduct) => !!onAddEditPrice && (
          <>
            {!companionProduct.price ? (
              <Button
                onClick={() => {
                  onAddEditPrice(companionProduct);
                  logEvent(CreateOfferEventType.ClickAddProductPrice);
                }}
                startIcon={<Add />}
                testID={`${testID}-add-companion-price`}
                variant="outlined"
              >
                Price
              </Button>
            ) : (
              <IconButton
                onClick={() => {
                  onAddEditPrice(companionProduct);
                  logEvent(CreateOfferEventType.ClickEditProductPrice);
                }}
                testID={`${testID}-edit-companion-price`}
              >
                <Edit />
              </IconButton>
            )}
          </>
        )}
        expandCompanion={expandedCompanionProduct}
        expandCompanionButton={variant === 'createOffer'
          ? (props) => props.showPriceList ? (
            <IconButton onClick={props.onExpand} testID={props.testID}>
              <Edit />
            </IconButton>
          ) : (
            <Button
              onClick={props.onExpand}
              startIcon={<Add />}
              testID={props.testID}
              variant="text"
            >
              Price
            </Button>
          ) : undefined
        }
        lastLookData={lastLookData}
        offerProducts={offerProducts}
        onExpandCompanion={() => setExpandedCompanionProduct(!expandedCompanionProduct)}
        product={product}
        rightAccessory={!!onAddEditPrice && (
          <>
            {!product?.price ? (
              <Button
                onClick={() => {
                  onAddEditPrice(product);
                  logEvent(CreateOfferEventType.ClickAddProductPrice);
                }}
                startIcon={<Add />}
                testID={`${testID}-add-price`}
                variant="outlined"
              >
                Price
              </Button>
            ) : (
              <IconButton
                onClick={() => {
                  onAddEditPrice(product);
                  logEvent(CreateOfferEventType.ClickEditProductPrice);
                }}
                testID={`${testID}-edit-price`}
              >
                <Edit />
              </IconButton>
            )}
          </>
        )}
        substituteProduct={product.substituteProduct}
        testID={testID}
      />
    </Box>
  );
};
