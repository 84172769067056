import { Client } from '@/utilities/api/Client';
import { QueryUtil } from '@/utilities/Query';
import { PaymentEndpoint } from '@api/endpoints';
import { PaymentMethodNew } from '@shared/enums/PaymentMethod';

export class PaymentApi {
  static async createFarmerPaymentIntent (
    pricingRequestId: string,
    body: PaymentEndpoint.CreateFarmerPaymentIntent.Request,
  ): Promise<PaymentEndpoint.CreateFarmerPaymentIntent.Response> {
    return Client(`payments/${pricingRequestId}/farmer-payment-intent`, {
      method: 'POST',
      body,
    });
  }

  static async farmerPaymentTransfer   (
    pricingRequestId: string,
    fetchStatus = false,
  ): Promise<PaymentEndpoint.PaymentTransfer.Response> {
    return Client(`payments/${pricingRequestId}/farmer-payment-transfer`, {
      method: 'POST',
      body: { fetchStatus },
    });
  }

  static async getPaymentFee (
    totalAmount: number,
  ): Promise<PaymentEndpoint.Fee.Response> {
    return Client('payments/fee', {
      method: 'POST',
      body: { totalAmount },
    });
  }

  static async requestPayment (
    pricingRequestId: string,
    paymentMethod: PaymentMethodNew,
  ): Promise<void> {
    return Client(`payments/${pricingRequestId}/request-payment`, {
      method: 'POST',
      body: { paymentMethod },
    });
  }

  static async syncFarmerPayment (id: string) {
    return Client(`payments/${id}/sync-farmer-payment`, { method: 'POST' });
  }

  static async syncRetailerPayment (
    id: string,
    token?: string,
  ): Promise<void> {
    const query = QueryUtil.stringify({ token });
    return Client(`payments/${id}/sync-retailer-payment?${query}`, { method: 'POST' });
  }
}
