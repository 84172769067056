import { Text } from '@/components/DesignSystem';
import { MobileStepper, MobileStepperProps, Stack } from '@mui/material';
import { VSpacer } from '../Spacer';

export interface ProgressStepperProps extends Partial<MobileStepperProps> {
  heading?: string,
  size?: number,
  subline?: string,
  testID: string,
}

export const ProgressStepper = ({
  heading,
  size = 12,
  steps = 3,
  subline,
  testID,
  ...rest
}: ProgressStepperProps) => {
  return (
    <Stack alignItems="center">
      <MobileStepper
        backButton={<></>}
        data-testid={testID}
        nextButton={<></>}
        position="static"
        steps={steps}
        sx={{
          maxWidth: 400,
          flexGrow: 1,
          ".MuiMobileStepper-dot": {
            width: `${size}px`,
            height: `${size}px`,
          },
        }}
        variant="dots"
        {...rest}
      />
      { !!heading && (
        <>
          <VSpacer size="2" />
          <Text
            category="label-medium"
            testID={`${testID}-heading`}
            upperCase>
            {heading}
          </Text>
        </>
      )}
      { !!subline && (
        <>
          <VSpacer size="4" />
          <Text
            testID={`${testID}-subline`}>
            {subline}
          </Text>
        </>
      )}
    </Stack>
  );
};
