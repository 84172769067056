import {
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
  styled,
} from '@mui/material';
import { useMediaQuery } from "@/hooks/useMediaQuery";

export interface TabsProps extends MuiTabsProps {
  dense?: boolean,
  justifyContent?: 'start' | 'center' | 'end',
  testID: string,
}

const DenseTabs = styled((props: MuiTabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <div className="MuiTabs-indicatorDiv" /> }}
  />
))({
  '& .MuiButtonBase-root': {
    minWidth: "56px",
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: "3px",
  },
  '& .MuiTabs-indicatorDiv': {
    backgroundColor: 'primary',
    borderTopLeftRadius: "100px",
    borderTopRightRadius: "100px",
    maxWidth: "20px",
    width: '100%',
  },
});

export const Tabs = ({
  dense,
  justifyContent = "start",
  testID,
  ...rest
}: TabsProps) => {

  const { isMobile } = useMediaQuery();

  const props = {
    sx: {
      '& .MuiTabs-flexContainer': {
        justifyContent: justifyContent,
      },
    },
    "data-testid": testID,
    variant: isMobile ? "fullWidth" : "standard",
    ...rest,
  } as MuiTabsProps;

  if (dense) {
    return <DenseTabs {...props} />;
  }

  return <MuiTabs {...props} />;
};
