import { Client } from '@/utilities/api/Client';
import { QueryUtil } from '@/utilities/Query';
import { ProductEventEndpoint } from '@api/endpoints/ProductEventEndpoint';

export class ProductEventApi {
  static export (
    query: ProductEventEndpoint.Export.Query,
  ) {
    const queryString = QueryUtil.stringify(query);
    return Client('product-events/export?' + queryString);
  }

  static logEvent (
    body: ProductEventEndpoint.Create.Request,
    query: { token?: string } = {},
  ) {
    let queryString = QueryUtil.stringify(query);
    queryString = queryString && ('?' + queryString);
    return Client('product-events' + queryString, {
      body,
      method: 'POST',
    });
  }
}
