import { PricingRequestCartEndpoint } from '@api/endpoints';
import { Client } from './Client';
import { QueryUtil } from '../Query';

export class ShoppingCartApi {
  static async create (
    request: PricingRequestCartEndpoint.Create.Request,
  ): Promise<PricingRequestCartEndpoint.Create.Response> {
    return Client('pricing-request-cart', { method: 'POST', body: request });
  }

  static async delete (
    id: string,
  ): Promise<void> {
    return Client(`pricing-request-cart/${id}`, { method: 'DELETE' });
  }

  static async list (
    filters: PricingRequestCartEndpoint.ListPricingRequestCart.Query,
  ): Promise<PricingRequestCartEndpoint.ListPricingRequestCart.Response> {
    const query = QueryUtil.stringify(filters);
    return Client(`pricing-request-cart?${query}`);
  }

  static async update (
    id: string,
    request: PricingRequestCartEndpoint.UpdatePricingRequestCart.Request,
  ): Promise<PricingRequestCartEndpoint.Create.Response> {
    return Client(`pricing-request-cart/${id}`, { method: 'PATCH', body: request });
  }

  static async clear (): Promise<void> {
    return Client(`pricing-request-cart/clear`, { method: 'DELETE' });
  }
}
