import { RequireAtLeastOne } from "@/utilities/TypeUtils";
import { ReactNode } from "react";
import { DataPointProps } from "../DataPoint/DataPoint";
import { DataSet } from "../DataSet/DataSet";

interface PriceRequestRetailerFields {
  actions: ReactNode[],
  name: string,
  email?: string,
  phone?: string,
  testID: string,
}

type PriceRequestRetailerCardProps = RequireAtLeastOne<
  PriceRequestRetailerFields, 'email' | 'phone'
>

export const PriceRequestRetailerCard = ({
  actions,
  email,
  name,
  phone,
  testID,
}: PriceRequestRetailerCardProps) => {
  
  const metadata: DataPointProps[] = [
    {
      children: email,
      label: "Email:",
      padding: "4px 0px",
      spacing: "flushed",
      testID: `${testID}-email-retailer-card`,
    },
    {
      children: phone,
      label: "Phone:",
      padding: "4px 0px",
      spacing: "flushed",
      testID: `${testID}-phone-retailer-card`,
    },
  ];
  
  return (
    <DataSet
      actions={actions}
      metadata={metadata}
      testID={testID}
      title={name}
    />
  );
};
