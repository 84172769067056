import { SharedConfig } from '@shared/SharedConfig';

export interface OfferWindowItem {
  days: number,
  toString: () => string,
}

export const offerWindowItems: OfferWindowItem[] = Array.from(
  Array(SharedConfig.settings.pricingRequestMaxExpirationDays).keys(),
).map(x => x + 1).filter(
  x => x >= SharedConfig.settings.pricingRequestMinExpirationDays,
).map(x => ({
  days: x, 
  toString: () => `${x} day${x === 1 ? '' : 's'}`,
}));
