import { Chip, ChipProps } from '@/components/DesignSystem';
import {
  PricingRequestPaymentStatus,
  PricingRequestStatus,
  PricingRequestStatusNew,
} from '@shared/enums';
import {
  getStatusChipPropsFromPRStatus,
  getStatusChipPropsFromPRStatusNew,
} from '@/utilities/PricingRequest';

interface PricingRequestStatusChipProps {
  status: PricingRequestStatus | PricingRequestStatusNew,
  orderCompletedOn?: Date | null,
  paymentStatus: PricingRequestPaymentStatus | null,
}

const PricingRequestStatusChip = ({
  status,
  orderCompletedOn,
  paymentStatus,
}: PricingRequestStatusChipProps) => {
  let chipProps: ChipProps;
  const isPricingRequestStatus = Object.values(PricingRequestStatus)
    .includes(status as PricingRequestStatus);
  if (isPricingRequestStatus) {
    chipProps = getStatusChipPropsFromPRStatus(status as PricingRequestStatus);
    if (status === PricingRequestStatus.Closed && !orderCompletedOn) {
      chipProps.label = 'Offer Expired';
    }
  } else {
    if (status === PricingRequestStatusNew.PaymentDue && (
      paymentStatus === PricingRequestPaymentStatus.PAYMENT_INITIATED_PROCESSING
        || paymentStatus === PricingRequestPaymentStatus.PAYMENT_INITIATED
    )) {
      chipProps = {
        color: 'info',
        label: PricingRequestPaymentStatus.PAYMENT_INITIATED_PROCESSING,
        testID: 'pricing-request-chip-status',
      };
    } else {
      chipProps = getStatusChipPropsFromPRStatusNew(status as PricingRequestStatusNew);
    }
  }

  return (
    <Chip
      color={chipProps?.color}
      label={chipProps?.label ?? ''}
      testID={chipProps?.testID ?? 'chip'}
    />
  );
};

export default PricingRequestStatusChip;
