import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const IDTypography = styled(Typography)`
color: var(--growers-sys-dark-medium-emphasis, #C9C6BE);
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 16px;
letter-spacing: 1.25px;
text-transform: uppercase;
`;

export const NameTypography = styled(Typography)`
color: #FFFEFB;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.5px;
text-transform: capitalize;
`;

export const LocationMemberTypography = styled(Typography)`
color: var(--growers-sys-dark-medium-emphasis, #C9C6BE);
font-family: Roboto;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.25px;
`;

export const IsPreferredTypography = styled(Typography)`
color: var(--growers-sys-dark-on-surface, #FFFEFB);
font-family: Roboto;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.25px;
`;

interface CustomTypographyProps {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  letterSpacing?: string;
  textTransform?: string;
}

export const CustomTypography = styled(Typography)`
  color: ${(props: CustomTypographyProps) => props.color} || #FFFEFB;
  font-family: Roboto;
  font-size: ${(props: CustomTypographyProps) => props.fontSize} || 16px;
  font-style: normal;
  font-weight: ${(props: CustomTypographyProps) => props.fontWeight} || 400;
  line-height: ${(props: CustomTypographyProps) => props.lineHeight} || 24px;
  letter-spacing: ${(props: CustomTypographyProps) => props.letterSpacing} || 0.5px;
  text-transform: ${(props: CustomTypographyProps) => props.textTransform} || none;
`;