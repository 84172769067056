import {
  Button,
  DataPoint,
  Text,
  TextAreaInput,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { getResponsiveValue, useMediaQuery } from '@/hooks/useMediaQuery';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { FeedbackApi } from '@/utilities/api/FeedbackApi';
import { GrowersContactInfo } from '@shared/enums';
import { formatPhoneNumber } from '@shared/utilities';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useMutation } from 'react-query';
import { ModalBottomSheet } from './ModalBottomSheet/ModalBottomSheet';

export interface SubmitFeedbackModalProps {
  onClose: () => void,
  open: boolean,
}

export const SubmitFeedbackModal = ({
  onClose,
  open,
}: SubmitFeedbackModalProps)  => {
  const [captchaToken, setCaptchaToken] = useState("");
  const [feedback, setFeedback] = useState("");
  const { isMobile } = useMediaQuery();
  const { openSnackbar } = useSnackbar();

  const { isLoading: isSubmitting, mutate: submitFeedback } = useMutation(
    () => FeedbackApi.submitFeedback({
      feedback,
      token: captchaToken,
    }),
    {
      onSuccess: async () => {
        setCaptchaToken('');
        setFeedback('');
        openSnackbar('Thank you for your feedback.');
        onClose();
      },
      onError: (error: { message: string }) => {
        openSnackbar(
          error.message || 'Error submitting feedback',
        );
      },
    },
  );

  const isFeedbackValid = !isEmpty(feedback) && !!captchaToken && feedback.length <= 1000;

  return (
    <ModalBottomSheet
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!isFeedbackValid}
          loading={isSubmitting}
          onClick={() => submitFeedback()}
          testID="submit-feedback-button"
        >
          Submit
        </Button>
      )}
      closeButton={(
        <TextLink
          category="label-large"
          onClick={onClose}
          pl="14px"
          testID="submit-feedback-cancel-button"
        >
          Cancel
        </TextLink>
      )}
      closeButtonLabel="Cancel"
      containerStyle={{
        px: getResponsiveValue(2, 0),
      }}
      hideScrollbarOnDesktop
      mobileTitle=""
      onClose={() => {
        setFeedback("");
        setCaptchaToken("");
        onClose();
      }}
      open={open}
      showPuller
      showTopButton={false}
      testID="submit-feedback-modal"
      title="Help"
      topButtonAction={(
        <TextLink
          category="label-large"
          disabled={!isFeedbackValid}
          onClick={submitFeedback}
          pr="8px"
          testID="submit-feedback-button"
        >
          Submit
        </TextLink>
      )}
      width={360}
    >
      <Text category="title-small">Contact Us</Text>
      <VSpacer size="2" />
      <DataPoint label="Email" testID="feedback-email">
        <TextLink
          category="p2"
          href={`mailto:${GrowersContactInfo.supportEmail}`}
          testID="feedback-modal-support-email-link"
        >
          {GrowersContactInfo.supportEmail}
        </TextLink>
      </DataPoint>
      <DataPoint label="Phone" testID="feedback-email">
        <TextLink
          category="p2"
          href={`tel:${GrowersContactInfo.supportPhone}`}
          testID="feedback-modal-support-phone-link"
        >
          {formatPhoneNumber(GrowersContactInfo.supportPhone, 'paren')}
        </TextLink>
      </DataPoint>
      <VSpacer size="2" />
      <Text category="title-small">Submit feedback</Text>
      <VSpacer size="2" />
      <Text
        category={isMobile ? "body-small" : "body-medium"}
      >
        Your feedback helps us improve Growers.
      </Text>
      <VSpacer size="4" />
      <TextAreaInput
        maxCharacterLimit={1000}
        maxLength={1100}
        onChangeText={setFeedback}
        placeholder="Enter feedback"
        rows={5}
        showCharacterCountdown
        showCharacterLimitMessage
        testID="submit-feedback-input"
        value={feedback}
      />
      <VSpacer size="5" />
      <ReCAPTCHA
        onChange={(token) => {
          setCaptchaToken(token || "");
        }}
        sitekey={AppConfig.recaptchaSiteKey}
      />
    </ModalBottomSheet>
  );
};
