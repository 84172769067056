import { Button, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import {
  SalespersonRecipientCard,
} from '@/pages/Admin/CustomNotifications/SalespersonRecipientCard';
import { ApiRetailer, LocationDetails } from '@api/interfaces';
import AddIcon from '@mui/icons-material/Add';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Stack } from '@mui/material';
import React from 'react';

interface SalespersonListProps {
  location: LocationDetails,
  onBack: () => void,
  onChange: (updatedIds: string[]) => void,
  retailer: ApiRetailer,
  selectedRecipientIds: string[],
}

export const SalespersonList = ({
  location,
  onBack,
  onChange,
  retailer,
  selectedRecipientIds,
}: SalespersonListProps) => {
  const salespersonIds = location.salespersons.map((salesperson) => salesperson.id);

  const addAll = () => {
    const newSelectedRecipients = new Set(selectedRecipientIds);
    salespersonIds.forEach((id) => newSelectedRecipients.add(id));
    onChange(Array.from(newSelectedRecipients));
  };

  const removeAll = () => {
    const newSelectedRecipients = new Set(selectedRecipientIds);
    salespersonIds.forEach((id) => newSelectedRecipients.delete(id));
    onChange(Array.from(newSelectedRecipients));
  };

  const isUserAdded = (id: string) => {
    return selectedRecipientIds.includes(id);
  };

  const showAddAllButton = !salespersonIds.every((id) => selectedRecipientIds.includes(id));
  const showRemoveAllButton = selectedRecipientIds.some((id) => salespersonIds.includes(id));

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack alignItems="center" direction="row">
          <Button
            color="inherit"
            onClick={onBack}
            startIcon={<ArrowBack />}
            testID="location-back-button"
            variant="outlined"
          >
            Back
          </Button>
          <HSpacer size="4" />
          <Text category="title-medium">
            {retailer.name} / {location.name} / Salespersons ({location.salespersons.length})
          </Text>
        </Stack>
        <Stack flexDirection="row" justifyContent="flex-end">
          {showRemoveAllButton && (
            <Button
              onClick={removeAll}
              testID="remove-all-recipients-button"
              variant="text"
            >
              Remove all
            </Button>
          )}
          {showAddAllButton && showRemoveAllButton && (
            <HSpacer size="5" />
          )}
          {showAddAllButton && (
            <Button
              onClick={addAll}
              startIcon={<AddIcon />}
              testID="remove-all-recipients-button"
            >
              Add all
          </Button>
          )}
        </Stack>
      </Stack>
      <VSpacer size="5" />
      {location.salespersons.map((salesperson) => (
        <React.Fragment key={salesperson.id}>
          <SalespersonRecipientCard
            onAction={() => {
              const updatedRecipients = new Set(selectedRecipientIds);
              if (isUserAdded(salesperson.id)) {
                updatedRecipients.delete(salesperson.id);
              } else {
                updatedRecipients.add(salesperson.id);
              }
              onChange(Array.from(updatedRecipients));
            }}
            salesperson={salesperson}
            selectedRecipientIds={selectedRecipientIds}
          />
          <VSpacer size="4" />
        </React.Fragment>
      ))}
    </>
  );
};
