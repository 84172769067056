import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
import { AppConfig } from './constants/AppConfig';
import { initializeGTM } from '@/utilities/Analytics';

initializeGTM(AppConfig.GTM_ID);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <App />,
);

reportWebVitals();
