import { Button, Card, Heading, IconButton } from '@/components/DesignSystem';
import { Mail } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import { DemoBlock } from '../../components/DemoBlock';

const levelsWithOverline: ('1' | '2')[] = ['1', '2'];
const levelsWithoutOverline: ('3' | '4')[] = ['3', '4'];

const actions = [
  (
    <Button
      key="button-1"
      testID="head-demo-button"
    >
      Enabled
    </Button>
  ),
];

const smallActions = [
  (
    <IconButton key="small-button-1" testID="heading-demo-edit-icon">
      <Edit />
    </IconButton>
  ),
  (
    <IconButton key="small-button-1" testID="heading-demo-delete-icon">
      <Delete />
    </IconButton>
  ),
];

export const HeadingDemo = () => (
  <Stack spacing={1}>
    {levelsWithOverline.map((level, index) => (
      <>
        <DemoBlock label={`Level ${level} Center aligned Title`}>
          <Card
            testID={`heading-demo-with-center-aling-overline-card-level-title-${index}`}
          >
            <Heading
              centered={true}
              level={level}
              testID="test-heading-1"
              title="Title"
            />
          </Card>
        </DemoBlock>
        <DemoBlock label={`Level ${level} Center aligned Title/subtitle`}>
          <Card
            testID={`heading-demo-with-center-overline-card-level-subtitle-${index}`}
          >
            <Heading
              centered={true}
              level={level}
              subtitle="Subtitle"
              testID="test-heading-1"
              title="Title"
            />
          </Card>
        </DemoBlock>
        <DemoBlock label={`Level ${level} Center aligned Title/overline`}>
          <Card
            testID={`heading-demo-with-overline-card-level-title-overline-${index}`}
          >
            <Heading
              centered={true}
              level={level}
              overline="overline"
              testID="test-heading-1"
              title="Title"
            />
          </Card>
        </DemoBlock>
        <DemoBlock
          label={`Level ${level} Center aligned Title/subtitle/overline`}
        >
          <Card
            testID={`heading-demo-with-overline-card-level-title-subtitle-overline-${index}`}
          >
            <Heading
              centered={true}
              level={level}
              overline="overline"
              subtitle="Subtitle"
              testID="test-heading-1"
              title="Title"
            />
          </Card>
        </DemoBlock>
        <DemoBlock label={`Level ${level} Aligned Title`}>
          <Card testID={`heading-demo-with-overline-card-level-title-${index}`}>
            <Heading
              actions={actions}
              leftAccessory={<Mail />}
              level={level}
              testID="test-heading-1"
              title="Title"
            />
          </Card>
        </DemoBlock>
        <DemoBlock label={`Level ${level} Title/subtitle`}>
          <Card
            testID={`heading-demo-with-overline-card-level-subtitle-${index}`}
          >
            <Heading
              actions={actions}
              leftAccessory={<Mail />}
              level={level}
              subtitle="Subtitle"
              testID="test-heading-1"
              title="Title"
            />
          </Card>
        </DemoBlock>
        <DemoBlock label={`Level ${level} Title/overline`}>
          <Card
            testID={`heading-demo-with-overline-card-level-title-overline-${index}`}
          >
            <Heading
              actions={actions}
              leftAccessory={<Mail />}
              level={level}
              overline="overline"
              testID="test-heading-1"
              title="Title"
            />
          </Card>
        </DemoBlock>
        <DemoBlock label={`Level ${level} Title/subtitle/overline`}>
          <Card
            testID={`heading-demo-with-overline-card-level-title-subtitle-overline-${index}`}
          >
            <Heading
              actions={actions}
              leftAccessory={<Mail />}
              level={level}
              overline="overline"
              subtitle="Subtitle"
              testID="test-heading-1"
              title="Title"
            />
          </Card>
        </DemoBlock>
      </>
    ))}
    {levelsWithoutOverline.map((level, index) => (
      <>
        <DemoBlock label={`Level ${level} Title`}>
          <Card testID={`heading-demo-without-overline-title-${index}`}>
            <Heading
              actions={smallActions}
              leftAccessory={<Mail />}
              level={level}
              testID="test-heading-1"
              title="Title"
            />
          </Card>
        </DemoBlock>
        <DemoBlock label={`Level ${level} Title/subtitle`}>
          <Card
            testID={`heading-demo-without-overline-title-subtitle-${index}`}
          >
            <Heading
              actions={smallActions}
              leftAccessory={<Mail />}
              level={level}
              subtitle="Subtitle"
              testID="test-heading-1"
              title="Title"
            />
          </Card>
        </DemoBlock>
      </>
    ))}
  </Stack>
);
