import { QueryKeys } from "@/constants/QueryKeys";
import { useSnackbar } from "@/providers/GlobalSnackbarProvider";
import { ProductApi } from "@/utilities/api/ProductApi";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useGetProductById = (id: string) => {

  const { data: productById, isLoading } = useQuery([QueryKeys.GET_PRODUCT, id], 
    () => ProductApi.getOneProductList(id, true), {
      enabled: !!id,
    });

  return { isLoading, productById };
};

export const useDeleteProductById = () => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();

  const { mutate: deleteProductById } = useMutation(
    [QueryKeys.GET_PRODUCT],
    (id: string) => ProductApi.delete(id),
    {
      onSuccess: async () => {
        openSnackbar('Product Deleted Successfully');
        await queryClient.invalidateQueries(QueryKeys.GET_PRODUCTS);
      },
      onError: (err: Error) => {
        openSnackbar(err.message);
      },
    },
  );
  return { deleteProductById };
};