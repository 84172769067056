import { QueryKeys } from '@/constants/QueryKeys';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { FeatureFlagApi } from '@/utilities/api/FeatureFlagApi';
import { FeatureFlag } from '@shared/enums';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const useFeatureFlags = () => {
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: flags = [] } = useQuery(
    [QueryKeys.GET_FEATURE_FLAGS],
    () => FeatureFlagApi.list(),
    {
      onError: () => {
        openSnackbar('Error loading feature flags. Some features may not work correctly.');
      },
    },
  );

  const { mutate: toggleFlag } = useMutation(
    (flagName: FeatureFlag) => {
      // Function is only run when a user toggles an existing flag, so it won't be null
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const flag = flags.find((f) => f.name === flagName)!;
      return FeatureFlagApi.update(flag.id, { enabled: !flag.enabled });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_FEATURE_FLAGS);
      },
      onError: (error: { message: string }) => {
        openSnackbar(
          error.message || 'Error updating feature flag',
        );
      },
    },
  );

  const isFlagOn = (flagName: FeatureFlag) => {
    return !!flags.find((f) => f.name === flagName)?.enabled;
  };

  return {
    flags,
    isFlagOn,
    toggleFlag,
  };
};
