
export const downloadCsv = (data: string, title: string) => {
  const blob = new Blob([data], { type: 'text/csv/charset=utf-8' });

  const a: HTMLAnchorElement = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';

  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = `${title}.csv`;
  a.click();
  window.URL.revokeObjectURL(url);
};
