import { FriendlyPaymentType } from '@/constants/FriendlyPaymentType';
import { ActivePaymentType, ActivePaymentTypes } from '@shared/enums';
import { ResponsivePicker } from './ResponsivePicker';

interface PaymentTypePickerProps {
  onChange: (item: ActivePaymentType) => void,
  onClose: () => void,
  open: boolean,
  paymentType?: ActivePaymentType,
  testID: string,
}

type PaymentPickerData = {
  paymentType: ActivePaymentType,
  toString: () => string,
}

export const PaymentTypePicker = ({
  onChange,
  onClose,
  open,
  paymentType,
  testID,
}: PaymentTypePickerProps) => {
  const data: PaymentPickerData[] = ActivePaymentTypes.map((item) => ({
    paymentType: item,
    toString: () => FriendlyPaymentType[item],
  }));

  return (
    <ResponsivePicker<PaymentPickerData>
      data={data}
      onChange={(item) => onChange(item!.paymentType)}
      onClose={onClose}
      open={open}
      testID={testID}
      title="Payment type"
      value={data?.find((item) => item.paymentType === paymentType)}
    />
  );
};
