import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  Stack,
} from '@mui/material';

export interface ButtonProps extends MuiButtonProps {
  loading?: boolean,
  square?: boolean,
  testID: string,
  uppercase?: boolean,
}

export const Button = ({
  children,
  disabled,
  loading,
  size,
  square = false,
  sx,
  testID,
  uppercase,
  ...rest
}: ButtonProps) => {

  return (
    <MuiButton
      data-testid={testID}
      disabled={loading || disabled}
      focusRipple={false}
      size={size}
      sx={[{
        borderRadius: square ? "2px" : "56px",
        textTransform: uppercase ? 'uppercase' : '',
        minWidth: 'auto',
        whiteSpace: 'nowrap',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <>
        {
          loading && <CircularProgress size={20} style={{
            position: "absolute",
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
          }} />
        }
        <Stack
          alignItems="center"
          direction="row"
          style={{ visibility: loading ? "hidden" : "visible" }}>
          {children}
        </Stack>
      </>
    </MuiButton>
  );
};
