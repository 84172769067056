const isObject = (obj: unknown) => obj === Object(obj);

export class LocalStorageUtility {
  static getItem (key: string) {
    return localStorage.getItem(key);
  }

  static exists (key: string) {
    return localStorage.getItem(key) !== null;
  }

  static remove (key: string) {
    localStorage.removeItem(key);
  }

  static set (key: string, value: unknown) {
    const stringValue = isObject(value) ? JSON.stringify(value) : String(value);
    localStorage.setItem(key, stringValue);
  }
}
