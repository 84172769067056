export enum PaymentStatus {
  DISPUTED = 'disputed',
  DISPUTE_LOST = 'dispute lost',
  DISPUTE_WON = 'dispute won',
  FAILED = 'failed',
  INQUIRY_STARTED = 'inquiry started',
  INQUIRY_WON = 'inquiry won',
  PAID = 'paid',
  PROCESSING = 'processing',
  REFUNDED = 'refunded',
  REQUIRES_ACTION = 'requires action',
  UNPAID = 'unpaid',
}
