import { QueryUtil } from '@/utilities/Query';
import { PricingRequestRetailerEndpoint } from "@api/endpoints";
import { Client } from "./Client";

export class PricingRequestRetailersApi {
  static async getInviteToken (
    id: string,
  ): Promise<string> {
    return Client(`retailers/${id}/invite-token`);
  }
  
  static async update (
    pricingRequestId: string,
    data: PricingRequestRetailerEndpoint.Update.Request,
    token?: string,
  ): Promise<PricingRequestRetailerEndpoint.Update.Response> {
    const query = QueryUtil.stringify({ token });
    return Client(`pricing-requests/${pricingRequestId}/retailers?${query}`,
      { method: "PATCH", body: data },
    );
  }
}
