import { Fab } from '@/components/DesignSystem';
import { Routes } from '@/constants/Routes';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Carousel } from './Carousel';
import { slides } from './CarouselSlides';

export const LandingPage = () => {
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();

  return (
    <>
      <Carousel
        buttons={
          <Stack
            alignSelf="center"
          >
            <Fab
              color="primary"
              onClick={() => navigate(Routes.LOGIN)}
              size="medium"
              sx={{ width: isMobile ? 'auto' : '210px' }}
              testID="login-button"
            >
              Sign Up/Login
            </Fab>
          </Stack>
        }
        slides={slides}
      />
    </>
  );
};
