import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Stack,
  Divider,
} from '@mui/material';
import { ReactNode } from 'react';
import { Text } from '../Text/Text';
import Close from '@mui/icons-material/Close';
import { IconButton } from '../IconButton/IconButton';
import { MenuItem } from '../MenuItem/MenuItem';

export interface SelectProps extends MuiSelectProps {
  children?: ReactNode,
  customValue?: string,
  label?: string,
  onChangeValue?: (value: string) => void,
  onClear?: () => void,
  required?: boolean,
  showClearButton?: boolean,
  testID: string,
  width?: number,
  renderValue?:  (value: unknown) => React.ReactNode,
  hideAsterisk?:boolean
}

export const Select = ({
  children,
  customValue,
  label,
  onChangeValue,
  onClear,
  required,
  showClearButton,
  testID,
  value,
  width,
  renderValue,
  hideAsterisk=false,
  ...rest
}: SelectProps) => {
  return (
    <Stack sx={{ flex: 1, width }}>
      <FormControl fullWidth>
        {!!label && (
          <InputLabel
            disabled={rest.disabled}
            variant={rest.variant || "filled"}>
            {`${label}${required && !hideAsterisk? "*" : ""}`}
          </InputLabel>
        )}
        <MuiSelect
          MenuProps={{
            sx: {
              "& .MuiMenuItem-root": {
                whiteSpace: 'normal',
              },
              "&& .Mui-selected": {
                backgroundColor: showClearButton && !value ? 'transparent' : undefined,
              },
            },
          }}
          data-testid={`${testID}-container`}
          endAdornment={(onClear && value) ? (
            <IconButton
              onClick={onClear}
              sx={{ display: value ? '' : 'none' }}
              testID={`${testID}-close`}
            >
              <Close />
            </IconButton>
          ) : null}
          inputProps={{ "data-testid": testID }}
          label={label ? `${label}${required ? "*" : ""}` : undefined}
          onChange={
            (event: SelectChangeEvent<unknown>) => onChangeValue?.(event.target.value as string)
          }
          renderValue={renderValue}
          sx={{
            "& .MuiSelect-iconFilled": {
              display: (value && onClear) ? 'none' : '',
            },
            borderBottom:
              !rest.disabled && !rest.variant
                ? '1px solid'
                : undefined,
          }}
          value={value === undefined || value === null ? "" : value}
          variant="filled"
          {...rest}
        >
          {children}
          {!!showClearButton && (
            <Divider style={{
              margin: 0,
            }} />
          )}
          {!!showClearButton && (
            <MenuItem testID={testID + '-clear'} value="">
              Clear
            </MenuItem>
          )}
          {!!customValue && (
            <MenuItem sx={{
              height: 0,
              position: 'absolute',
              overflow: 'hidden',
              padding: 0,
              zIndex: -1,
            }} testID={testID + '-custom'} value={customValue}>
              {customValue}
            </MenuItem>
          )}
        </MuiSelect>
      </FormControl>
      {required && (
        <Text category="body-small" sx={{ paddingTop: "4px", paddingLeft: "16px" }}>
          required*
        </Text>
      )}
    </Stack>
  );
};
