import { useCountyList } from '@/hooks/useCountyList';
import { ApiCounty } from '@api/interfaces/ApiCounty';
import { MenuItem } from '@mui/material';
import { State } from '@shared/enums';
import { Select } from '../../DesignSystem';
import { SelectProps } from '../../DesignSystem/Select/Select';

interface CountySelectProps extends SelectProps {
  onChangeCounty: (countyId: string) => void,
  required?: boolean,
  state?: State,
  value?: string,
}

export const CountySelect = ({
  onChangeCounty,
  required = false,
  state,
  ...rest
}: CountySelectProps) => {

  const { counties } = useCountyList();

  return (
    <Select
      label="County"
      onChangeValue={(county) => onChangeCounty(county)}
      required={required}
      {...rest}>
      {(state && counties
        ? counties.filter((x) => x.state === state)
        : []).map((county: ApiCounty) => (
        <MenuItem key={county.id} value={county.id}>
          {county.county}
        </MenuItem>
      ))}
    </Select>
  );
};
