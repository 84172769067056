import {
  Button,
  IconButton,
  ProgressIndicator,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { useHideBackdrop } from '@/hooks/useHideBackdrop';
import {
  getResponsiveValue,
  getResponsiveValues,
  useMediaQuery,
} from '@/hooks/useMediaQuery';
import { createPropsContext } from '@/hooks/useProps';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Close from '@mui/icons-material/Close';
import { Modal as MuiModal, Stack, SxProps } from '@mui/material';
import {
  Children,
  ReactElement,
  ReactNode,
  RefObject,
  createContext,
  useRef,
} from 'react';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';

const styles = {
  modal: getResponsiveValues(
    {
      backgroundColor: 'background.default',
      height: '100%',
    },
    {
      backgroundColor: 'background.default',
      borderRadius: 4,
      boxShadow: 24,
      height: 750,
      left: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: 480,
    },
  ),
  pageContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  pageWrapper: {
    flexGrow: 1,
    overflow: 'scroll',
    px: getResponsiveValue(2, 3),
    py: getResponsiveValue(3, 5),
  },
  pageFooter: {
    alignItems: 'center',
    p: 3,
  },
} as const;

interface ContextProps {
  actionRight?: ReactNode,
  activeStep?: number,
  onStepChange: (step: number) => void,
  showProgressIndicator?: boolean,
  testID: string,
}

const { useProps, Provider } = createPropsContext<ContextProps>();

interface MultiPageModalProps extends ContextProps {
  children: ReactElement<MultiPageStepProps> | ReactElement<MultiPageStepProps>[],
  onClose: () => void,
  title: string,
}

export const MultiPageModal = ({
  actionRight,
  activeStep,
  children,
  onClose,
  onStepChange,
  showProgressIndicator = true,
  testID,
  title,
}: MultiPageModalProps) => {
  const { isMobile } = useMediaQuery();
  const pages = Children.map(children, (child) =>
    child.type !== MultiPageStep ? null : child,
  );

  const steps = pages.length;

  const goToPreviousStep = () => onStepChange((activeStep ?? 0) - 1);

  const { hide: hideBackdrop } = useHideBackdrop(activeStep !== undefined);

  return activeStep === undefined ? null : (
    <MuiModal
      componentsProps={{
        backdrop: {
          style: {
            opacity: hideBackdrop ? 0 : 1,
            transitionDuration: '0',
          },
        },
      }}
      data-testid={testID}
      onClose={onClose}
      open
    >
      <Stack sx={styles.modal}>
        <Stack direction="row" justifyContent="space-between" p={2}>
          {isMobile && activeStep !== 1 ? (
            <IconButton
              onClick={goToPreviousStep}
              testID={`${testID}-previous-button`}
            >
              <ArrowBack />
            </IconButton>
          ) : (
            <Stack alignItems="center" direction={isMobile ? "row" : "column"}>
              <VSpacer size="2" />
              <MobileOnly>
                <IconButton onClick={onClose} sx={{ marginRight:"8px" }} testID={`${testID}-close`}>
                  <Close />
                </IconButton>
              </MobileOnly>
              <Text category="headline-small">{title}</Text>
            </Stack>
          )}
          <Stack direction="row" spacing={1}>
            {actionRight}
            <DesktopOnly>
              <IconButton onClick={onClose} testID={`${testID}-close`}>
                <Close />
              </IconButton>
            </DesktopOnly>
          </Stack>
        </Stack>
        {showProgressIndicator && (
          <ProgressIndicator
            activeStep={activeStep}
            steps={steps}
            testID="test"
            type="secondary"
          />
        )}

        <Provider value={{ activeStep, onStepChange, testID }}>
          {pages.map((page, index) => (activeStep !== index + 1 ? null : page))}
        </Provider>
      </Stack>
    </MuiModal>
  );
};

interface MultiPageStepProps {
  accessoryLeft?: ReactNode,
  children: ReactNode,
  containerStyle?: SxProps,
  nextButton?: (onClick: () => void) => ReactNode,
  previousButton?: (onClick: () => void) => ReactNode,
}

export const AutocompleteRefContext = createContext<RefObject<HTMLDivElement> | null>(null);

export const MultiPageStep = ({
  accessoryLeft,
  children,
  containerStyle,
  nextButton,
  previousButton,
}: MultiPageStepProps) => {
  const { activeStep = 0, onStepChange, testID } = useProps();
  const modalRef = useRef<HTMLDivElement>(null);

  const goToNextStep = () => onStepChange(activeStep + 1);
  const goToPreviousStep = () => onStepChange(activeStep - 1);

  return (
    <Stack
      data-testid={`${testID}-page-${activeStep}`}
      sx={styles.pageContainer}
    >
      <AutocompleteRefContext.Provider value={modalRef}>
        <Stack
          className="scrollable-container"
          ref={modalRef}
          sx={{
            ...styles.pageWrapper,
            ...containerStyle,
          }}>
          {children}
        </Stack>
      </AutocompleteRefContext.Provider>
      <Stack
        direction="row"
        justifyContent={getResponsiveValue(
          !accessoryLeft ? "center" : "space-between",
          "space-between",
        )}
        sx={styles.pageFooter}
      >
        <Stack alignItems="center" direction="row" spacing={1}>
          {accessoryLeft}
        </Stack>
        <Stack alignItems="center" direction="row" spacing={1}>
          {activeStep !== 1 &&
            (previousButton?.(goToPreviousStep) ?? (
              <DesktopOnly>
                <Button
                  onClick={goToPreviousStep}
                  testID={`${testID}-previous-button`}
                  variant="text"
                >
                  Back
                </Button>
              </DesktopOnly>
            ))}
          {nextButton?.(goToNextStep) ?? (
            <Button onClick={goToNextStep} testID={`${testID}-next-button`}>
              Next
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
