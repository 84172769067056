import { Fragment, useState } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { FeaturedProductApi } from '@/utilities/api/FeaturedProductApi';
import AddFeaturedProduct from './AddFeaturedProduct';
import { useSearch } from '@/hooks/useSearch';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { ToastMessages } from '@/constants/constant';
import DetailedCard from './DetailedCard';

const FeaturedProduct = () => {
  const queryClient = useQueryClient();
  const [isSearch, setIsSearch] = useState(false);
  const [addFeaturedProduct, setFeaturedProduct] = useState(false);

  const { openSnackbar } = useSnackbar();

  const { search, setSearch, debouncedSearch } = useSearch();

  const { data: featuredProducts } = useQuery(
    [QueryKeys.GET_FEATURED_PRODUCTS, debouncedSearch],
    () => FeaturedProductApi.list(debouncedSearch),
    {
      onSuccess: (response) => {
        if (response?.length) {
          return setIsSearch(true);
        }
        if (debouncedSearch) {
          return setIsSearch(true);
        }
        return setIsSearch(false);
      },
    },
  );

  const { mutate: deleteFeaturedProduct } = useMutation(
    (id: string) => FeaturedProductApi.delete(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.GET_FEATURED_PRODUCTS]);
        openSnackbar(ToastMessages.featuredProductDeleteSuccess);
      },
      onError: (error: DetailedApiError) => {
        if (error?.code == '500') {
          return openSnackbar('Something went wrong');
        }
        return openSnackbar(error?.message);
      },
    },
  );

  const { mutate: saveChanges, isLoading: isSaveChangesLoading } = useMutation(
    (args: string[]) => FeaturedProductApi.reOrderFeaturedProduct(args),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.GET_FEATURED_PRODUCTS]);
        openSnackbar(ToastMessages.featuredProductAddSuccess);
      },
      onError: (error: DetailedApiError) => {
        if (error?.code == '500') {
          return openSnackbar(ToastMessages.featuredProductReOrderFailure);
        }
        return openSnackbar(error?.message);
      },
    },
  );

  const featuredProductsData =
    featuredProducts?.map(({ rank, title, id, productId }) => ({
      id,
      productId: productId ?? '',
      rank: rank ?? NaN,
      name: title ?? '',
    })) || [];

  const getTitle = () => {
    if (!isSearch) {
      return 'Featured Product';
    }
    if (featuredProductsData?.length <= 1) {
      return `${featuredProducts?.length} Featured Product`;
    }
    return `${featuredProducts?.length} Featured Products`;
  };

  return (
    <Fragment>
      <DetailedCard
        addNew={setFeaturedProduct}
        cardTitle='Product'
        data={featuredProductsData}
        deleteCard={deleteFeaturedProduct}
        isSaveChangesLoading={isSaveChangesLoading}
        isSearch={isSearch}
        saveChanges={saveChanges}
        search={search}
        setSearch={setSearch}
        title={getTitle()}
      />
      {addFeaturedProduct && (
        <AddFeaturedProduct
          addFeaturedProduct={addFeaturedProduct}
          close={() => setFeaturedProduct(false)}
          featuredProductData={featuredProductsData}
        />
      )}
    </Fragment>
  );
};

export default FeaturedProduct;
