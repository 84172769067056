import { Button, FullscreenDialog, Modal, Text, VSpacer } from '@/components/DesignSystem';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { StripeBadge } from '@/components/shared/StripeBadge';
import { AppConfig } from '@/constants/AppConfig';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import PaymentForm from '@/pages/Payment/PaymentForm';
import SubmitPaymentButton from '@/pages/Payment/SubmitPaymentButton';
import { ApiPayment } from '@api/interfaces';
import { Box, CircularProgress, Divider, Stack, Theme, useTheme } from '@mui/material';
import { formatCurrency } from '@shared/utilities';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import React, { useState } from "react";

const stripePromise = loadStripe(AppConfig.stripePublishableKey);

interface RetailerPaymentModalProps {
  offerPublicId: string,
  onClose: () => void,
  payment: ApiPayment,
}

const getStripeAppearanceOptions = (theme: Theme): StripeElementsOptions['appearance'] => ({
  rules: {
    '.Error': {
      marginBottom: '10px',
    },
    '.Input': {
      marginBottom: '10px',
    },
    '.Label': {
      paddingBottom: '6px',
    },
    '.Tab': {
      marginBottom: '10px',
    },
    '.Tab--selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '.Tab--selected:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  theme: theme.palette.mode === 'dark' ? 'night' : undefined,
  variables: {
    borderRadius: '6px',
    colorDanger: 'error',
    colorIconTab: theme.palette.text.primary,
    colorIconTabSelected: theme.palette.primary.contrastText,
    colorPrimary: theme.palette.primary.main,
    colorText: theme.palette.text.primary,
    colorTextSecondary: theme.palette.text.primary,
    fontFamily: "'Roboto', sans-serif;",
    fontWeightNormal: '400',
    spacingGridRow: '0px',
  },
});

export const RetailerPaymentModal = ({
  offerPublicId,
  onClose,
  payment,
}: RetailerPaymentModalProps) => {
  const [email, setEmail] = useState<string>('');
  const { isMobile } = useMediaQuery();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  const options: StripeElementsOptions = {
    ...getStripeAppearanceOptions(theme),
    clientSecret: payment.clientSecret ?? undefined,
    loader: 'always',
  };

  const renderBody = () => {
    return (
      <Box
        sx={{
          maxHeight: isMobile ? undefined : '490px',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <MobileOnly>
          <Text category="body-small">
            Retailer transaction fee
          </Text>
          <VSpacer size="8" />
        </MobileOnly>
        <Divider />
        <VSpacer size="4" />
        <Stack flexDirection="row" justifyContent="space-between">
          <Text category="body-medium">Offer number {offerPublicId}</Text>
          <Text category="body-medium">
            Amount Due: {formatCurrency(payment.feeAmountInCents / 100)}
          </Text>
        </Stack>
        <VSpacer size="4" />
        <Divider />
        <VSpacer size="8" />
        {isLoading &&
          <Box textAlign="center" width={isMobile ? undefined : 620}>
            <VSpacer size="12" />
            <CircularProgress size="100px" />
            <VSpacer size="12" />
          </Box>
        }
        {payment.clientSecret && (
          <Box
            sx={{
              display: isLoading ? 'none' : undefined,
            }}
          >
            <PaymentForm
              onEmailUpdate={(email) => setEmail(email)}
              onLoaded={() => setIsLoading(false)}
              payment={payment}
            />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Elements options={options} stripe={stripePromise}>
      <DesktopOnly>
        <Modal
          acceptButton={(props) => (
            <SubmitPaymentButton
              email={email}
              pricingRequestId={payment.pricingRequestId}
              {...props}
            />
          )}
          cancelButton={(props) => (
            <Button onClick={onClose} {...props}>
              Cancel
            </Button>
          )}
          headerAccessoryRight={<StripeBadge />}
          loading={isLoading}
          onClose={onClose}
          open
          subline="Retailer transaction fee"
          testID="payment-modal"
          title="Payment"
        >
          {renderBody()}
        </Modal>
      </DesktopOnly>
      <MobileOnly>
        <FullscreenDialog
          actionButton={
            <SubmitPaymentButton
              email={email}
              pricingRequestId={payment.pricingRequestId}
              testID="payment-modal-submit-button"
            />
          }
          onClose={onClose}
          open
          testID="payment-fullscreen-dialog"
          title="Payment"
        >
          {renderBody()}
        </FullscreenDialog>
      </MobileOnly>
    </Elements>
  );
};
