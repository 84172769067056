import { Stack, StackProps } from '@mui/system';
import { Card, Text, VSpacer } from '@/components/DesignSystem';

interface DemoProps extends StackProps {
  label?: string,
}

export const DemoBlock = ({ label, children, ...props }: DemoProps) => (
  <Card testID="demo-block-card">
    <Text category="body-large">{label}</Text>
    <Stack {...props}>
      <VSpacer size="5" />
      {children}
      <VSpacer size="5" />
    </Stack>
  </Card>
);
