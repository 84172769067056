import {
  Button,
  Card, Chip,
  DataPoint,
  HSpacer,
  ProgressLoader,
  Text,
  VSpacer,
} from "@/components/DesignSystem";
import { QueryKeys } from '@/constants/QueryKeys';
import { SendTestNotificationModal } from '@/pages/Admin/Notifications/SendTestNotificationModal';
import { NotificationApi } from '@/utilities/api/NotificationApi';
import Add from '@mui/icons-material/Add';
import { Container, Pagination, Stack } from "@mui/material";
import { formatDate, getFarmerName } from '@shared/utilities';
import { useState } from 'react';
import { useQuery } from 'react-query';

export const Notifications = () => {
  const [page, setPage] = useState(0);
  const [isSendTestModalOpen, setIsSendTestModalOpen] = useState(false);

  const { data: notifications, isLoading } = useQuery(
    [QueryKeys.GET_NOTIFICATIONS, page],
    async () => NotificationApi.list({ page, sort: 'createdAt', sortDesc: true }),
  );

  const Header = (
    <>
      <Text category="title-large">
        Notifications
      </Text>
      <VSpacer size="5" />
      <Stack alignItems="end" direction="row" justifyContent="space-between">
        <Button
          onClick={() => { setIsSendTestModalOpen(true); }}
          startIcon={<Add />}
          testID="send-test-notification-button"
        >
          Send Test Notification
        </Button>
      </Stack>
    </>
  );

  const NotificationCards = !!notifications?.data.length && (
    <Stack gap="16px">
      {notifications.data.map((notification) => (
        <Card
          cardContentStyle={{ padding: '16px' }}
          key={notification.id}
          testID={`${notification.id}-notification-card`}
        >
          <Stack direction="row">
            <Text category="title-medium">{notification.title}</Text>
            {notification.isTest &&
              <>
                <HSpacer size="3" />
                <Chip color="primary" label="Test" testID={`${notification.id}-test-chip`}/>
              </>
            }
          </Stack>
          <DataPoint label="User:" testID={`${notification.id}-user-data-point`}>
            {notification.user?.firstName
              ? getFarmerName(notification.user) : notification.user?.businessName}
          </DataPoint>
          <DataPoint label="Created:" testID={`${notification.id}-created-data-point`}>
            {formatDate(notification.createdAt)}
          </DataPoint>
        </Card>
      ))}
    </Stack>
  );

  return (
    <>
      <Container maxWidth="lg">
        <VSpacer size="10" />
        {Header}
        <VSpacer size="6" />
        {isLoading ? (
          <Stack alignItems="center" py="180px">
            <ProgressLoader type="circular" />
          </Stack>
        ) : NotificationCards}
        {!!notifications?.total && notifications?.lastPage > 0 && (
          <Stack alignItems="center" py="20px">
            <Pagination
              count={notifications?.lastPage + 1}
              onChange={(event, page) => {
                setPage(page - 1);
              }}
              page={notifications?.page + 1}
            />
          </Stack>
        )}
      </Container>
      {isSendTestModalOpen &&
        <SendTestNotificationModal
          onClose={() => setIsSendTestModalOpen(false)}
        />
      }
    </>
  );
};
