import { ReactNode } from "react";
import { DataPointProps } from "../DataPoint/DataPoint";
import { DataSet } from "../DataSet/DataSet";

interface PriceRequestProductCardProps {
  actions: ReactNode[],
  crop?: string,
  name: string,
  packageSize?: string,
  quantityUom: string,
  testID: string,
  type?: string,
}

export const PriceRequestProductCard = ({
  actions,
  crop,
  name,
  packageSize,
  quantityUom,
  testID,
  type,
}: PriceRequestProductCardProps) => {
  
  const metadata: DataPointProps[] = [
    {
      children: quantityUom,
      label: "Qty/UoM:",
      padding: "4px 0px",
      spacing: "flushed",
      testID: `${testID}-quantity-uom`,
    },
    {
      children: type,
      label: "Type:",
      padding: "4px 0px",
      spacing: "flushed",
      testID: `${testID}-type`,
    },
    {
      children: packageSize,
      label: "Package size:",
      padding: "4px 0px",
      spacing: "flushed",
      testID: `${testID}-package-size`,
    },
    {
      children: crop,
      label: "Crop:",
      padding: "4px 0px",
      spacing: "flushed",
      testID: `${testID}-crop`,
    },
  ];
  
  return (
    <DataSet
      actions={actions}
      metadata={metadata}
      testID={testID}
      title={name}
    />
  );
};
