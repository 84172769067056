import GrowersIcon from '@/assets/icons/growers-icon.svg';
import ManufacturerIcon from '@/assets/icons/manufacturer-icon.svg';
import RetailerIcon from '@/assets/icons/retailer-icon.svg';

export interface Wallet {
  activePoints: number,
  manufacturerId: string | null,
  manufacturerName: string | null,
  name: string,
  retailerId: string | null,
  retailerName: string | null,
}

export const getIcon = (wallet: Wallet) => {
  if (!wallet.manufacturerId && !wallet.retailerId) {
    return GrowersIcon;
  } else if (wallet.manufacturerId) {
    return ManufacturerIcon;
  }
  return RetailerIcon;
};
