import { HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { ColorStopNumber, useColor } from '@/hooks/useColor';
import { Box, Stack } from '@mui/material';
import { Color } from '../../../../themes/MUITheme/palette';
import { DemoBlock } from '../../components/DemoBlock';

export const ColorDemo = () => {
  const { getColor } = useColor();
  const colors: Color[] = ['primary', 'info', 'warning', 'error', 'success'];
  const colorStopNumbers: ColorStopNumber[] = [1, 2, 3 ,4 , 5, 6, 7, 8, 9, 10];

  return (
    <DemoBlock
      sx={{
        padding: "16px",
        backgroundColor: "background.default",
      }}
    >
      <Stack flexDirection="row">
        {colors.map((color) => (
          <Stack alignItems="center" key={color} px="8px">
            <Text>{color}</Text>
            <VSpacer size="4" />
            <Stack alignItems="flex-end">
              {colorStopNumbers.map((number) => (
                <Stack alignItems="center" flexDirection="row" key={number}>
                  <Text>{color} {number}</Text>
                  <HSpacer size="3" />
                  <Box
                    height="50px"
                    my="4px"
                    sx={{ backgroundColor: getColor(color, number) }}
                    width="50px"
                  />
                </Stack>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </DemoBlock>
  );
};
