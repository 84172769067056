import { FeedbackEndpoint } from "@api/endpoints";
import { Client } from "./Client";

export class FeedbackApi {
  static submitFeedback (
    data: FeedbackEndpoint.SubmitFeedback.Request,
  ): Promise<FeedbackEndpoint.SubmitFeedback.Response> {
    return Client('submit-feedback', {
      body: data,
      method: 'POST',
    });
  }
}
