import {
  Button,
  Checkbox,
  Search,
  Select,
  SideSheet,
  Text,
} from '@/components/DesignSystem';
import { States } from '@/constants/CountiesStateList';
import {
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from '@mui/material';
import {
  useAddCounties,
  useGetCountiesByState,
} from '@/hooks/useRetailerLocations';
import React, { useEffect, useState } from 'react';
import { State } from '@shared/enums';
import { ApiCounty } from '@api/interfaces';
import { useParams } from 'react-router-dom';
import { useSearch } from '@/hooks/useSearch';

type SelectCountiesProps = {
  isSelectCounties: boolean;
  setIsSelectCounties: (value: boolean) => void;
};

const SelectCounties = ({
  isSelectCounties,
  setIsSelectCounties,
}: SelectCountiesProps) => {
  const [selectedState, setSelectedState] = React.useState<string>('');
  const [selectedCounties, setSelectedCounties] = React.useState<Partial<ApiCounty[]>>(
    [],
  );
  const [counties, setCounties] = useState<Partial<ApiCounty[]>>([]);

  const { search, setSearch, debouncedSearch } = useSearch();

  const { getCountiesByState, isLoading } =
    useGetCountiesByState(selectedState, setCounties);

  const { locationId = '' } = useParams<{ locationId: string }>();

  const { addCounties, isLoading: isAddCountiesLoading } =
    useAddCounties(locationId, setIsSelectCounties);

  useEffect(() => {
    if (debouncedSearch) {
      const filteredCounties = getCountiesByState?.filter((county) =>
        county?.county?.toLowerCase().includes(debouncedSearch.toLowerCase()),
      );
      setCounties(filteredCounties ?? []);
    }
    if (!debouncedSearch) {
      setCounties(getCountiesByState ?? []);
    }
  }, [debouncedSearch, getCountiesByState]);

  const CancelButton = () => {
    return (
      <Button
        fullWidth
        onClick={() => setIsSelectCounties(false)}
        testID='cancel-btn'
        variant='outlined'
      >
        Cancel
      </Button>
    );
  };

  const AddButton = () => {
    return (
      <Button
        disabled={selectedCounties?.length === 0}
        fullWidth
        loading={isAddCountiesLoading}
        onClick={() => {
          addCounties({
            counties: selectedCounties?.map((county) => ({
              state: county?.state ?? State.Alabama,
              county: county?.county ?? '',
            })),
            serveAllCounties: false,
          });
        }}
        testID='add-btn'
      >
        Add
      </Button>
    );
  };

  const handleToggle = (item: ApiCounty | undefined) => () => {
    setSelectedCounties((prevSelected: (ApiCounty | undefined)[]) => {
      if (
        prevSelected?.some(
          (selectedItem: ApiCounty | undefined) => selectedItem?.county === item?.county,
        )
      ) {
        return prevSelected.filter(
          (selectedItem: ApiCounty | undefined) => selectedItem?.county !== item?.county,
        );
      } else {
        return [...prevSelected, item as ApiCounty];
      }
    });
  };

  const onSelectAll = () => {
    if (
      getCountiesByState &&
      selectedCounties.length === getCountiesByState?.length
    ) {
      return setSelectedCounties([]);
    }
    if (getCountiesByState) {
      return setSelectedCounties([...getCountiesByState]);
    }
  };

  return (
    <SideSheet
      actionButton={CancelButton}
      hideScrollbar={true}
      onClose={() => setIsSelectCounties(false)}
      onOpen={() => setIsSelectCounties(true)}
      open={isSelectCounties}
      secondaryActionButton={AddButton}
      testID='sidesheet'
      title='Select Counties'
    >
      <Stack p='12px 24px 16px 24px'>
        <Select
          label='State'
          onChangeValue={(value) => {
            setSelectedState(value);
          }}
          onClear={() => setSelectedState('')}
          testID='counties-modal-select-state'
          value={selectedState}
        >
          {States?.map((state: string) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
        </Select>
        {!selectedState && (
          <Stack alignItems='center' justifyContent='center' p='56px 0px'>
            <Text>Select a state</Text>
          </Stack>
        )}

        <Stack>
          {selectedState && isLoading && (
            <Stack
              alignItems='center'
              display='flex'
              justifyContent='center'
              p='32px 0px 0px 0px'
            >
              <CircularProgress sx={{ color: 'yellow' }} />
            </Stack>
          )}
          {selectedState && (
            <>
              <Stack p='32px 0px 0px 0px'>
                <Search
                  name='Search'
                  onChangeText={(value: string) => setSearch(value)}
                  testID='counties-modal-search'
                  value={search}
                />
              </Stack>
              <Stack
                alignItems='center'
                direction='row'
                justifyContent='space-between'
                p='24px 0px'
              >
                <Text>{counties?.length} Counties</Text>
                <Button
                  onClick={onSelectAll}
                  size='small'
                  testID='select-all'
                  variant='text'
                >
                  {selectedCounties.length === counties?.length
                    ? 'Clear all'
                    : 'Select all'}
                </Button>
              </Stack>
              <List>
                {counties?.length === 0 && (
                  <ListItem sx={{ background: 'rgba(33,33,32,1)' }}>
                    <ListItemText primary='No counties found' />
                  </ListItem>
                )}
                {counties?.map((countyData: ApiCounty | undefined) => (
                  <>
                    <ListItem
                      key={countyData?.county}
                      onClick={handleToggle(countyData)}
                      sx={{ background: 'rgba(33,33,32,1)' }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={selectedCounties.some(
                            (item: ApiCounty | undefined) => item?.county === countyData?.county,
                          )}
                          edge='start'
                          testID='select-county-checkbox'
                        />
                      </ListItemIcon>
                      <ListItemText primary={`${countyData?.county}`} />
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
            </>
          )}
        </Stack>
      </Stack>
    </SideSheet>
  );
};

export default SelectCounties;
