import { SplashPage } from '@/pages/RedirectToOs/SplashPage';
import { useLocation } from 'react-router-dom';

export const GetAppPage = () => {
  const location = useLocation();
  const { state } = location;
  const domain = window.location.href.split("#")[0];
  window.location.href = `${domain}#/redirect/Home`;
  if (state?.redirect) {
    setTimeout(() => {
      window.location.href = state.redirect;
    }, 0);
  }
  return <SplashPage />;
};
