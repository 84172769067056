import { Button, Text, VSpacer, HSpacer } from '@/components/DesignSystem';
import { Size } from '@/themes/variant-interfaces/Size';
import { DemoBlock } from '../../components/DemoBlock';
import { Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export const ButtonDemo = () => {
  const sizes: Size[] = ['tiny', 'small', 'medium', 'large', 'giant'];
  type Status = 'primary' | 'info' | 'warning' | 'error' | 'success';

  const statuses: Status[] = ['primary', 'info', 'warning', 'error', 'success'];

  return (
    <DemoBlock
      sx={{
        padding: "16px",
        backgroundColor: "background.default",
      }}
    >
      <DemoBlock
        divider={<HSpacer size="5" />}
        label="Filled button"
        spacing={1}
        sx={{ backgroundColor: "background.default" }}
      >
        <DemoBlock direction="row" spacing={2}>
          <Button testID="button-demo-enable">Enabled</Button>
          <Button disabled testID="button-demo-disabled">
            Disabled
          </Button>
        </DemoBlock>

        <DemoBlock direction="row" spacing={2}>
          <Button
            endIcon={<AddIcon />}
            testID="button-demo-enabled-left-accessory"
          >
            Enabled
          </Button>
          <Button
            disabled
            endIcon={<AddIcon />}
            testID="button-demo-disabled-left-accessory"
          >
            Disabled
          </Button>
        </DemoBlock>
        <DemoBlock direction="row" spacing={2}>
          <Button
            startIcon={<AddIcon />}
            testID="button-demo-enabled-left-accessory"
          >
            Enabled
          </Button>
          <Button
            disabled
            startIcon={<AddIcon />}
            testID="button-demo-disabled-left-accessory"
          >
            Disabled
          </Button>
        </DemoBlock>
      </DemoBlock>
      <VSpacer size="5" />
      <DemoBlock
        divider={<HSpacer size="5" />}
        label="Outline button"
        spacing={1}
      >
        <DemoBlock direction="row" spacing={2}>
          <Button testID="button-demo-enabled-outline" variant="outlined">
            Enabled
          </Button>
          <Button
            disabled
            testID="button-demo-disabled-outline"
            variant="outlined"
          >
            Disabled
          </Button>
        </DemoBlock>
        <DemoBlock direction="row" spacing={2}>
          <Button
            endIcon={<AddIcon />}
            testID="button-demo-enabled-outline-left-accessory"
            variant="outlined"
          >
            Enabled
          </Button>
          <Button
            disabled
            endIcon={<AddIcon />}
            testID="button-demo-disabled-outline-left-accessory"
            variant="outlined"
          >
            Disabled
          </Button>
        </DemoBlock>
        <DemoBlock direction="row" spacing={2}>
          <Button
            startIcon={<AddIcon />}
            testID="button-demo-enabled-outline-left-accessory"
            variant="outlined"
          >
            Enabled
          </Button>
          <Button
            disabled
            startIcon={<AddIcon />}
            testID="button-demo-disabled-outline-left-accessory"
            variant="outlined"
          >
            Disabled
          </Button>
        </DemoBlock>
      </DemoBlock>
      <VSpacer size="5" />
      <DemoBlock divider={<HSpacer size="5" />} label="Text button" spacing={1}>
        <DemoBlock direction="row" spacing={2}>
          <Button testID="button-demo-enabled" variant="text">
            Enabled
          </Button>
          <Button disabled testID="button-demo-disabled-text" variant="text">
            Disabled
          </Button>
        </DemoBlock>
        <DemoBlock direction="row" spacing={2}>
          <Button
            endIcon={<AddIcon />}
            testID="button-demo-enabled-text"
            variant="text"
          >
            Enabled
          </Button>
          <Button
            disabled
            endIcon={<AddIcon />}
            testID="button-demo-disabled-text-accessory-left"
            variant="text"
          >
            Disabled
          </Button>
        </DemoBlock>
        <DemoBlock direction="row" spacing={2}>
          <Button
            startIcon={<AddIcon />}
            testID="button-demo-enabled-text"
            variant="text"
          >
            Enabled
          </Button>
          <Button
            disabled
            startIcon={<AddIcon />}
            testID="button-demo-disabled-text-accessory-left"
            variant="text"
          >
            Disabled
          </Button>
        </DemoBlock>
      </DemoBlock>
      <VSpacer size="5" />
      <DemoBlock
        divider={<HSpacer size="5" />}
        label="Square button"
        spacing={1}
      >
        <DemoBlock direction="row" spacing={2}>
          <Button square testID="button-square-demo-enabled-left-accessory">
            Enabled
          </Button>
          <Button
            disabled
            square
            testID="button-square-demo-disabled-left-accessory"
          >
            Disabled
          </Button>
        </DemoBlock>
        <DemoBlock direction="row" spacing={2}>
          <Button
            endIcon={<AddIcon />}
            square
            testID="button-square-demo-enabled-left-accessory"
          >
            Enabled
          </Button>
          <Button
            disabled
            endIcon={<AddIcon />}
            square
            testID="button-square-demo-disabled-left-accessory"
          >
            Disabled
          </Button>
        </DemoBlock>
        <DemoBlock direction="row" spacing={2}>
          <Button
            square
            startIcon={<AddIcon />}
            testID="button-square-demo-enabled-left-accessory"
          >
            Enabled
          </Button>
          <Button
            disabled
            square
            startIcon={<AddIcon />}
            testID="button-square-demo-disabled-left-accessory"
          >
            Disabled
          </Button>
        </DemoBlock>
        <DemoBlock direction="row" spacing={2}>
          <Button
            square
            testID="button-square-demo-enabled-outline"
            variant="outlined"
          >
            Enabled
          </Button>
          <Button
            disabled
            square
            testID="button-square-demo-disabled-outline"
            variant="outlined"
          >
            Disabled
          </Button>
        </DemoBlock>
        <DemoBlock direction="row" spacing={2}>
          <Button
            endIcon={<AddIcon />}
            square
            testID="button-square-demo-enabled-outline-left-accessory"
            variant="outlined"
          >
            Enabled
          </Button>
          <Button
            disabled
            endIcon={<AddIcon />}
            square
            testID="button-square-demo-disabled-outline-left-accessory"
            variant="outlined"
          >
            Disabled
          </Button>
        </DemoBlock>
        <DemoBlock direction="row" spacing={2}>
          <Button
            square
            startIcon={<AddIcon />}
            testID="button-square-demo-enabled-outline-left-accessory"
            variant="outlined"
          >
            Enabled
          </Button>
          <Button
            disabled
            square
            startIcon={<AddIcon />}
            testID="button-square-demo-disabled-outline-left-accessory"
            variant="outlined"
          >
            Disabled
          </Button>
        </DemoBlock>
      </DemoBlock>
      <VSpacer size="5" />

      <VSpacer size="8" />

      <Text category="title-small">OLD DEMOS</Text>
      <VSpacer size="5" />

      <Text>Filled Buttons</Text>
      <VSpacer size="5" />
      <DemoBlock
        alignItems="center"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        {sizes.map((size) => (
          <Button key={size} size={size} testID="button-demo-filled-size">
            {size}
          </Button>
        ))}
      </DemoBlock>
      <VSpacer size="5" />
      <Text>Loading Buttons</Text>
      <VSpacer size="5" />
      <DemoBlock
        alignItems="center"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        {sizes.map((size) => (
          <Button
            key={size}
            loading
            size={size}
            testID="button-demo-loading-size"
          >
            {size}
          </Button>
        ))}
      </DemoBlock>
      <VSpacer size="5" />
      <Text>Outlined Buttons</Text>
      <VSpacer size="5" />
      <DemoBlock
        alignItems="center"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Button
          size="tiny"
          testID="button-demo-tiny-outlined"
          variant="outlined"
        >
          Tiny
        </Button>
        <Button
          size="small"
          testID="button-demo-small-outlined"
          variant="outlined"
        >
          Small
        </Button>
        <Button
          size="medium"
          testID="button-demo-medium-outlined"
          variant="outlined"
        >
          Medium
        </Button>
        <Button
          size="large"
          testID="button-demo-large-outlined"
          variant="outlined"
        >
          Large
        </Button>
        <Button
          size="giant"
          testID="button-demo-giant-outlined"
          variant="outlined"
        >
          Giant
        </Button>
      </DemoBlock>
      <VSpacer size="5" />
      <Text>Status Buttons</Text>
      <VSpacer size="5" />
      <DemoBlock
        alignItems="center"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        {statuses.map((status) => (
          <Button
            color={status}
            key={status}
            size="medium"
            testID="button-demo-medium-status"
            uppercase
            variant="contained"
          >
            {status}
          </Button>
        ))}
      </DemoBlock>
      <VSpacer size="5" />
      <Text>Text Buttons</Text>
      <VSpacer size="5" />
      <DemoBlock
        alignItems="center"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Button size="tiny" testID="button-demo-tiny-text" variant="text">
          Tiny
        </Button>
        <Button size="small" testID="button-demo-small-text" variant="text">
          Small
        </Button>
        <Button size="medium" testID="button-demo-medium-text" variant="text">
          Medium
        </Button>
        <Button size="large" testID="button-demo-large-text" variant="text">
          Large
        </Button>
        <Button size="giant" testID="button-demo-giant-text" variant="text">
          Giant
        </Button>
      </DemoBlock>
      <VSpacer size="5" />
      <Text>Disabled Buttons</Text>
      <VSpacer size="5" />
      <DemoBlock
        alignItems="center"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
        sx={{ alignItems: "center" }}
      >
        {sizes.map((size) => (
          <Button
            disabled
            key={size}
            size={size}
            testID="button-demo-disabled-giant-contained-size"
            variant="contained"
          >
            {size}
          </Button>
        ))}
      </DemoBlock>
      <VSpacer size="5" />
      <Text>Button Elevation</Text>
      <VSpacer size="5" />
      <DemoBlock
        alignItems="center"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Button testID="button-demo-floating">Floating</Button>
        <Button disableElevation testID="button-demo-disabled-elevation">
          Standard
        </Button>
      </DemoBlock>
      <VSpacer size="5" />
      <Text>Uppercase Button</Text>
      <VSpacer size="5" />
      <DemoBlock
        alignItems="center"
        direction="row"
        divider={<Divider flexItem orientation="vertical" />}
        spacing={2}
      >
        <Button testID="button-demo-uppercase" uppercase>
          uppercase
        </Button>
      </DemoBlock>
    </DemoBlock>
  );
};
