import { Box, Stack } from '@mui/material';
import { Children } from 'react';

interface BottomBarProps {
  children?: React.ReactNode,
  isStatic?: boolean, // for demo purposes
  justifyContent?: "center" | "flex-end" | "space-between",
  removeGutters?: boolean,
  width?: number | 'inherit',
}

export const BottomBar = ({
  children,
  isStatic = false,
  justifyContent = "space-between",
  removeGutters = false,
  width,
}: BottomBarProps) => {
  let widthProps;
  if (width === 'inherit') {
    widthProps = { maxWidth: 'inherit', width: 'inherit' };
  } else {
    widthProps = { width: width ?? '100%' };
  }
  return (
    <Box sx={{
      bottom: 32,
      pointerEvents: isStatic ? "all" : "none",
      position: isStatic ? "static" : "fixed",
      width: widthProps,
    }}>
      <Stack
        direction="row"
        justifyContent={justifyContent}
        sx={{ paddingX: removeGutters ? 0 : '40px' }}
      >
        {Children.toArray(children).map((c, index) => (
          <Box key={index} sx={{ pointerEvents: "all" }} width="100%">
            {c}
          </Box>),
        )}
      </Stack>
    </Box>
  );
};
