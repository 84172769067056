import { Text } from '@/components/DesignSystem';
import { formatPhoneNumber } from '@shared/utilities';

interface SalesPersonContactInfo {
  salesPersonContactInfo: {
    email: string;
    location?: string;
    phone: string;
  };
}
const SalesPersonContactCard = ({
  salesPersonContactInfo,
}: SalesPersonContactInfo) => {
  const { email, location, phone } = salesPersonContactInfo;
  
  return (
    <>
      {!!location && (
        <Text
          fontSize='12px'
          sx={{
            textTransform: 'capitalize',
          }}
        >
          Location: {location}
        </Text>
      )}
      {!!phone?.length && (
        <Text fontSize='12px'>
        +1 {formatPhoneNumber(phone ?? '', 'paren')}
        </Text>
      )}
      <Text fontSize='12px'>
        {email}
      </Text>
    </>
  );
};

export default SalesPersonContactCard;
