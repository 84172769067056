import { Logo } from '@/components/DesignSystem';
import { getPortalRedirectUrl } from '@/utilities/StorefrontUtilities';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Ballot from '@mui/icons-material/Ballot';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { SvgIconProps } from '@mui/material';
import { Routes } from '../../../constants/Routes';
import { LogoProps } from '../Logo/Logo';

type MenuPropBase = {
  dividerAfter?: boolean,
  headerName?: string,
  icon?: (props: LogoProps | SvgIconProps) => JSX.Element,
  name: string,
  requiresAuthentication?: boolean,
  showArrow?: boolean,
};

export type MenuPropWithExternalLink = MenuPropBase & {
  externalLink: string,
  route?: never,
};

export type MenuPropWithRoute = MenuPropBase & {
  externalLink?: never,
  route: string,
};

export type MenuProp = MenuPropWithExternalLink | MenuPropWithRoute;

export const baseMenu: MenuProp[] = [
  {
    icon: (props) => <Logo category="growers-logo-reverse" size="menu" {...props as LogoProps} />,
    name: 'Home',
    route: Routes.HOMEPAGE,
  },
  {
    name: 'Requests',
    route: Routes.PAST_PRICING_REQUESTS,
    icon: (props) => <Ballot {...props} />,
    requiresAuthentication: true,
    dividerAfter: true,
  },
  {
    icon: (props) => <AccountCircle {...props} />,
    name: 'Account',
    requiresAuthentication: true,
    route: Routes.MY_PROFILE,
  },
];

export const landusMenu: MenuProp[] = [
  {
    externalLink: getPortalRedirectUrl('landus'),
    icon: (props) => (
      <Logo category="landus-icon" size="menu" {...props as LogoProps} />
    ),
    name: 'Landus Portal Home',
  },
  {
    icon: (props) => <ShoppingCart {...props} />,
    name: 'Shop',
    route: Routes.SHOP,
  },
  {
    dividerAfter: true,
    icon: (props) => <Ballot {...props} />,
    name: 'Requests',
    requiresAuthentication: true,
    route: Routes.PAST_PRICING_REQUESTS,
  },
  {
    icon: (props) => <AccountCircle {...props} />,
    name: 'Shop Account',
    requiresAuthentication: true,
    route: Routes.MY_PROFILE,
  },
];
